import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import './LifeStyleChallenge.css'
import CloseSvg from '../../../assets/images/svgs/close.svg'
import { questions } from './Options'
import axios from 'axios'
import { BASE_URL } from '../../../utils/config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import CustomInput from '../../core/CustomInput/CustomInput'
import { cities } from '../../../constants/cityList'

const OptionCard = ({ label, active, onSelect }) =>
    <div
        style={{ display: 'flex', padding: '0px 16px', cursor: 'pointer' }}
        onClick={() => onSelect(label)}
    >
        <div className='option-radio'>
            {active && <div className='option-radio-selected' />}
        </div>
        <p className='option-label'>{label}</p>
    </div>

const LifeStyleChallenge = ({ show, close, selected, onComplete }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [recommendationDetails, setRecommendationDetails] = useState({
        sleepSchedule: '',
        sunlightExposure: '',
        healthIssues: '',
        stressLevel: '',
        waterConsumption: '',
        commute: '',
        diet: [],
        exercise: '',
        medication: '',
        smoke: '',
        category: 'lifestyle',
        skinTone: '',
        makeUp: '',
        hairType: '',
        scalpType: '',
        hairConcern: [],
        city: '',
        gender: '',
        age: '',
        skinGoals: [],
        skinType: '',
    })

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const jwtoken = localStorage.getItem('jwtToken')
        setToken(jwtoken)
        setUser(userData)
    }, []);
    // console.log('jdmnvkjdvk', recommendationDetails);
    const QuestionComponent = ({ label, options, value }) => <>
        <p className='input-title'>{label}</p>
        {
            options?.map(item => <OptionCard
                label={item}
                active={value == 'diet'
                    ? recommendationDetails?.diet?.includes(item)
                    : value == 'hairConcern' ? recommendationDetails?.hairConcern?.includes(item)
                    : recommendationDetails?.[value] == item
                }
                onSelect={() => {
                    if (value == 'diet') {
                        let selectedVal = recommendationDetails?.diet
                        if (selectedVal?.includes(item)) {
                            const filteredArr = selectedVal?.filter(x => x != item)
                            setRecommendationDetails({ ...recommendationDetails, diet: filteredArr })
                        }
                        else {
                            selectedVal?.push(item)
                            setRecommendationDetails({ ...recommendationDetails, diet: selectedVal })
                        }

                    } else if (value == 'hairConcern') {
                        let selectedConcern = recommendationDetails?.hairConcern
                        if (selectedConcern?.includes(item)) {
                            const filteredArr = selectedConcern?.filter(x => x != item)
                            setRecommendationDetails({ ...recommendationDetails, hairConcern: filteredArr })
                        }
                        else {
                            selectedConcern?.push(item)
                            setRecommendationDetails({ ...recommendationDetails, hairConcern: selectedConcern })
                        }
                    }
                    else setRecommendationDetails({ ...recommendationDetails, [value]: item })
                }}
            />)
        }
    </>

    const onPost = async () => {
        if (!recommendationDetails?.sleepSchedule) {
            toast.warn('Please select field How long do you sleep for?')
            return
        }
        if (!recommendationDetails?.sunlightExposure) {
            toast.warn('Please select fieldHow long are you generally exposed to sunlight?')
            return
        }
        if (!recommendationDetails?.healthIssues) {
            toast.warn('Please select field Do you have any gut health issues?')
            return
        }
        if (!recommendationDetails?.stressLevel) {
            toast.warn('Please select field How stressed are you?')
            return
        }
        if (!recommendationDetails?.waterConsumption) {
            toast.warn('Please select field How much water do you consume in a day?')
            return
        }
        if (!recommendationDetails?.commute) {
            toast.warn('Please select field How do you commute to work')
            return
        }
        if (!recommendationDetails?.diet) {
            toast.warn('Please select field How do you consume daily?')
            return
        }
        if (!recommendationDetails?.exercise) {
            toast.warn('Please select field How often do you exercise?')
            return
        }
        // if (!recommendationDetails?.skinTone) {
        //     toast.warn('Please select field What is your skin tone?')
        //     return
        // }
        // if (!recommendationDetails?.makeUp) {
        //     toast.warn('Please select field How often do you use makeup?')
        //     return
        // }
        // if (!recommendationDetails?.hairType) {
        //     toast.warn('Please select field What is your hair type?')
        //     return
        // }
        // if (!recommendationDetails?.scalpType) {
        //     toast.warn('Please select field What is your scalp type?')
        //     return
        // }
        if (!recommendationDetails?.gender) {
            toast.warn('Please select Gender?')
            return
        }
        if (!recommendationDetails?.age) {
            toast.warn('Please select Age?')
            return
        }
        if (!recommendationDetails?.medication) {
            toast.warn('Please select field Are you taking any medication?')
            return
        }
        if (!recommendationDetails?.smoke) {
            toast.warn('Please select field Do you smoke?')
            return
        }
        if (!recommendationDetails?.city) {
            toast.warn('Please enter Location?')
            return
        }
        else {
            const data = { ...recommendationDetails }
            data.diet = recommendationDetails.diet.join(',')
            data.hairConcern = recommendationDetails.hairConcern.join(',')
            setLoading(true);
            return axios.post(`${BASE_URL}influencer/${selected?.cd_id}/user/${user?.userID}/lifestyle`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(response => {
                    console.log('upload image response.. ', response?.data);
                    setLoading(false);
                    close()
                    onComplete()

                })
                .catch(error => {
                    console.log(error, '...........', error?.response);
                    setLoading(false)
                    onComplete()
                    if(error?.response?.status == 403) {
                        navigate('/blocked')
                    }
                });
        }
    }

    return (
        <Modal
            show={show}
            onHide={close}
            style={{ marginTop: 66 }}
        >
            <div className='p-4 container' style={{ backgroundColor: '#25387C', }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: 5, width: 100, backgroundColor: '#fff', alignSelf: 'center', display: 'flex', borderRadius: 6 }} />
                </div>
                <div className='scrolling-comp pt-3'>
                    <div
                        className='cross'
                        onClick={close}
                    >
                        <img src={CloseSvg} style={{ height: 26, width: 26 }} />
                    </div>
                    <p className='form-title'>Fill the details</p>
                    {questions.map(question =>
                        <QuestionComponent
                            label={question.label}
                            options={question.options}
                            value={question.value}
                        />)}
                        <p className='input-title' style={{ marginBottom: 10 }}>
                            What is your location?
                        </p>
                        {/* <CustomInput
                            placeholder={'Enter city'}
                            val={recommendationDetails?.city}
                            onChange={(e) => setRecommendationDetails({...recommendationDetails, city: e?.target?.value})}
                            style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                        /> */}

                        <Form.Select
                        // aria-label="Default select example"
                        style={{backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12, border: '1px solid #fff', borderRadius: 12}}
                        onChange={(e) => setRecommendationDetails({...recommendationDetails, city: e?.target?.value})}>
                          <option>Select city</option>
                          {cities?.map(city => <option value={city?.value}>{city?.label}</option>)}
                          {/* <option value="2">Two</option>
                          <option value="3">Three</option> */}
                        </Form.Select>
                    <div
                        className='save-btn'
                        style={{ opacity: loading ? 0.6 : 1 }}
                        onClick={() => {
                            if (!loading) {
                                onPost()
                            }
                        }}
                    >
                        <p>Submit</p>
                    </div>
                    <div style={{ height: 160 }} />
                </div>
            </div>
        </Modal>
    )
}

export default LifeStyleChallenge







