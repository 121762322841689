import React, { useCallback, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Webcam from 'react-webcam';
import Close from '../../../assets/images/svgs/closeBlack.svg'

const CustomWebCam = ({ show, close, onSubmit, product }) => {
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null)

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        // downloadFile(imageSrc)
        const fileName = product ? `${product}.png` : 'screenshot.png'
        const testImage = base64StringToFile(imageSrc.replace(/^[^,]+,/, ''), fileName)
        // console.log(testImage);
        setImageFile(testImage)
    }, [webcamRef]);

    const retake = () => {
        setImgSrc(null);
    };

    const base64StringToFile = (base64String, fileName) => {
        // console.log('This one string', base64String);
        const byteCharacters = atob(base64String);
        // const byteCharacters = atob()
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const file = new File([blob], fileName, { type: 'image/png' });
        return file;
    }

    return (
        <Modal
            // show={true}
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter br4"
            centered
        >
            <div className='p-4'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 }}>
                    <p style={{ fontSize: 22, fontWeight: '600', color: '#000', alignSelf: 'center', marginBottom: 0 }}>
                        Submit a photo of your product
                    </p>
                    <div className='close-btn'>
                        <img
                            src={Close} style={{ cursor: 'pointer' }}
                            onClick={close}
                        />
                    </div>
                </div>
                {imgSrc ? (
                    <img src={imgSrc} alt="webcam" />
                ) : (
                    <Webcam
                        width={'100%'}
                        ref={webcamRef}
                        style={{ borderRadius: 16 }}
                    // screenshotFormat={'image/png'}
                    />
                )}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: imgSrc ? 'space-between' : 'center' }}>
                    {imgSrc ? (
                        <div
                            className='upload-btn'
                            style={{ display: 'flex', alignSelf: 'flex-start', marginTop: 18, marginLeft: 16, cursor: 'pointer' }}
                            onClick={retake}
                        >
                            <p>Retake photo</p>
                        </div>
                    ) : (
                        <div
                            className='upload-btn'
                            style={{ display: 'flex', alignSelf: 'flex-start', marginTop: 18, marginLeft: 16, cursor: 'pointer' }}
                            onClick={capture}
                        >
                            <p>Capture photo</p>
                        </div>
                    )}
                    {imgSrc && <div
                        className='upload-btn'
                        style={{ display: 'flex', alignSelf: 'flex-start', marginTop: 18, marginLeft: 16, cursor: 'pointer' }}
                        onClick={() => onSubmit(imageFile)}
                    >
                        <p>Upload Image</p>
                    </div>}
                </div>
            </div>
        </Modal>
    )
}

export default CustomWebCam