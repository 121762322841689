import React from 'react'
import { Col } from 'react-bootstrap'

const HeaderTab = ({ label, onClick, active }) => {
  return (
    <Col md={4} sm={4} xs={4} className='p-0'>
      <div
        className={active ? 'tab-container-active' : 'tab-container'}
        onClick={onClick}
      >
        <p className='tab-bar' style={{ color: active && '#fff' }}>{label}</p>
      </div>
    </Col>
  )
}

export default HeaderTab