import * as React from "react";
// import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
    useSearchParams,
} from "react-router-dom";
import LandingPage from './screens/LandingPage';
import ThanksForSubmitting from './screens/ThanksForSubmitting/ThanksForSubmitting';
import Dashboard from './screens/Dashboard/Dashboard';
import Home from "./screens/Home/Home";
import SignUpForm from "./screens/SignUpForm/SignUpForm";
import { Col, Container, Row } from "react-bootstrap";
import Profile from "./screens/Profile/Profile";
import homeSvg from './assets/images/svgs/tabs/home.svg'
import homeActiveSvg from './assets/images/svgs/tabs/homeActive.svg'
import tasksSvg from './assets/images/svgs/tabs/tasks.svg'
import tasksActiveSvg from './assets/images/svgs/tabs/tasksActive.svg'
import profileSvg from './assets/images/svgs/tabs/profile.svg'
import profileActiveSvg from './assets/images/svgs/tabs/profileActive.svg'
import Stats from "./screens/Stats/Stats";
import SignUp from "./screens/SignUp/SignUp";
import SplashScreen from "./screens/SplashScreen/SplashScreen";
import ClaimRewards from "./screens/ClaimRewards/ClaimRewards";
import Notifications from "./screens/Notifications/Notifications";
import WithdrawRewards from "./screens/WithdrawRewards/WithdrawRewards";
import Blocked from "./screens/Blocked/Blocked";
import RenderDashboard from "./screens/Dashboard/RenderDashboard";
// import { useNavigate } from 'react-router';

const router = createBrowserRouter([
    {
        path: "/dashboard",
        // element: <LandingPage />,
        // element: <Home />,
        element: <RenderDashboard />
    },
    // {
    //     path: "signup",
    //     // element: <LandingPage />,
    //     // element: <Home />,
    //     element: <SignUpForm />
    // },
    {
        path: "success",
        element: <ThanksForSubmitting />,
    },
    {
        path: "stats",
        element: <Stats />,
    },
    {
        path: "*",
        // element: <Dashboard />,
        element: <SplashScreen />,
    },
    {
        path: "profile",
        element: <Profile />,
    },
    {
        path: "signup",
        element: <SignUp />,
    },
    {
        path: "/",
        element: <SplashScreen />,
    },
    {
        path: "splash",
        element: <SplashScreen />,
    },
    {
        path: "claim",
        element: <ClaimRewards />,
    },
    {
        path: "notifications",
        element: <Notifications />,
    },
    {
        path: "withdraw",
        element: <WithdrawRewards />,
    },
    {
        path: "blocked",
        element: <Blocked />,
    },
]);

// const TabComponent = ({ label, icon, active, onClick }) =>
//     <div
//         className={active ? "bottom-tab-active" : "bottom-tab"}
//         onClick={onClick}
//     >
//         <img src={icon} height={24} width={24} style={{ marginTop: 4 }} />
//         <p className={active ? "tab-name-active" : "tab-name"}>{label}</p>
//     </div>

const routes = () => {
    // const navigate = useNavigate();
    const path = window.location.pathname
    // const activeTab = path == '/' ? 'tasks' : path == '/profile' ? 'profile' : path == '/stats' ? 'home' : 'home'
    const showTabs = path != '/signup' && path != '/signin' && path != '/splash' && path != '/claim' && path != '/'

    return (
        <>
            <Container>
                <Row className="justify-content-center hide-scroll-bar">
                    <Col md={12} sm={12} xs={12} className="p-0" >
                        <RouterProvider router={router} />
                        {/* <Container>
                        <Col md={4} sm={12} xs={12} style={{ height: 100, backgroundColor: 'pink', position: 'fixed', bottom: 0 }}>
                        </Col>
                    </Container> */}
                    </Col>
                    {/* {showTabs && <Col md={4} lg={4} sm={12} xs={12} className='m-0 p-0 bottom-tabs-wrap'>
                        <div className="bottom-tabs">
                            <TabComponent
                                label={'Home'}
                                active={activeTab == 'home'}
                                icon={activeTab == 'home' ? homeActiveSvg : homeSvg}
                            // onClick={() => navigate('/stats')}
                            />
                            <TabComponent
                                label={'Tasks'}
                                active={activeTab == 'tasks'}
                                icon={activeTab == 'tasks' ? tasksActiveSvg : tasksSvg}
                            // onClick={ }
                            />
                            <TabComponent
                                label={'Profile'}
                                active={activeTab == 'profile'}
                                icon={activeTab == 'profile' ? profileActiveSvg : profileSvg}
                            // onClick={ }
                            />
                        </div>
                    </Col>} */}
                </Row>
            </Container>
        </>
    )
}

export default routes