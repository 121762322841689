import React from 'react'
import { Modal } from 'react-bootstrap'
import './InfoPopup.css'
import Close from '../../../assets/images/svgs/closeBlack.svg'

const InfoPopup = ({ show, close }) => {
    return (
        <Modal
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter br4"
            centered
        >
            <div className='p-4'>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid #E4E4E7' }}>
                    <p className='heading-txt'>How to Use</p>
                    <img src={Close} style={{ cursor: 'pointer' }} onClick={close} />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ height: 4, width: 4, backgroundColor: '#000', borderRadius: 4, alignSelf: 'center', marginRight: 10 }} />
                    <p className='bullet-point  m-0'>Complete the tasks to Win <span style={{ fontWeight: 600 }}>Cash</span> Prizes</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ height: 4, width: 4, backgroundColor: '#000', borderRadius: 4, alignSelf: 'center', marginRight: 10 }} />
                    <p className='bullet-point m-0'>Refer People & Earn <span style={{ fontWeight: 600 }}>₹500</span></p>
                </div>
            </div>
        </Modal>
    )
}

export default InfoPopup