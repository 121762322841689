import React, { useEffect, useState } from 'react'
import DashboardV2 from './DashboardV2';
import Dashboard from './Dashboard';
import { useNavigate } from 'react-router';

const RenderDashboard = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    return (
        <>
            {user && <Dashboard />}
        </>
    )
}

export default RenderDashboard