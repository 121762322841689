import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import UploadSvg from '../../assets/images/svgs/upload.svg'
import RecommendSvg from '../../assets/images/svgs/recommend.svg'
import InReviewSvg from '../../assets/images/svgs/inReview.svg'
import ValidSvg from '../../assets/images/svgs/valid.svg'
import InvalidSvg from '../../assets/images/svgs/invalid.svg'

const ChallengeCard = ({ item, onClick, pending, status, pendingChallenges }) => {

    const dontDisplay = pendingChallenges?.filter(x => x.cd_id == item.cd_id && x.challenge_id != item.challenge_id && x?.status == 2)?.length > 0
    // console.log('What is this', dontDisplay, item?.name);
    return (
        <>
            {(pending && dontDisplay)
                ? <>
                </>
                : <Card style={{ margin: 8, borderRadius: 16, border: 0 }} className="shadow p-0">
                    <Card.Body style={{
                        padding: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: (status == 1 && (item.type == 'beauty' || item.type == 'lifestyle')) ? '#FCF36B' : status == 1 ? '#fff' : status == 2 ? '#FFDF8C' : status == 3 ? '#E53E3E29' : status == 4 ? '#4E8B436B' : '#fff',
                        borderRadius: 16
                    }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, width: '100%' }}>
                            <p className='card-title' style={{ textAlign: 'start' }} >
                                {item?.name}
                            </p>
                            {pending && <p className='coins mb-0 mt-0' style={{ color: status == 3 ? '#283072' : status == 2 ? '#E1A200' : '#4E8B43' }} >
                                ₹{item?.coins}
                            </p>}
                        </div>
                        <p className='card-subtitles'>
                            {item?.description || ''}
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {(status == 1 || status == 3) && <div
                                className='upload-btn'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onClick(item)}
                            >
                                <img
                                    src={item?.type == 'recommendation' || item.type == 'lifestyle' || item.type == 'beauty' ? RecommendSvg : UploadSvg}
                                    style={{ height: 20, width: 20 }}
                                />
                                {pending ? <p>
                                    {item?.type == 'recommendation'
                                        ? 'Recommend Again'
                                        : 'Re-upload Image'}
                                </p> : <p>
                                    {item?.type == 'recommendation'
                                        ? 'Recommend Now' : item.type == 'lifestyle' || item.type == 'beauty' ? 'Start Quiz'
                                            : 'Upload Image'}
                                </p>}
                            </div>}
                            {status == 1 && <p className='coins'>₹{item?.coins}</p>}
                        </div>
                        {status > 1 && <div
                            style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
                        >
                            <p style={{
                                fontSize: 12,
                                paddingTop: 2,
                                color: status == 2 ? '#E1A200' : status == 3 ? '#F05050' : '#4E8B43'
                            }}
                                className='m-0'
                            >
                                {status == 2 ? 'Under Review' : status == 3 ? 'Rejected' : 'Completed'}
                            </p>
                            <img src={status == 2 ? InReviewSvg : status == 3 ? InvalidSvg : ValidSvg} style={{ height: 28, width: 28, marginLeft: 6, marginTop: status > 2 ? -4 : 0 }} />
                        </div>}
                        {((item.type == 'beauty' || item.type == 'lifestyle') && status == 1) && <div style={{position: 'absolute',  backgroundColor: '#4254ea', top: 0, right: 0, borderTopRightRadius: 16, borderBottomLeftRadius: 16}}>
                            <p style={{color: '#fff', padding: '4px 24px', fontSize: 16, margin: 0, fontWeight: 'bold'}}>New</p>
                        </div>}
                    </Card.Body>
                </Card>}
        </>
    )
}

export default ChallengeCard