export const cities = [
    {
        "label": "Achalpur",
        "value": "Achalpur"
    },
    {
        "label": "Achhnera",
        "value": "Achhnera"
    },
    {
        "label": "Adalaj",
        "value": "Adalaj"
    },
    {
        "label": "Adilabad",
        "value": "Adilabad"
    },
    {
        "label": "Adityapur",
        "value": "Adityapur"
    },
    {
        "label": "Adoni",
        "value": "Adoni"
    },
    {
        "label": "Adoor",
        "value": "Adoor"
    },
    {
        "label": "Adra",
        "value": "Adra"
    },
    {
        "label": "Adyar",
        "value": "Adyar"
    },
    {
        "label": "Afzalpur",
        "value": "Afzalpur"
    },
    {
        "label": "Agartala",
        "value": "Agartala"
    },
    {
        "label": "Agra",
        "value": "Agra"
    },
    {
        "label": "Ahmedabad",
        "value": "Ahmedabad"
    },
    {
        "label": "Ahmednagar",
        "value": "Ahmednagar"
    },
    {
        "label": "Aizawl",
        "value": "Aizawl"
    },
    {
        "label": "Ajmer",
        "value": "Ajmer"
    },
    {
        "label": "Akola",
        "value": "Akola"
    },
    {
        "label": "Akot",
        "value": "Akot"
    },
    {
        "label": "Alappuzha",
        "value": "Alappuzha"
    },
    {
        "label": "Aligarh",
        "value": "Aligarh"
    },
    {
        "label": "AlipurdUrban Agglomerationr",
        "value": "AlipurdUrban Agglomerationr"
    },
    {
        "label": "Alirajpur",
        "value": "Alirajpur"
    },
    {
        "label": "Allahabad",
        "value": "Allahabad"
    },
    {
        "label": "Alwar",
        "value": "Alwar"
    },
    {
        "label": "Amalapuram",
        "value": "Amalapuram"
    },
    {
        "label": "Amalner",
        "value": "Amalner"
    },
    {
        "label": "Ambejogai",
        "value": "Ambejogai"
    },
    {
        "label": "Ambikapur",
        "value": "Ambikapur"
    },
    {
        "label": "Amravati",
        "value": "Amravati"
    },
    {
        "label": "Amreli",
        "value": "Amreli"
    },
    {
        "label": "Amritsar",
        "value": "Amritsar"
    },
    {
        "label": "Amroha",
        "value": "Amroha"
    },
    {
        "label": "Anakapalle",
        "value": "Anakapalle"
    },
    {
        "label": "Anand",
        "value": "Anand"
    },
    {
        "label": "Anantapur",
        "value": "Anantapur"
    },
    {
        "label": "Anantnag",
        "value": "Anantnag"
    },
    {
        "label": "Anjangaon",
        "value": "Anjangaon"
    },
    {
        "label": "Anjar",
        "value": "Anjar"
    },
    {
        "label": "Ankleshwar",
        "value": "Ankleshwar"
    },
    {
        "label": "Arakkonam",
        "value": "Arakkonam"
    },
    {
        "label": "Arambagh",
        "value": "Arambagh"
    },
    {
        "label": "Araria",
        "value": "Araria"
    },
    {
        "label": "Arrah",
        "value": "Arrah"
    },
    {
        "label": "Arsikere",
        "value": "Arsikere"
    },
    {
        "label": "Aruppukkottai",
        "value": "Aruppukkottai"
    },
    {
        "label": "Arvi",
        "value": "Arvi"
    },
    {
        "label": "Arwal",
        "value": "Arwal"
    },
    {
        "label": "Asansol",
        "value": "Asansol"
    },
    {
        "label": "Asarganj",
        "value": "Asarganj"
    },
    {
        "label": "Ashok Nagar",
        "value": "Ashok Nagar"
    },
    {
        "label": "Athni",
        "value": "Athni"
    },
    {
        "label": "Attingal",
        "value": "Attingal"
    },
    {
        "label": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "label": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "label": "Azamgarh",
        "value": "Azamgarh"
    },
    {
        "label": "Bagaha",
        "value": "Bagaha"
    },
    {
        "label": "Bageshwar",
        "value": "Bageshwar"
    },
    {
        "label": "Bahadurgarh",
        "value": "Bahadurgarh"
    },
    {
        "label": "Baharampur",
        "value": "Baharampur"
    },
    {
        "label": "Bahraich",
        "value": "Bahraich"
    },
    {
        "label": "Balaghat",
        "value": "Balaghat"
    },
    {
        "label": "Balangir",
        "value": "Balangir"
    },
    {
        "label": "Baleshwar Town",
        "value": "Baleshwar Town"
    },
    {
        "label": "Ballari",
        "value": "Ballari"
    },
    {
        "label": "Balurghat",
        "value": "Balurghat"
    },
    {
        "label": "Bangalore",
        "value": "Bengaluru"
    },
    {
        "label": "Bankura",
        "value": "Bankura"
    },
    {
        "label": "Bapatla",
        "value": "Bapatla"
    },
    {
        "label": "Baramula",
        "value": "Baramula"
    },
    {
        "label": "Barbil",
        "value": "Barbil"
    },
    {
        "label": "Bargarh",
        "value": "Bargarh"
    },
    {
        "label": "Barh",
        "value": "Barh"
    },
    {
        "label": "Baripada Town",
        "value": "Baripada Town"
    },
    {
        "label": "Barmer",
        "value": "Barmer"
    },
    {
        "label": "Barnala",
        "value": "Barnala"
    },
    {
        "label": "Barpeta",
        "value": "Barpeta"
    },
    {
        "label": "Batala",
        "value": "Batala"
    },
    {
        "label": "Bathinda",
        "value": "Bathinda"
    },
    {
        "label": "Begusarai",
        "value": "Begusarai"
    },
    {
        "label": "Belagavi",
        "value": "Belagavi"
    },
    {
        "label": "Bellampalle",
        "value": "Bellampalle"
    },
    {
        "label": "Belonia",
        "value": "Belonia"
    },
    {
        "label": "Bengaluru",
        "value": "Bengaluru"
    },
    {
        "label": "Bettiah",
        "value": "Bettiah"
    },
    {
        "label": "BhabUrban Agglomeration",
        "value": "BhabUrban Agglomeration"
    },
    {
        "label": "Bhadrachalam",
        "value": "Bhadrachalam"
    },
    {
        "label": "Bhadrak",
        "value": "Bhadrak"
    },
    {
        "label": "Bhagalpur",
        "value": "Bhagalpur"
    },
    {
        "label": "Bhainsa",
        "value": "Bhainsa"
    },
    {
        "label": "Bharatpur",
        "value": "Bharatpur"
    },
    {
        "label": "Bharuch",
        "value": "Bharuch"
    },
    {
        "label": "Bhatapara",
        "value": "Bhatapara"
    },
    {
        "label": "Bhavnagar",
        "value": "Bhavnagar"
    },
    {
        "label": "Bhawanipatna",
        "value": "Bhawanipatna"
    },
    {
        "label": "Bheemunipatnam",
        "value": "Bheemunipatnam"
    },
    {
        "label": "Bhilai Nagar",
        "value": "Bhilai Nagar"
    },
    {
        "label": "Bhilwara",
        "value": "Bhilwara"
    },
    {
        "label": "Bhimavaram",
        "value": "Bhimavaram"
    },
    {
        "label": "Bhiwandi",
        "value": "Bhiwandi"
    },
    {
        "label": "Bhiwani",
        "value": "Bhiwani"
    },
    {
        "label": "Bhongir",
        "value": "Bhongir"
    },
    {
        "label": "Bhopal",
        "value": "Bhopal"
    },
    {
        "label": "Bhubaneswar",
        "value": "Bhubaneswar"
    },
    {
        "label": "Bhuj",
        "value": "Bhuj"
    },
    {
        "label": "Bikaner",
        "value": "Bikaner"
    },
    {
        "label": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "label": "Bobbili",
        "value": "Bobbili"
    },
    {
        "label": "Bodhan",
        "value": "Bodhan"
    },
    {
        "label": "Bokaro Steel City",
        "value": "Bokaro Steel City"
    },
    {
        "label": "Bongaigaon City",
        "value": "Bongaigaon City"
    },
    {
        "label": "Brahmapur",
        "value": "Brahmapur"
    },
    {
        "label": "Buxar",
        "value": "Buxar"
    },
    {
        "label": "Byasanagar",
        "value": "Byasanagar"
    },
    {
        "label": "Chaibasa",
        "value": "Chaibasa"
    },
    {
        "label": "Chalakudy",
        "value": "Chalakudy"
    },
    {
        "label": "Chandausi",
        "value": "Chandausi"
    },
    {
        "label": "Chandigarh",
        "value": "Chandigarh"
    },
    {
        "label": "Changanassery",
        "value": "Changanassery"
    },
    {
        "label": "Charkhi Dadri",
        "value": "Charkhi Dadri"
    },
    {
        "label": "Chatra",
        "value": "Chatra"
    },
    {
        "label": "Chennai",
        "value": "Chennai"
    },
    {
        "label": "Cherthala",
        "value": "Cherthala"
    },
    {
        "label": "Chhapra",
        "value": "Chhapra"
    },
    {
        "label": "Chhapra",
        "value": "Chhapra"
    },
    {
        "label": "Chikkamagaluru",
        "value": "Chikkamagaluru"
    },
    {
        "label": "Chilakaluripet",
        "value": "Chilakaluripet"
    },
    {
        "label": "Chirala",
        "value": "Chirala"
    },
    {
        "label": "Chirkunda",
        "value": "Chirkunda"
    },
    {
        "label": "Chirmiri",
        "value": "Chirmiri"
    },
    {
        "label": "Chittoor",
        "value": "Chittoor"
    },
    {
        "label": "Chittur-Thathamangalam",
        "value": "Chittur-Thathamangalam"
    },
    {
        "label": "Coimbatore",
        "value": "Coimbatore"
    },
    {
        "label": "Cuttack",
        "value": "Cuttack"
    },
    {
        "label": "Dalli-Rajhara",
        "value": "Dalli-Rajhara"
    },
    {
        "label": "Darbhanga",
        "value": "Darbhanga"
    },
    {
        "label": "Darjiling",
        "value": "Darjiling"
    },
    {
        "label": "Davanagere",
        "value": "Davanagere"
    },
    {
        "label": "Deesa",
        "value": "Deesa"
    },
    {
        "label": "Dehradun",
        "value": "Dehradun"
    },
    {
        "label": "Dehri-on-Sone",
        "value": "Dehri-on-Sone"
    },
    {
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "label": "Deoghar",
        "value": "Deoghar"
    },
    {
        "label": "Dhamtari",
        "value": "Dhamtari"
    },
    {
        "label": "Dhanbad",
        "value": "Dhanbad"
    },
    {
        "label": "Dharmanagar",
        "value": "Dharmanagar"
    },
    {
        "label": "Dharmavaram",
        "value": "Dharmavaram"
    },
    {
        "label": "Dhenkanal",
        "value": "Dhenkanal"
    },
    {
        "label": "Dhoraji",
        "value": "Dhoraji"
    },
    {
        "label": "Dhubri",
        "value": "Dhubri"
    },
    {
        "label": "Dhule",
        "value": "Dhule"
    },
    {
        "label": "Dhuri",
        "value": "Dhuri"
    },
    {
        "label": "Dibrugarh",
        "value": "Dibrugarh"
    },
    {
        "label": "Dimapur",
        "value": "Dimapur"
    },
    {
        "label": "Diphu",
        "value": "Diphu"
    },
    {
        "label": "Dumka",
        "value": "Dumka"
    },
    {
        "label": "Dumraon",
        "value": "Dumraon"
    },
    {
        "label": "Durg",
        "value": "Durg"
    },
    {
        "label": "Eluru",
        "value": "Eluru"
    },
    {
        "label": "English Bazar",
        "value": "English Bazar"
    },
    {
        "label": "Erode",
        "value": "Erode"
    },
    {
        "label": "Etawah",
        "value": "Etawah"
    },
    {
        "label": "Faridabad",
        "value": "Faridabad"
    },
    {
        "label": "Faridkot",
        "value": "Faridkot"
    },
    {
        "label": "Farooqnagar",
        "value": "Farooqnagar"
    },
    {
        "label": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "label": "Fatehpur Sikri",
        "value": "Fatehpur Sikri"
    },
    {
        "label": "Fazilka",
        "value": "Fazilka"
    },
    {
        "label": "Firozabad",
        "value": "Firozabad"
    },
    {
        "label": "Firozpur",
        "value": "Firozpur"
    },
    {
        "label": "Firozpur Cantt.",
        "value": "Firozpur Cantt."
    },
    {
        "label": "Forbesganj",
        "value": "Forbesganj"
    },
    {
        "label": "Gadag",
        "value": "Gadag"
    },
    {
        "label": "Gadwal",
        "value": "Gadwal"
    },
    {
        "label": "Gandhinagar",
        "value": "Gandhinagar"
    },
    {
        "label": "Gangarampur",
        "value": "Gangarampur"
    },
    {
        "label": "Ganjbasoda",
        "value": "Ganjbasoda"
    },
    {
        "label": "Gaya",
        "value": "Gaya"
    },
    {
        "label": "Giridih",
        "value": "Giridih"
    },
    {
        "label": "Goalpara",
        "value": "Goalpara"
    },
    {
        "label": "Gobichettipalayam",
        "value": "Gobichettipalayam"
    },
    {
        "label": "Gobindgarh",
        "value": "Gobindgarh"
    },
    {
        "label": "Godhra",
        "value": "Godhra"
    },
    {
        "label": "Gohana",
        "value": "Gohana"
    },
    {
        "label": "Gokak",
        "value": "Gokak"
    },
    {
        "label": "Gooty",
        "value": "Gooty"
    },
    {
        "label": "Gopalganj",
        "value": "Gopalganj"
    },
    {
        "label": "Gudivada",
        "value": "Gudivada"
    },
    {
        "label": "Gudur",
        "value": "Gudur"
    },
    {
        "label": "Gumia",
        "value": "Gumia"
    },
    {
        "label": "Guntakal",
        "value": "Guntakal"
    },
    {
        "label": "Guntur",
        "value": "Guntur"
    },
    {
        "label": "Gurdaspur",
        "value": "Gurdaspur"
    },
    {
        "label": "Gurgaon",
        "value": "Gurgaon"
    },
    {
        "label": "Guruvayoor",
        "value": "Guruvayoor"
    },
    {
        "label": "Guwahati",
        "value": "Guwahati"
    },
    {
        "label": "Gwalior",
        "value": "Gwalior"
    },
    {
        "label": "Habra",
        "value": "Habra"
    },
    {
        "label": "Hajipur",
        "value": "Hajipur"
    },
    {
        "label": "Haldwani-cum-Kathgodam",
        "value": "Haldwani-cum-Kathgodam"
    },
    {
        "label": "Hansi",
        "value": "Hansi"
    },
    {
        "label": "Hapur",
        "value": "Hapur"
    },
    {
        "label": "Hardoi ",
        "value": "Hardoi "
    },
    {
        "label": "Hardwar",
        "value": "Hardwar"
    },
    {
        "label": "Hazaribag",
        "value": "Hazaribag"
    },
    {
        "label": "Hindupur",
        "value": "Hindupur"
    },
    {
        "label": "Hisar",
        "value": "Hisar"
    },
    {
        "label": "Hoshiarpur",
        "value": "Hoshiarpur"
    },
    {
        "label": "Hubli-Dharwad",
        "value": "Hubli-Dharwad"
    },
    {
        "label": "Hugli-Chinsurah",
        "value": "Hugli-Chinsurah"
    },
    {
        "label": "Hyderabad",
        "value": "Hyderabad"
    },
    {
        "label": "Ichalkaranji",
        "value": "Ichalkaranji"
    },
    {
        "label": "Imphal",
        "value": "Imphal"
    },
    {
        "label": "Indore",
        "value": "Indore"
    },
    {
        "label": "Itarsi",
        "value": "Itarsi"
    },
    {
        "label": "Jabalpur",
        "value": "Jabalpur"
    },
    {
        "label": "Jagdalpur",
        "value": "Jagdalpur"
    },
    {
        "label": "Jaggaiahpet",
        "value": "Jaggaiahpet"
    },
    {
        "label": "Jagraon",
        "value": "Jagraon"
    },
    {
        "label": "Jagtial",
        "value": "Jagtial"
    },
    {
        "label": "Jaipur",
        "value": "Jaipur"
    },
    {
        "label": "Jalandhar",
        "value": "Jalandhar"
    },
    {
        "label": "Jalandhar Cantt.",
        "value": "Jalandhar Cantt."
    },
    {
        "label": "Jalpaiguri",
        "value": "Jalpaiguri"
    },
    {
        "label": "Jamalpur",
        "value": "Jamalpur"
    },
    {
        "label": "Jammalamadugu",
        "value": "Jammalamadugu"
    },
    {
        "label": "Jammu",
        "value": "Jammu"
    },
    {
        "label": "Jamnagar",
        "value": "Jamnagar"
    },
    {
        "label": "Jamshedpur",
        "value": "Jamshedpur"
    },
    {
        "label": "Jamui",
        "value": "Jamui"
    },
    {
        "label": "Jangaon",
        "value": "Jangaon"
    },
    {
        "label": "Jatani",
        "value": "Jatani"
    },
    {
        "label": "Jehanabad",
        "value": "Jehanabad"
    },
    {
        "label": "Jhansi",
        "value": "Jhansi"
    },
    {
        "label": "Jhargram",
        "value": "Jhargram"
    },
    {
        "label": "Jharsuguda",
        "value": "Jharsuguda"
    },
    {
        "label": "Jhumri Tilaiya",
        "value": "Jhumri Tilaiya"
    },
    {
        "label": "Jind",
        "value": "Jind"
    },
    {
        "label": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "label": "Jorhat",
        "value": "Jorhat"
    },
    {
        "label": "Kadapa",
        "value": "Kadapa"
    },
    {
        "label": "Kadi",
        "value": "Kadi"
    },
    {
        "label": "Kadiri",
        "value": "Kadiri"
    },
    {
        "label": "Kagaznagar",
        "value": "Kagaznagar"
    },
    {
        "label": "Kailasahar",
        "value": "Kailasahar"
    },
    {
        "label": "Kaithal",
        "value": "Kaithal"
    },
    {
        "label": "Kakinada",
        "value": "Kakinada"
    },
    {
        "label": "Kalimpong",
        "value": "Kalimpong"
    },
    {
        "label": "Kalpi",
        "value": "Kalpi"
    },
    {
        "label": "Kalyan-Dombivali",
        "value": "Kalyan-Dombivali"
    },
    {
        "label": "Kamareddy",
        "value": "Kamareddy"
    },
    {
        "label": "Kancheepuram",
        "value": "Kancheepuram"
    },
    {
        "label": "Kandukur",
        "value": "Kandukur"
    },
    {
        "label": "Kanhangad",
        "value": "Kanhangad"
    },
    {
        "label": "Kannur",
        "value": "Kannur"
    },
    {
        "label": "Kanpur",
        "value": "Kanpur"
    },
    {
        "label": "Kapadvanj",
        "value": "Kapadvanj"
    },
    {
        "label": "Kapurthala",
        "value": "Kapurthala"
    },
    {
        "label": "Karaikal",
        "value": "Karaikal"
    },
    {
        "label": "Karimganj",
        "value": "Karimganj"
    },
    {
        "label": "Karimnagar",
        "value": "Karimnagar"
    },
    {
        "label": "Karjat",
        "value": "Karjat"
    },
    {
        "label": "Karnal",
        "value": "Karnal"
    },
    {
        "label": "Karur",
        "value": "Karur"
    },
    {
        "label": "Karwar",
        "value": "Karwar"
    },
    {
        "label": "Kasaragod",
        "value": "Kasaragod"
    },
    {
        "label": "Kashipur",
        "value": "Kashipur"
    },
    {
        "label": "KathUrban Agglomeration",
        "value": "KathUrban Agglomeration"
    },
    {
        "label": "Katihar",
        "value": "Katihar"
    },
    {
        "label": "Kavali",
        "value": "Kavali"
    },
    {
        "label": "Kayamkulam",
        "value": "Kayamkulam"
    },
    {
        "label": "Kendrapara",
        "value": "Kendrapara"
    },
    {
        "label": "Kendujhar",
        "value": "Kendujhar"
    },
    {
        "label": "Keshod",
        "value": "Keshod"
    },
    {
        "label": "Khair",
        "value": "Khair"
    },
    {
        "label": "Khambhat",
        "value": "Khambhat"
    },
    {
        "label": "Khammam",
        "value": "Khammam"
    },
    {
        "label": "Khanna",
        "value": "Khanna"
    },
    {
        "label": "Kharagpur",
        "value": "Kharagpur"
    },
    {
        "label": "Kharar",
        "value": "Kharar"
    },
    {
        "label": "Khowai",
        "value": "Khowai"
    },
    {
        "label": "Kishanganj",
        "value": "Kishanganj"
    },
    {
        "label": "Kochi",
        "value": "Kochi"
    },
    {
        "label": "Kodungallur",
        "value": "Kodungallur"
    },
    {
        "label": "Kohima",
        "value": "Kohima"
    },
    {
        "label": "Kolar",
        "value": "Kolar"
    },
    {
        "label": "Kolkata",
        "value": "Kolkata"
    },
    {
        "label": "Kollam",
        "value": "Kollam"
    },
    {
        "label": "Koratla",
        "value": "Koratla"
    },
    {
        "label": "Korba",
        "value": "Korba"
    },
    {
        "label": "Kot Kapura",
        "value": "Kot Kapura"
    },
    {
        "label": "Kothagudem",
        "value": "Kothagudem"
    },
    {
        "label": "Kottayam",
        "value": "Kottayam"
    },
    {
        "label": "Kovvur",
        "value": "Kovvur"
    },
    {
        "label": "Koyilandy",
        "value": "Koyilandy"
    },
    {
        "label": "Kozhikode",
        "value": "Kozhikode"
    },
    {
        "label": "Kullu",
        "value": "Kullu"
    },
    {
        "label": "Kunnamkulam",
        "value": "Kunnamkulam"
    },
    {
        "label": "Kurnool",
        "value": "Kurnool"
    },
    {
        "label": "Kyathampalle",
        "value": "Kyathampalle"
    },
    {
        "label": "Lachhmangarh",
        "value": "Lachhmangarh"
    },
    {
        "label": "Ladnu",
        "value": "Ladnu"
    },
    {
        "label": "Ladwa",
        "value": "Ladwa"
    },
    {
        "label": "Lahar",
        "value": "Lahar"
    },
    {
        "label": "Laharpur",
        "value": "Laharpur"
    },
    {
        "label": "Lakheri",
        "value": "Lakheri"
    },
    {
        "label": "Lakhimpur",
        "value": "Lakhimpur"
    },
    {
        "label": "Lakhisarai",
        "value": "Lakhisarai"
    },
    {
        "label": "Lakshmeshwar",
        "value": "Lakshmeshwar"
    },
    {
        "label": "Lal Gopalganj Nindaura",
        "value": "Lal Gopalganj Nindaura"
    },
    {
        "label": "Lalganj",
        "value": "Lalganj"
    },
    {
        "label": "Lalganj",
        "value": "Lalganj"
    },
    {
        "label": "Lalgudi",
        "value": "Lalgudi"
    },
    {
        "label": "Lalitpur",
        "value": "Lalitpur"
    },
    {
        "label": "Lalsot",
        "value": "Lalsot"
    },
    {
        "label": "Lanka",
        "value": "Lanka"
    },
    {
        "label": "Lar",
        "value": "Lar"
    },
    {
        "label": "Lathi",
        "value": "Lathi"
    },
    {
        "label": "Latur",
        "value": "Latur"
    },
    {
        "label": "Lilong",
        "value": "Lilong"
    },
    {
        "label": "Limbdi",
        "value": "Limbdi"
    },
    {
        "label": "Lingsugur",
        "value": "Lingsugur"
    },
    {
        "label": "Loha",
        "value": "Loha"
    },
    {
        "label": "Lohardaga",
        "value": "Lohardaga"
    },
    {
        "label": "Lonar",
        "value": "Lonar"
    },
    {
        "label": "Lonavla",
        "value": "Lonavla"
    },
    {
        "label": "Longowal",
        "value": "Longowal"
    },
    {
        "label": "Loni",
        "value": "Loni"
    },
    {
        "label": "Losal",
        "value": "Losal"
    },
    {
        "label": "Lucknow",
        "value": "Lucknow"
    },
    {
        "label": "Ludhiana",
        "value": "Ludhiana"
    },
    {
        "label": "Lumding",
        "value": "Lumding"
    },
    {
        "label": "Lunawada",
        "value": "Lunawada"
    },
    {
        "label": "Lunglei",
        "value": "Lunglei"
    },
    {
        "label": "Macherla",
        "value": "Macherla"
    },
    {
        "label": "Machilipatnam",
        "value": "Machilipatnam"
    },
    {
        "label": "Madanapalle",
        "value": "Madanapalle"
    },
    {
        "label": "Maddur",
        "value": "Maddur"
    },
    {
        "label": "Madhepura",
        "value": "Madhepura"
    },
    {
        "label": "Madhubani",
        "value": "Madhubani"
    },
    {
        "label": "Madhugiri",
        "value": "Madhugiri"
    },
    {
        "label": "Madhupur",
        "value": "Madhupur"
    },
    {
        "label": "Madikeri",
        "value": "Madikeri"
    },
    {
        "label": "Madurai",
        "value": "Madurai"
    },
    {
        "label": "Magadi",
        "value": "Magadi"
    },
    {
        "label": "Mahad",
        "value": "Mahad"
    },
    {
        "label": "Mahalingapura",
        "value": "Mahalingapura"
    },
    {
        "label": "Maharajganj",
        "value": "Maharajganj"
    },
    {
        "label": "Maharajpur",
        "value": "Maharajpur"
    },
    {
        "label": "Mahasamund",
        "value": "Mahasamund"
    },
    {
        "label": "Mahbubnagar",
        "value": "Mahbubnagar"
    },
    {
        "label": "Mahe",
        "value": "Mahe"
    },
    {
        "label": "Mahemdabad",
        "value": "Mahemdabad"
    },
    {
        "label": "Mahendragarh",
        "value": "Mahendragarh"
    },
    {
        "label": "Mahesana",
        "value": "Mahesana"
    },
    {
        "label": "Mahidpur",
        "value": "Mahidpur"
    },
    {
        "label": "Mahnar Bazar",
        "value": "Mahnar Bazar"
    },
    {
        "label": "Mahuva",
        "value": "Mahuva"
    },
    {
        "label": "Maihar",
        "value": "Maihar"
    },
    {
        "label": "Mainaguri",
        "value": "Mainaguri"
    },
    {
        "label": "Makhdumpur",
        "value": "Makhdumpur"
    },
    {
        "label": "Makrana",
        "value": "Makrana"
    },
    {
        "label": "Malaj Khand",
        "value": "Malaj Khand"
    },
    {
        "label": "Malappuram",
        "value": "Malappuram"
    },
    {
        "label": "Malavalli",
        "value": "Malavalli"
    },
    {
        "label": "Malda",
        "value": "Malda"
    },
    {
        "label": "Malegaon",
        "value": "Malegaon"
    },
    {
        "label": "Malerkotla",
        "value": "Malerkotla"
    },
    {
        "label": "Malkangiri",
        "value": "Malkangiri"
    },
    {
        "label": "Malkapur",
        "value": "Malkapur"
    },
    {
        "label": "Malout",
        "value": "Malout"
    },
    {
        "label": "Malpura",
        "value": "Malpura"
    },
    {
        "label": "Malur",
        "value": "Malur"
    },
    {
        "label": "Manachanallur",
        "value": "Manachanallur"
    },
    {
        "label": "Manali",
        "value": "Manali"
    },
    {
        "label": "Manasa",
        "value": "Manasa"
    },
    {
        "label": "Manavadar",
        "value": "Manavadar"
    },
    {
        "label": "Manawar",
        "value": "Manawar"
    },
    {
        "label": "Mancherial",
        "value": "Mancherial"
    },
    {
        "label": "Mandalgarh",
        "value": "Mandalgarh"
    },
    {
        "label": "Mandamarri",
        "value": "Mandamarri"
    },
    {
        "label": "Mandapeta",
        "value": "Mandapeta"
    },
    {
        "label": "Mandawa",
        "value": "Mandawa"
    },
    {
        "label": "Mandi",
        "value": "Mandi"
    },
    {
        "label": "Mandi Dabwali",
        "value": "Mandi Dabwali"
    },
    {
        "label": "Mandideep",
        "value": "Mandideep"
    },
    {
        "label": "Mandla",
        "value": "Mandla"
    },
    {
        "label": "Mandsaur",
        "value": "Mandsaur"
    },
    {
        "label": "Mandvi",
        "value": "Mandvi"
    },
    {
        "label": "Mandya",
        "value": "Mandya"
    },
    {
        "label": "Manendragarh",
        "value": "Manendragarh"
    },
    {
        "label": "Maner",
        "value": "Maner"
    },
    {
        "label": "Mangaldoi",
        "value": "Mangaldoi"
    },
    {
        "label": "Mangaluru",
        "value": "Mangaluru"
    },
    {
        "label": "Mangalvedhe",
        "value": "Mangalvedhe"
    },
    {
        "label": "Manglaur",
        "value": "Manglaur"
    },
    {
        "label": "Mangrol",
        "value": "Mangrol"
    },
    {
        "label": "Mangrol",
        "value": "Mangrol"
    },
    {
        "label": "Mangrulpir",
        "value": "Mangrulpir"
    },
    {
        "label": "Manihari",
        "value": "Manihari"
    },
    {
        "label": "Manjlegaon",
        "value": "Manjlegaon"
    },
    {
        "label": "Mankachar",
        "value": "Mankachar"
    },
    {
        "label": "Manmad",
        "value": "Manmad"
    },
    {
        "label": "Mansa",
        "value": "Mansa"
    },
    {
        "label": "Mansa",
        "value": "Mansa"
    },
    {
        "label": "Manuguru",
        "value": "Manuguru"
    },
    {
        "label": "Manvi",
        "value": "Manvi"
    },
    {
        "label": "Manwath",
        "value": "Manwath"
    },
    {
        "label": "Mapusa",
        "value": "Mapusa"
    },
    {
        "label": "Margao",
        "value": "Margao"
    },
    {
        "label": "Margherita",
        "value": "Margherita"
    },
    {
        "label": "Marhaura",
        "value": "Marhaura"
    },
    {
        "label": "Mariani",
        "value": "Mariani"
    },
    {
        "label": "Marigaon",
        "value": "Marigaon"
    },
    {
        "label": "Markapur",
        "value": "Markapur"
    },
    {
        "label": "Marmagao",
        "value": "Marmagao"
    },
    {
        "label": "Masaurhi",
        "value": "Masaurhi"
    },
    {
        "label": "Mathabhanga",
        "value": "Mathabhanga"
    },
    {
        "label": "Mathura",
        "value": "Mathura"
    },
    {
        "label": "Mattannur",
        "value": "Mattannur"
    },
    {
        "label": "Mauganj",
        "value": "Mauganj"
    },
    {
        "label": "Mavelikkara",
        "value": "Mavelikkara"
    },
    {
        "label": "Mavoor",
        "value": "Mavoor"
    },
    {
        "label": "Mayang Imphal",
        "value": "Mayang Imphal"
    },
    {
        "label": "Medak",
        "value": "Medak"
    },
    {
        "label": "Medininagar (Daltonganj)",
        "value": "Medininagar (Daltonganj)"
    },
    {
        "label": "Medinipur",
        "value": "Medinipur"
    },
    {
        "label": "Meerut",
        "value": "Meerut"
    },
    {
        "label": "Mehkar",
        "value": "Mehkar"
    },
    {
        "label": "Memari",
        "value": "Memari"
    },
    {
        "label": "Merta City",
        "value": "Merta City"
    },
    {
        "label": "Mhaswad",
        "value": "Mhaswad"
    },
    {
        "label": "Mhow Cantonment",
        "value": "Mhow Cantonment"
    },
    {
        "label": "Mhowgaon",
        "value": "Mhowgaon"
    },
    {
        "label": "Mihijam",
        "value": "Mihijam"
    },
    {
        "label": "Mira-Bhayandar",
        "value": "Mira-Bhayandar"
    },
    {
        "label": "Mirganj",
        "value": "Mirganj"
    },
    {
        "label": "Miryalaguda",
        "value": "Miryalaguda"
    },
    {
        "label": "Mirzapur",
        "value": "Mirzapur"
    },
    {
        "label": "Modasa",
        "value": "Modasa"
    },
    {
        "label": "Modinagar",
        "value": "Modinagar"
    },
    {
        "label": "Moga",
        "value": "Moga"
    },
    {
        "label": "Mohali",
        "value": "Mohali"
    },
    {
        "label": "Mokameh",
        "value": "Mokameh"
    },
    {
        "label": "Mokokchung",
        "value": "Mokokchung"
    },
    {
        "label": "Monoharpur",
        "value": "Monoharpur"
    },
    {
        "label": "Moradabad",
        "value": "Moradabad"
    },
    {
        "label": "Morena",
        "value": "Morena"
    },
    {
        "label": "Morinda, India",
        "value": "Morinda, India"
    },
    {
        "label": "Morshi",
        "value": "Morshi"
    },
    {
        "label": "Morvi",
        "value": "Morvi"
    },
    {
        "label": "Motihari",
        "value": "Motihari"
    },
    {
        "label": "Motipur",
        "value": "Motipur"
    },
    {
        "label": "Mount Abu",
        "value": "Mount Abu"
    },
    {
        "label": "Mudabidri",
        "value": "Mudabidri"
    },
    {
        "label": "Mudalagi",
        "value": "Mudalagi"
    },
    {
        "label": "Muddebihal",
        "value": "Muddebihal"
    },
    {
        "label": "Mudhol",
        "value": "Mudhol"
    },
    {
        "label": "Mukerian",
        "value": "Mukerian"
    },
    {
        "label": "Mukhed",
        "value": "Mukhed"
    },
    {
        "label": "Muktsar",
        "value": "Muktsar"
    },
    {
        "label": "Mul",
        "value": "Mul"
    },
    {
        "label": "Mulbagal",
        "value": "Mulbagal"
    },
    {
        "label": "Multai",
        "value": "Multai"
    },
    {
        "label": "Mumbai",
        "value": "Mumbai"
    },
    {
        "label": "Mundargi",
        "value": "Mundargi"
    },
    {
        "label": "Mundi",
        "value": "Mundi"
    },
    {
        "label": "Mungeli",
        "value": "Mungeli"
    },
    {
        "label": "Munger",
        "value": "Munger"
    },
    {
        "label": "Murliganj",
        "value": "Murliganj"
    },
    {
        "label": "Murshidabad",
        "value": "Murshidabad"
    },
    {
        "label": "Murtijapur",
        "value": "Murtijapur"
    },
    {
        "label": "Murwara (Katni)",
        "value": "Murwara (Katni)"
    },
    {
        "label": "Musabani",
        "value": "Musabani"
    },
    {
        "label": "Mussoorie",
        "value": "Mussoorie"
    },
    {
        "label": "Muvattupuzha",
        "value": "Muvattupuzha"
    },
    {
        "label": "Muzaffarpur",
        "value": "Muzaffarpur"
    },
    {
        "label": "Mysore",
        "value": "Mysore"
    },
    {
        "label": "Nabadwip",
        "value": "Nabadwip"
    },
    {
        "label": "Nabarangapur",
        "value": "Nabarangapur"
    },
    {
        "label": "Nabha",
        "value": "Nabha"
    },
    {
        "label": "Nadbai",
        "value": "Nadbai"
    },
    {
        "label": "Nadiad",
        "value": "Nadiad"
    },
    {
        "label": "Nagaon",
        "value": "Nagaon"
    },
    {
        "label": "Nagapattinam",
        "value": "Nagapattinam"
    },
    {
        "label": "Nagar",
        "value": "Nagar"
    },
    {
        "label": "Nagari",
        "value": "Nagari"
    },
    {
        "label": "Nagarkurnool",
        "value": "Nagarkurnool"
    },
    {
        "label": "Nagaur",
        "value": "Nagaur"
    },
    {
        "label": "Nagda",
        "value": "Nagda"
    },
    {
        "label": "Nagercoil",
        "value": "Nagercoil"
    },
    {
        "label": "Nagina",
        "value": "Nagina"
    },
    {
        "label": "Nagla",
        "value": "Nagla"
    },
    {
        "label": "Nagpur",
        "value": "Nagpur"
    },
    {
        "label": "Nahan",
        "value": "Nahan"
    },
    {
        "label": "Naharlagun",
        "value": "Naharlagun"
    },
    {
        "label": "Naidupet",
        "value": "Naidupet"
    },
    {
        "label": "Naihati",
        "value": "Naihati"
    },
    {
        "label": "Naila Janjgir",
        "value": "Naila Janjgir"
    },
    {
        "label": "Nainital",
        "value": "Nainital"
    },
    {
        "label": "Nainpur",
        "value": "Nainpur"
    },
    {
        "label": "Najibabad",
        "value": "Najibabad"
    },
    {
        "label": "Nakodar",
        "value": "Nakodar"
    },
    {
        "label": "Nakur",
        "value": "Nakur"
    },
    {
        "label": "Nalbari",
        "value": "Nalbari"
    },
    {
        "label": "Namagiripettai",
        "value": "Namagiripettai"
    },
    {
        "label": "Namakkal",
        "value": "Namakkal"
    },
    {
        "label": "Nanded-Waghala",
        "value": "Nanded-Waghala"
    },
    {
        "label": "Nandgaon",
        "value": "Nandgaon"
    },
    {
        "label": "Nandivaram-Guduvancheri",
        "value": "Nandivaram-Guduvancheri"
    },
    {
        "label": "Nandura",
        "value": "Nandura"
    },
    {
        "label": "Nandurbar",
        "value": "Nandurbar"
    },
    {
        "label": "Nandyal",
        "value": "Nandyal"
    },
    {
        "label": "Nangal",
        "value": "Nangal"
    },
    {
        "label": "Nanjangud",
        "value": "Nanjangud"
    },
    {
        "label": "Nanjikottai",
        "value": "Nanjikottai"
    },
    {
        "label": "Nanpara",
        "value": "Nanpara"
    },
    {
        "label": "Narasapuram",
        "value": "Narasapuram"
    },
    {
        "label": "Narasaraopet",
        "value": "Narasaraopet"
    },
    {
        "label": "Naraura",
        "value": "Naraura"
    },
    {
        "label": "Narayanpet",
        "value": "Narayanpet"
    },
    {
        "label": "Nargund",
        "value": "Nargund"
    },
    {
        "label": "Narkatiaganj",
        "value": "Narkatiaganj"
    },
    {
        "label": "Narkhed",
        "value": "Narkhed"
    },
    {
        "label": "Narnaul",
        "value": "Narnaul"
    },
    {
        "label": "Narsinghgarh",
        "value": "Narsinghgarh"
    },
    {
        "label": "Narsinghgarh",
        "value": "Narsinghgarh"
    },
    {
        "label": "Narsipatnam",
        "value": "Narsipatnam"
    },
    {
        "label": "Narwana",
        "value": "Narwana"
    },
    {
        "label": "Nashik",
        "value": "Nashik"
    },
    {
        "label": "Nasirabad",
        "value": "Nasirabad"
    },
    {
        "label": "Natham",
        "value": "Natham"
    },
    {
        "label": "Nathdwara",
        "value": "Nathdwara"
    },
    {
        "label": "Naugachhia",
        "value": "Naugachhia"
    },
    {
        "label": "Naugawan Sadat",
        "value": "Naugawan Sadat"
    },
    {
        "label": "Nautanwa",
        "value": "Nautanwa"
    },
    {
        "label": "Navalgund",
        "value": "Navalgund"
    },
    {
        "label": "Navsari",
        "value": "Navsari"
    },
    {
        "label": "Nawabganj",
        "value": "Nawabganj"
    },
    {
        "label": "Nawada",
        "value": "Nawada"
    },
    {
        "label": "Nawanshahr",
        "value": "Nawanshahr"
    },
    {
        "label": "Nawapur",
        "value": "Nawapur"
    },
    {
        "label": "Nedumangad",
        "value": "Nedumangad"
    },
    {
        "label": "Neem-Ka-Thana",
        "value": "Neem-Ka-Thana"
    },
    {
        "label": "Neemuch",
        "value": "Neemuch"
    },
    {
        "label": "Nehtaur",
        "value": "Nehtaur"
    },
    {
        "label": "Nelamangala",
        "value": "Nelamangala"
    },
    {
        "label": "Nellikuppam",
        "value": "Nellikuppam"
    },
    {
        "label": "Nellore",
        "value": "Nellore"
    },
    {
        "label": "Nepanagar",
        "value": "Nepanagar"
    },
    // {
    //     "label": "New Delhi",
    //     "value": "New Delhi"
    // },
    {
        "label": "Neyveli (TS)",
        "value": "Neyveli (TS)"
    },
    {
        "label": "Neyyattinkara",
        "value": "Neyyattinkara"
    },
    {
        "label": "Nidadavole",
        "value": "Nidadavole"
    },
    {
        "label": "Nilambur",
        "value": "Nilambur"
    },
    {
        "label": "Nilanga",
        "value": "Nilanga"
    },
    {
        "label": "Nimbahera",
        "value": "Nimbahera"
    },
    {
        "label": "Nirmal",
        "value": "Nirmal"
    },
    {
        "label": "Niwai",
        "value": "Niwai"
    },
    {
        "label": "Niwari",
        "value": "Niwari"
    },
    {
        "label": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "label": "Nohar",
        "value": "Nohar"
    },
    {
        "label": "Noida",
        "value": "Noida"
    },
    {
        "label": "Nokha",
        "value": "Nokha"
    },
    {
        "label": "Nokha",
        "value": "Nokha"
    },
    {
        "label": "Nongstoin",
        "value": "Nongstoin"
    },
    {
        "label": "Noorpur",
        "value": "Noorpur"
    },
    {
        "label": "North Lakhimpur",
        "value": "North Lakhimpur"
    },
    {
        "label": "Nowgong",
        "value": "Nowgong"
    },
    {
        "label": "Nowrozabad (Khodargama)",
        "value": "Nowrozabad (Khodargama)"
    },
    {
        "label": "Nuzvid",
        "value": "Nuzvid"
    },
    {
        "label": "O' Valley",
        "value": "O' Valley"
    },
    {
        "label": "Obra",
        "value": "Obra"
    },
    {
        "label": "Oddanchatram",
        "value": "Oddanchatram"
    },
    {
        "label": "Ongole",
        "value": "Ongole"
    },
    {
        "label": "Orai",
        "value": "Orai"
    },
    {
        "label": "Osmanabad",
        "value": "Osmanabad"
    },
    {
        "label": "Ottappalam",
        "value": "Ottappalam"
    },
    {
        "label": "Ozar",
        "value": "Ozar"
    },
    {
        "label": "P.N.Patti",
        "value": "P.N.Patti"
    },
    {
        "label": "Pachora",
        "value": "Pachora"
    },
    {
        "label": "Pachore",
        "value": "Pachore"
    },
    {
        "label": "Pacode",
        "value": "Pacode"
    },
    {
        "label": "Padmanabhapuram",
        "value": "Padmanabhapuram"
    },
    {
        "label": "Padra",
        "value": "Padra"
    },
    {
        "label": "Padrauna",
        "value": "Padrauna"
    },
    {
        "label": "Paithan",
        "value": "Paithan"
    },
    {
        "label": "Pakaur",
        "value": "Pakaur"
    },
    {
        "label": "Palacole",
        "value": "Palacole"
    },
    {
        "label": "Palai",
        "value": "Palai"
    },
    {
        "label": "Palakkad",
        "value": "Palakkad"
    },
    {
        "label": "Palampur",
        "value": "Palampur"
    },
    {
        "label": "Palani",
        "value": "Palani"
    },
    {
        "label": "Palanpur",
        "value": "Palanpur"
    },
    {
        "label": "Palasa Kasibugga",
        "value": "Palasa Kasibugga"
    },
    {
        "label": "Palghar",
        "value": "Palghar"
    },
    {
        "label": "Pali",
        "value": "Pali"
    },
    {
        "label": "Pali",
        "value": "Pali"
    },
    {
        "label": "Palia Kalan",
        "value": "Palia Kalan"
    },
    {
        "label": "Palitana",
        "value": "Palitana"
    },
    {
        "label": "Palladam",
        "value": "Palladam"
    },
    {
        "label": "Pallapatti",
        "value": "Pallapatti"
    },
    {
        "label": "Pallikonda",
        "value": "Pallikonda"
    },
    {
        "label": "Palwal",
        "value": "Palwal"
    },
    {
        "label": "Palwancha",
        "value": "Palwancha"
    },
    {
        "label": "Panagar",
        "value": "Panagar"
    },
    {
        "label": "Panagudi",
        "value": "Panagudi"
    },
    {
        "label": "Panaji",
        "value": "Panaji"
    },
    {
        "label": "Panamattom",
        "value": "Panamattom"
    },
    {
        "label": "Panchkula",
        "value": "Panchkula"
    },
    {
        "label": "Panchla",
        "value": "Panchla"
    },
    {
        "label": "PandUrban Agglomeration",
        "value": "PandUrban Agglomeration"
    },
    {
        "label": "Pandharkaoda",
        "value": "Pandharkaoda"
    },
    {
        "label": "Pandharpur",
        "value": "Pandharpur"
    },
    {
        "label": "Pandhurna",
        "value": "Pandhurna"
    },
    {
        "label": "Panipat",
        "value": "Panipat"
    },
    {
        "label": "Panna",
        "value": "Panna"
    },
    {
        "label": "Panniyannur",
        "value": "Panniyannur"
    },
    {
        "label": "Panruti",
        "value": "Panruti"
    },
    {
        "label": "Panvel",
        "value": "Panvel"
    },
    {
        "label": "Pappinisseri",
        "value": "Pappinisseri"
    },
    {
        "label": "Paradip",
        "value": "Paradip"
    },
    {
        "label": "Paramakudi",
        "value": "Paramakudi"
    },
    {
        "label": "Parangipettai",
        "value": "Parangipettai"
    },
    {
        "label": "Parasi",
        "value": "Parasi"
    },
    {
        "label": "Paravoor",
        "value": "Paravoor"
    },
    {
        "label": "Parbhani",
        "value": "Parbhani"
    },
    {
        "label": "Pardi",
        "value": "Pardi"
    },
    {
        "label": "Parlakhemundi",
        "value": "Parlakhemundi"
    },
    {
        "label": "Parli",
        "value": "Parli"
    },
    {
        "label": "Partur",
        "value": "Partur"
    },
    {
        "label": "Parvathipuram",
        "value": "Parvathipuram"
    },
    {
        "label": "Pasan",
        "value": "Pasan"
    },
    {
        "label": "Paschim Punropara",
        "value": "Paschim Punropara"
    },
    {
        "label": "Pasighat",
        "value": "Pasighat"
    },
    {
        "label": "Patan",
        "value": "Patan"
    },
    {
        "label": "Pathanamthitta",
        "value": "Pathanamthitta"
    },
    {
        "label": "Pathankot",
        "value": "Pathankot"
    },
    {
        "label": "Pathardi",
        "value": "Pathardi"
    },
    {
        "label": "Pathri",
        "value": "Pathri"
    },
    {
        "label": "Patiala",
        "value": "Patiala"
    },
    {
        "label": "Patna",
        "value": "Patna"
    },
    {
        "label": "Patratu",
        "value": "Patratu"
    },
    {
        "label": "Pattamundai",
        "value": "Pattamundai"
    },
    {
        "label": "Patti",
        "value": "Patti"
    },
    {
        "label": "Pattran",
        "value": "Pattran"
    },
    {
        "label": "Pattukkottai",
        "value": "Pattukkottai"
    },
    {
        "label": "Patur",
        "value": "Patur"
    },
    {
        "label": "Pauni",
        "value": "Pauni"
    },
    {
        "label": "Pauri",
        "value": "Pauri"
    },
    {
        "label": "Pavagada",
        "value": "Pavagada"
    },
    {
        "label": "Pedana",
        "value": "Pedana"
    },
    {
        "label": "Peddapuram",
        "value": "Peddapuram"
    },
    {
        "label": "Pehowa",
        "value": "Pehowa"
    },
    {
        "label": "Pen",
        "value": "Pen"
    },
    {
        "label": "Perambalur",
        "value": "Perambalur"
    },
    {
        "label": "Peravurani",
        "value": "Peravurani"
    },
    {
        "label": "Peringathur",
        "value": "Peringathur"
    },
    {
        "label": "Perinthalmanna",
        "value": "Perinthalmanna"
    },
    {
        "label": "Periyakulam",
        "value": "Periyakulam"
    },
    {
        "label": "Periyasemur",
        "value": "Periyasemur"
    },
    {
        "label": "Pernampattu",
        "value": "Pernampattu"
    },
    {
        "label": "Perumbavoor",
        "value": "Perumbavoor"
    },
    {
        "label": "Petlad",
        "value": "Petlad"
    },
    {
        "label": "Phagwara",
        "value": "Phagwara"
    },
    {
        "label": "Phalodi",
        "value": "Phalodi"
    },
    {
        "label": "Phaltan",
        "value": "Phaltan"
    },
    {
        "label": "Phillaur",
        "value": "Phillaur"
    },
    {
        "label": "Phulabani",
        "value": "Phulabani"
    },
    {
        "label": "Phulera",
        "value": "Phulera"
    },
    {
        "label": "Phulpur",
        "value": "Phulpur"
    },
    {
        "label": "Phusro",
        "value": "Phusro"
    },
    {
        "label": "Pihani",
        "value": "Pihani"
    },
    {
        "label": "Pilani",
        "value": "Pilani"
    },
    {
        "label": "Pilibanga",
        "value": "Pilibanga"
    },
    {
        "label": "Pilibhit",
        "value": "Pilibhit"
    },
    {
        "label": "Pilkhuwa",
        "value": "Pilkhuwa"
    },
    {
        "label": "Pindwara",
        "value": "Pindwara"
    },
    {
        "label": "Pinjore",
        "value": "Pinjore"
    },
    {
        "label": "Pipar City",
        "value": "Pipar City"
    },
    {
        "label": "Pipariya",
        "value": "Pipariya"
    },
    {
        "label": "Piriyapatna",
        "value": "Piriyapatna"
    },
    {
        "label": "Piro",
        "value": "Piro"
    },
    {
        "label": "Pithampur",
        "value": "Pithampur"
    },
    {
        "label": "Pithapuram",
        "value": "Pithapuram"
    },
    {
        "label": "Pithoragarh",
        "value": "Pithoragarh"
    },
    {
        "label": "Pollachi",
        "value": "Pollachi"
    },
    {
        "label": "Polur",
        "value": "Polur"
    },
    {
        "label": "Pondicherry",
        "value": "Pondicherry"
    },
    {
        "label": "Ponnani",
        "value": "Ponnani"
    },
    {
        "label": "Ponneri",
        "value": "Ponneri"
    },
    {
        "label": "Ponnur",
        "value": "Ponnur"
    },
    {
        "label": "Porbandar",
        "value": "Porbandar"
    },
    {
        "label": "Porsa",
        "value": "Porsa"
    },
    {
        "label": "Port Blair",
        "value": "Port Blair"
    },
    {
        "label": "Powayan",
        "value": "Powayan"
    },
    {
        "label": "Prantij",
        "value": "Prantij"
    },
    {
        "label": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "label": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "label": "Prithvipur",
        "value": "Prithvipur"
    },
    {
        "label": "Proddatur",
        "value": "Proddatur"
    },
    {
        "label": "Pudukkottai",
        "value": "Pudukkottai"
    },
    {
        "label": "Pudupattinam",
        "value": "Pudupattinam"
    },
    {
        "label": "Pukhrayan",
        "value": "Pukhrayan"
    },
    {
        "label": "Pulgaon",
        "value": "Pulgaon"
    },
    {
        "label": "Puliyankudi",
        "value": "Puliyankudi"
    },
    {
        "label": "Punalur",
        "value": "Punalur"
    },
    {
        "label": "Punch",
        "value": "Punch"
    },
    {
        "label": "Pune",
        "value": "Pune"
    },
    {
        "label": "Punganur",
        "value": "Punganur"
    },
    {
        "label": "Punjaipugalur",
        "value": "Punjaipugalur"
    },
    {
        "label": "Puranpur",
        "value": "Puranpur"
    },
    {
        "label": "Puri",
        "value": "Puri"
    },
    {
        "label": "Purna",
        "value": "Purna"
    },
    {
        "label": "Purnia",
        "value": "Purnia"
    },
    {
        "label": "PurqUrban Agglomerationzi",
        "value": "PurqUrban Agglomerationzi"
    },
    {
        "label": "Purulia",
        "value": "Purulia"
    },
    {
        "label": "Purwa",
        "value": "Purwa"
    },
    {
        "label": "Pusad",
        "value": "Pusad"
    },
    {
        "label": "Puthuppally",
        "value": "Puthuppally"
    },
    {
        "label": "Puttur",
        "value": "Puttur"
    },
    {
        "label": "Puttur",
        "value": "Puttur"
    },
    {
        "label": "Qadian",
        "value": "Qadian"
    },
    {
        "label": "Raayachuru",
        "value": "Raayachuru"
    },
    {
        "label": "Rabkavi Banhatti",
        "value": "Rabkavi Banhatti"
    },
    {
        "label": "Radhanpur",
        "value": "Radhanpur"
    },
    {
        "label": "Rae Bareli",
        "value": "Rae Bareli"
    },
    {
        "label": "Rafiganj",
        "value": "Rafiganj"
    },
    {
        "label": "Raghogarh-Vijaypur",
        "value": "Raghogarh-Vijaypur"
    },
    {
        "label": "Raghunathganj",
        "value": "Raghunathganj"
    },
    {
        "label": "Raghunathpur",
        "value": "Raghunathpur"
    },
    {
        "label": "Rahatgarh",
        "value": "Rahatgarh"
    },
    {
        "label": "Rahuri",
        "value": "Rahuri"
    },
    {
        "label": "Raiganj",
        "value": "Raiganj"
    },
    {
        "label": "Raigarh",
        "value": "Raigarh"
    },
    {
        "label": "Raikot",
        "value": "Raikot"
    },
    {
        "label": "Raipur",
        "value": "Raipur"
    },
    {
        "label": "Rairangpur",
        "value": "Rairangpur"
    },
    {
        "label": "Raisen",
        "value": "Raisen"
    },
    {
        "label": "Raisinghnagar",
        "value": "Raisinghnagar"
    },
    {
        "label": "Rajagangapur",
        "value": "Rajagangapur"
    },
    {
        "label": "Rajahmundry",
        "value": "Rajahmundry"
    },
    {
        "label": "Rajakhera",
        "value": "Rajakhera"
    },
    {
        "label": "Rajaldesar",
        "value": "Rajaldesar"
    },
    {
        "label": "Rajam",
        "value": "Rajam"
    },
    {
        "label": "Rajampet",
        "value": "Rajampet"
    },
    {
        "label": "Rajapalayam",
        "value": "Rajapalayam"
    },
    {
        "label": "Rajauri",
        "value": "Rajauri"
    },
    {
        "label": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "label": "Rajgarh (Alwar)",
        "value": "Rajgarh (Alwar)"
    },
    {
        "label": "Rajgarh (Churu)",
        "value": "Rajgarh (Churu)"
    },
    {
        "label": "Rajgir",
        "value": "Rajgir"
    },
    {
        "label": "Rajkot",
        "value": "Rajkot"
    },
    {
        "label": "Rajnandgaon",
        "value": "Rajnandgaon"
    },
    {
        "label": "Rajpipla",
        "value": "Rajpipla"
    },
    {
        "label": "Rajpura",
        "value": "Rajpura"
    },
    {
        "label": "Rajsamand",
        "value": "Rajsamand"
    },
    {
        "label": "Rajula",
        "value": "Rajula"
    },
    {
        "label": "Rajura",
        "value": "Rajura"
    },
    {
        "label": "Ramachandrapuram",
        "value": "Ramachandrapuram"
    },
    {
        "label": "Ramagundam",
        "value": "Ramagundam"
    },
    {
        "label": "Ramanagaram",
        "value": "Ramanagaram"
    },
    {
        "label": "Ramanathapuram",
        "value": "Ramanathapuram"
    },
    {
        "label": "Ramdurg",
        "value": "Ramdurg"
    },
    {
        "label": "Rameshwaram",
        "value": "Rameshwaram"
    },
    {
        "label": "Ramganj Mandi",
        "value": "Ramganj Mandi"
    },
    {
        "label": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "label": "Ramngarh",
        "value": "Ramngarh"
    },
    {
        "label": "Rampur",
        "value": "Rampur"
    },
    {
        "label": "Rampur Maniharan",
        "value": "Rampur Maniharan"
    },
    {
        "label": "Rampura Phul",
        "value": "Rampura Phul"
    },
    {
        "label": "Rampurhat",
        "value": "Rampurhat"
    },
    {
        "label": "Ramtek",
        "value": "Ramtek"
    },
    {
        "label": "Ranaghat",
        "value": "Ranaghat"
    },
    {
        "label": "Ranavav",
        "value": "Ranavav"
    },
    {
        "label": "Ranchi",
        "value": "Ranchi"
    },
    {
        "label": "Ranebennuru",
        "value": "Ranebennuru"
    },
    {
        "label": "Rangia",
        "value": "Rangia"
    },
    {
        "label": "Rania",
        "value": "Rania"
    },
    {
        "label": "Ranibennur",
        "value": "Ranibennur"
    },
    {
        "label": "Ranipet",
        "value": "Ranipet"
    },
    {
        "label": "Rapar",
        "value": "Rapar"
    },
    {
        "label": "Rasipuram",
        "value": "Rasipuram"
    },
    {
        "label": "Rasra",
        "value": "Rasra"
    },
    {
        "label": "Ratangarh",
        "value": "Ratangarh"
    },
    {
        "label": "Rath",
        "value": "Rath"
    },
    {
        "label": "Ratia",
        "value": "Ratia"
    },
    {
        "label": "Ratlam",
        "value": "Ratlam"
    },
    {
        "label": "Ratnagiri",
        "value": "Ratnagiri"
    },
    {
        "label": "Rau",
        "value": "Rau"
    },
    {
        "label": "Raurkela",
        "value": "Raurkela"
    },
    {
        "label": "Raver",
        "value": "Raver"
    },
    {
        "label": "Rawatbhata",
        "value": "Rawatbhata"
    },
    {
        "label": "Rawatsar",
        "value": "Rawatsar"
    },
    {
        "label": "Raxaul Bazar",
        "value": "Raxaul Bazar"
    },
    {
        "label": "Rayachoti",
        "value": "Rayachoti"
    },
    {
        "label": "Rayadurg",
        "value": "Rayadurg"
    },
    {
        "label": "Rayagada",
        "value": "Rayagada"
    },
    {
        "label": "Reengus",
        "value": "Reengus"
    },
    {
        "label": "Rehli",
        "value": "Rehli"
    },
    {
        "label": "Renigunta",
        "value": "Renigunta"
    },
    {
        "label": "Renukoot",
        "value": "Renukoot"
    },
    {
        "label": "Reoti",
        "value": "Reoti"
    },
    {
        "label": "Repalle",
        "value": "Repalle"
    },
    {
        "label": "Revelganj",
        "value": "Revelganj"
    },
    {
        "label": "Rewa",
        "value": "Rewa"
    },
    {
        "label": "Rewari",
        "value": "Rewari"
    },
    {
        "label": "Rishikesh",
        "value": "Rishikesh"
    },
    {
        "label": "Risod",
        "value": "Risod"
    },
    {
        "label": "Robertsganj",
        "value": "Robertsganj"
    },
    {
        "label": "Robertson Pet",
        "value": "Robertson Pet"
    },
    {
        "label": "Rohtak",
        "value": "Rohtak"
    },
    {
        "label": "Ron",
        "value": "Ron"
    },
    {
        "label": "Roorkee",
        "value": "Roorkee"
    },
    {
        "label": "Rosera",
        "value": "Rosera"
    },
    {
        "label": "Rudauli",
        "value": "Rudauli"
    },
    {
        "label": "Rudrapur",
        "value": "Rudrapur"
    },
    {
        "label": "Rudrapur",
        "value": "Rudrapur"
    },
    {
        "label": "Rupnagar",
        "value": "Rupnagar"
    },
    {
        "label": "SUrban Agglomerationr",
        "value": "SUrban Agglomerationr"
    },
    {
        "label": "Sabalgarh",
        "value": "Sabalgarh"
    },
    {
        "label": "Sadabad",
        "value": "Sadabad"
    },
    {
        "label": "Sadalagi",
        "value": "Sadalagi"
    },
    {
        "label": "Sadasivpet",
        "value": "Sadasivpet"
    },
    {
        "label": "Sadri",
        "value": "Sadri"
    },
    {
        "label": "Sadulpur",
        "value": "Sadulpur"
    },
    {
        "label": "Sadulshahar",
        "value": "Sadulshahar"
    },
    {
        "label": "Safidon",
        "value": "Safidon"
    },
    {
        "label": "Safipur",
        "value": "Safipur"
    },
    {
        "label": "Sagar",
        "value": "Sagar"
    },
    {
        "label": "Sagara",
        "value": "Sagara"
    },
    {
        "label": "Sagwara",
        "value": "Sagwara"
    },
    {
        "label": "Saharanpur",
        "value": "Saharanpur"
    },
    {
        "label": "Saharsa",
        "value": "Saharsa"
    },
    {
        "label": "Sahaspur",
        "value": "Sahaspur"
    },
    {
        "label": "Sahaswan",
        "value": "Sahaswan"
    },
    {
        "label": "Sahawar",
        "value": "Sahawar"
    },
    {
        "label": "Sahibganj",
        "value": "Sahibganj"
    },
    {
        "label": "Sahjanwa",
        "value": "Sahjanwa"
    },
    {
        "label": "Saidpur",
        "value": "Saidpur"
    },
    {
        "label": "Saiha",
        "value": "Saiha"
    },
    {
        "label": "Sailu",
        "value": "Sailu"
    },
    {
        "label": "Sainthia",
        "value": "Sainthia"
    },
    {
        "label": "Sakaleshapura",
        "value": "Sakaleshapura"
    },
    {
        "label": "Sakti",
        "value": "Sakti"
    },
    {
        "label": "Salaya",
        "value": "Salaya"
    },
    {
        "label": "Salem",
        "value": "Salem"
    },
    {
        "label": "Salur",
        "value": "Salur"
    },
    {
        "label": "Samalkha",
        "value": "Samalkha"
    },
    {
        "label": "Samalkot",
        "value": "Samalkot"
    },
    {
        "label": "Samana",
        "value": "Samana"
    },
    {
        "label": "Samastipur",
        "value": "Samastipur"
    },
    {
        "label": "Sambalpur",
        "value": "Sambalpur"
    },
    {
        "label": "Sambhal",
        "value": "Sambhal"
    },
    {
        "label": "Sambhar",
        "value": "Sambhar"
    },
    {
        "label": "Samdhan",
        "value": "Samdhan"
    },
    {
        "label": "Samthar",
        "value": "Samthar"
    },
    {
        "label": "Sanand",
        "value": "Sanand"
    },
    {
        "label": "Sanawad",
        "value": "Sanawad"
    },
    {
        "label": "Sanchore",
        "value": "Sanchore"
    },
    {
        "label": "Sandi",
        "value": "Sandi"
    },
    {
        "label": "Sandila",
        "value": "Sandila"
    },
    {
        "label": "Sanduru",
        "value": "Sanduru"
    },
    {
        "label": "Sangamner",
        "value": "Sangamner"
    },
    {
        "label": "Sangareddy",
        "value": "Sangareddy"
    },
    {
        "label": "Sangaria",
        "value": "Sangaria"
    },
    {
        "label": "Sangli",
        "value": "Sangli"
    },
    {
        "label": "Sangole",
        "value": "Sangole"
    },
    {
        "label": "Sangrur",
        "value": "Sangrur"
    },
    {
        "label": "Sankarankovil",
        "value": "Sankarankovil"
    },
    {
        "label": "Sankari",
        "value": "Sankari"
    },
    {
        "label": "Sankeshwara",
        "value": "Sankeshwara"
    },
    {
        "label": "Santipur",
        "value": "Santipur"
    },
    {
        "label": "Sarangpur",
        "value": "Sarangpur"
    },
    {
        "label": "Sardarshahar",
        "value": "Sardarshahar"
    },
    {
        "label": "Sardhana",
        "value": "Sardhana"
    },
    {
        "label": "Sarni",
        "value": "Sarni"
    },
    {
        "label": "Sarsod",
        "value": "Sarsod"
    },
    {
        "label": "Sasaram",
        "value": "Sasaram"
    },
    {
        "label": "Sasvad",
        "value": "Sasvad"
    },
    {
        "label": "Satana",
        "value": "Satana"
    },
    {
        "label": "Satara",
        "value": "Satara"
    },
    {
        "label": "Sathyamangalam",
        "value": "Sathyamangalam"
    },
    {
        "label": "Satna",
        "value": "Satna"
    },
    {
        "label": "Sattenapalle",
        "value": "Sattenapalle"
    },
    {
        "label": "Sattur",
        "value": "Sattur"
    },
    {
        "label": "Saunda",
        "value": "Saunda"
    },
    {
        "label": "Saundatti-Yellamma",
        "value": "Saundatti-Yellamma"
    },
    {
        "label": "Sausar",
        "value": "Sausar"
    },
    {
        "label": "Savanur",
        "value": "Savanur"
    },
    {
        "label": "Savarkundla",
        "value": "Savarkundla"
    },
    {
        "label": "Savner",
        "value": "Savner"
    },
    {
        "label": "Sawai Madhopur",
        "value": "Sawai Madhopur"
    },
    {
        "label": "Sawantwadi",
        "value": "Sawantwadi"
    },
    {
        "label": "Sedam",
        "value": "Sedam"
    },
    {
        "label": "Sehore",
        "value": "Sehore"
    },
    {
        "label": "Sendhwa",
        "value": "Sendhwa"
    },
    {
        "label": "Seohara",
        "value": "Seohara"
    },
    {
        "label": "Seoni",
        "value": "Seoni"
    },
    {
        "label": "Seoni-Malwa",
        "value": "Seoni-Malwa"
    },
    {
        "label": "Shahabad",
        "value": "Shahabad"
    },
    {
        "label": "Shahabad, Hardoi",
        "value": "Shahabad, Hardoi"
    },
    {
        "label": "Shahabad, Rampur",
        "value": "Shahabad, Rampur"
    },
    {
        "label": "Shahade",
        "value": "Shahade"
    },
    {
        "label": "Shahbad",
        "value": "Shahbad"
    },
    {
        "label": "Shahdol",
        "value": "Shahdol"
    },
    {
        "label": "Shahganj",
        "value": "Shahganj"
    },
    {
        "label": "Shahjahanpur",
        "value": "Shahjahanpur"
    },
    {
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "label": "Shahpura",
        "value": "Shahpura"
    },
    {
        "label": "Shahpura",
        "value": "Shahpura"
    },
    {
        "label": "Shajapur",
        "value": "Shajapur"
    },
    {
        "label": "Shamgarh",
        "value": "Shamgarh"
    },
    {
        "label": "Shamli",
        "value": "Shamli"
    },
    {
        "label": "Shamsabad, Agra",
        "value": "Shamsabad, Agra"
    },
    {
        "label": "Shamsabad, Farrukhabad",
        "value": "Shamsabad, Farrukhabad"
    },
    {
        "label": "Shegaon",
        "value": "Shegaon"
    },
    {
        "label": "Sheikhpura",
        "value": "Sheikhpura"
    },
    {
        "label": "Shendurjana",
        "value": "Shendurjana"
    },
    {
        "label": "Shenkottai",
        "value": "Shenkottai"
    },
    {
        "label": "Sheoganj",
        "value": "Sheoganj"
    },
    {
        "label": "Sheohar",
        "value": "Sheohar"
    },
    {
        "label": "Sheopur",
        "value": "Sheopur"
    },
    {
        "label": "Sherghati",
        "value": "Sherghati"
    },
    {
        "label": "Sherkot",
        "value": "Sherkot"
    },
    {
        "label": "Shiggaon",
        "value": "Shiggaon"
    },
    {
        "label": "Shikaripur",
        "value": "Shikaripur"
    },
    {
        "label": "Shikarpur, Bulandshahr",
        "value": "Shikarpur, Bulandshahr"
    },
    {
        "label": "Shikohabad",
        "value": "Shikohabad"
    },
    {
        "label": "Shillong",
        "value": "Shillong"
    },
    {
        "label": "Shimla",
        "value": "Shimla"
    },
    {
        "label": "Shirdi",
        "value": "Shirdi"
    },
    {
        "label": "Shirpur-Warwade",
        "value": "Shirpur-Warwade"
    },
    {
        "label": "Shirur",
        "value": "Shirur"
    },
    {
        "label": "Shishgarh",
        "value": "Shishgarh"
    },
    {
        "label": "Shivamogga",
        "value": "Shivamogga"
    },
    {
        "label": "Shivpuri",
        "value": "Shivpuri"
    },
    {
        "label": "Sholavandan",
        "value": "Sholavandan"
    },
    {
        "label": "Sholingur",
        "value": "Sholingur"
    },
    {
        "label": "Shoranur",
        "value": "Shoranur"
    },
    {
        "label": "Shrigonda",
        "value": "Shrigonda"
    },
    {
        "label": "Shrirampur",
        "value": "Shrirampur"
    },
    {
        "label": "Shrirangapattana",
        "value": "Shrirangapattana"
    },
    {
        "label": "Shujalpur",
        "value": "Shujalpur"
    },
    {
        "label": "Siana",
        "value": "Siana"
    },
    {
        "label": "Sibsagar",
        "value": "Sibsagar"
    },
    {
        "label": "Siddipet",
        "value": "Siddipet"
    },
    {
        "label": "Sidhi",
        "value": "Sidhi"
    },
    {
        "label": "Sidhpur",
        "value": "Sidhpur"
    },
    {
        "label": "Sidlaghatta",
        "value": "Sidlaghatta"
    },
    {
        "label": "Sihor",
        "value": "Sihor"
    },
    {
        "label": "Sihora",
        "value": "Sihora"
    },
    {
        "label": "Sikanderpur",
        "value": "Sikanderpur"
    },
    {
        "label": "Sikandra Rao",
        "value": "Sikandra Rao"
    },
    {
        "label": "Sikandrabad",
        "value": "Sikandrabad"
    },
    {
        "label": "Sikar",
        "value": "Sikar"
    },
    {
        "label": "Silao",
        "value": "Silao"
    },
    {
        "label": "Silapathar",
        "value": "Silapathar"
    },
    {
        "label": "Silchar",
        "value": "Silchar"
    },
    {
        "label": "Siliguri",
        "value": "Siliguri"
    },
    {
        "label": "Sillod",
        "value": "Sillod"
    },
    {
        "label": "Silvassa",
        "value": "Silvassa"
    },
    {
        "label": "Simdega",
        "value": "Simdega"
    },
    {
        "label": "Sindagi",
        "value": "Sindagi"
    },
    {
        "label": "Sindhagi",
        "value": "Sindhagi"
    },
    {
        "label": "Sindhnur",
        "value": "Sindhnur"
    },
    {
        "label": "Singrauli",
        "value": "Singrauli"
    },
    {
        "label": "Sinnar",
        "value": "Sinnar"
    },
    {
        "label": "Sira",
        "value": "Sira"
    },
    {
        "label": "Sircilla",
        "value": "Sircilla"
    },
    {
        "label": "Sirhind Fatehgarh Sahib",
        "value": "Sirhind Fatehgarh Sahib"
    },
    {
        "label": "Sirkali",
        "value": "Sirkali"
    },
    {
        "label": "Sirohi",
        "value": "Sirohi"
    },
    {
        "label": "Sironj",
        "value": "Sironj"
    },
    {
        "label": "Sirsa",
        "value": "Sirsa"
    },
    {
        "label": "Sirsaganj",
        "value": "Sirsaganj"
    },
    {
        "label": "Sirsi",
        "value": "Sirsi"
    },
    {
        "label": "Sirsi",
        "value": "Sirsi"
    },
    {
        "label": "Siruguppa",
        "value": "Siruguppa"
    },
    {
        "label": "Sitamarhi",
        "value": "Sitamarhi"
    },
    {
        "label": "Sitapur",
        "value": "Sitapur"
    },
    {
        "label": "Sitarganj",
        "value": "Sitarganj"
    },
    {
        "label": "Sivaganga",
        "value": "Sivaganga"
    },
    {
        "label": "Sivagiri",
        "value": "Sivagiri"
    },
    {
        "label": "Sivakasi",
        "value": "Sivakasi"
    },
    {
        "label": "Siwan",
        "value": "Siwan"
    },
    {
        "label": "Sohagpur",
        "value": "Sohagpur"
    },
    {
        "label": "Sohna",
        "value": "Sohna"
    },
    {
        "label": "Sojat",
        "value": "Sojat"
    },
    {
        "label": "Solan",
        "value": "Solan"
    },
    {
        "label": "Solapur",
        "value": "Solapur"
    },
    {
        "label": "Sonamukhi",
        "value": "Sonamukhi"
    },
    {
        "label": "Sonepur",
        "value": "Sonepur"
    },
    {
        "label": "Songadh",
        "value": "Songadh"
    },
    {
        "label": "Sonipat",
        "value": "Sonipat"
    },
    {
        "label": "Sopore",
        "value": "Sopore"
    },
    {
        "label": "Soro",
        "value": "Soro"
    },
    {
        "label": "Soron",
        "value": "Soron"
    },
    {
        "label": "Soyagaon",
        "value": "Soyagaon"
    },
    {
        "label": "Sri Madhopur",
        "value": "Sri Madhopur"
    },
    {
        "label": "Srikakulam",
        "value": "Srikakulam"
    },
    {
        "label": "Srikalahasti",
        "value": "Srikalahasti"
    },
    {
        "label": "Srinagar",
        "value": "Srinagar"
    },
    {
        "label": "Srinagar",
        "value": "Srinagar"
    },
    {
        "label": "Srinivaspur",
        "value": "Srinivaspur"
    },
    {
        "label": "Srirampore",
        "value": "Srirampore"
    },
    {
        "label": "Srisailam Project (Right Flank Colony) Township",
        "value": "Srisailam Project (Right Flank Colony) Township"
    },
    {
        "label": "Srivilliputhur",
        "value": "Srivilliputhur"
    },
    {
        "label": "Sugauli",
        "value": "Sugauli"
    },
    {
        "label": "Sujangarh",
        "value": "Sujangarh"
    },
    {
        "label": "Sujanpur",
        "value": "Sujanpur"
    },
    {
        "label": "Sullurpeta",
        "value": "Sullurpeta"
    },
    {
        "label": "Sultanganj",
        "value": "Sultanganj"
    },
    {
        "label": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "label": "Sumerpur",
        "value": "Sumerpur"
    },
    {
        "label": "Sumerpur",
        "value": "Sumerpur"
    },
    {
        "label": "Sunabeda",
        "value": "Sunabeda"
    },
    {
        "label": "Sunam",
        "value": "Sunam"
    },
    {
        "label": "Sundargarh",
        "value": "Sundargarh"
    },
    {
        "label": "Sundarnagar",
        "value": "Sundarnagar"
    },
    {
        "label": "Supaul",
        "value": "Supaul"
    },
    {
        "label": "Surandai",
        "value": "Surandai"
    },
    {
        "label": "Surapura",
        "value": "Surapura"
    },
    {
        "label": "Surat",
        "value": "Surat"
    },
    {
        "label": "Suratgarh",
        "value": "Suratgarh"
    },
    {
        "label": "Suri",
        "value": "Suri"
    },
    {
        "label": "Suriyampalayam",
        "value": "Suriyampalayam"
    },
    {
        "label": "Suryapet",
        "value": "Suryapet"
    },
    {
        "label": "Tadepalligudem",
        "value": "Tadepalligudem"
    },
    {
        "label": "Tadpatri",
        "value": "Tadpatri"
    },
    {
        "label": "Takhatgarh",
        "value": "Takhatgarh"
    },
    {
        "label": "Taki",
        "value": "Taki"
    },
    {
        "label": "Talaja",
        "value": "Talaja"
    },
    {
        "label": "Talcher",
        "value": "Talcher"
    },
    {
        "label": "Talegaon Dabhade",
        "value": "Talegaon Dabhade"
    },
    {
        "label": "Talikota",
        "value": "Talikota"
    },
    {
        "label": "Taliparamba",
        "value": "Taliparamba"
    },
    {
        "label": "Talode",
        "value": "Talode"
    },
    {
        "label": "Talwara",
        "value": "Talwara"
    },
    {
        "label": "Tamluk",
        "value": "Tamluk"
    },
    {
        "label": "Tanda",
        "value": "Tanda"
    },
    {
        "label": "Tandur",
        "value": "Tandur"
    },
    {
        "label": "Tanuku",
        "value": "Tanuku"
    },
    {
        "label": "Tarakeswar",
        "value": "Tarakeswar"
    },
    {
        "label": "Tarana",
        "value": "Tarana"
    },
    {
        "label": "Taranagar",
        "value": "Taranagar"
    },
    {
        "label": "Taraori",
        "value": "Taraori"
    },
    {
        "label": "Tarbha",
        "value": "Tarbha"
    },
    {
        "label": "Tarikere",
        "value": "Tarikere"
    },
    {
        "label": "Tarn Taran",
        "value": "Tarn Taran"
    },
    {
        "label": "Tasgaon",
        "value": "Tasgaon"
    },
    {
        "label": "Tehri",
        "value": "Tehri"
    },
    {
        "label": "Tekkalakote",
        "value": "Tekkalakote"
    },
    {
        "label": "Tenali",
        "value": "Tenali"
    },
    {
        "label": "Tenkasi",
        "value": "Tenkasi"
    },
    {
        "label": "Tenu dam-cum-Kathhara",
        "value": "Tenu dam-cum-Kathhara"
    },
    {
        "label": "Terdal",
        "value": "Terdal"
    },
    {
        "label": "Tezpur",
        "value": "Tezpur"
    },
    {
        "label": "Thakurdwara",
        "value": "Thakurdwara"
    },
    {
        "label": "Thammampatti",
        "value": "Thammampatti"
    },
    {
        "label": "Thana Bhawan",
        "value": "Thana Bhawan"
    },
    {
        "label": "Thane",
        "value": "Thane"
    },
    {
        "label": "Thanesar",
        "value": "Thanesar"
    },
    {
        "label": "Thangadh",
        "value": "Thangadh"
    },
    {
        "label": "Thanjavur",
        "value": "Thanjavur"
    },
    {
        "label": "Tharad",
        "value": "Tharad"
    },
    {
        "label": "Tharamangalam",
        "value": "Tharamangalam"
    },
    {
        "label": "Tharangambadi",
        "value": "Tharangambadi"
    },
    {
        "label": "Theni Allinagaram",
        "value": "Theni Allinagaram"
    },
    {
        "label": "Thirumangalam",
        "value": "Thirumangalam"
    },
    {
        "label": "Thirupuvanam",
        "value": "Thirupuvanam"
    },
    {
        "label": "Thiruthuraipoondi",
        "value": "Thiruthuraipoondi"
    },
    {
        "label": "Thiruvalla",
        "value": "Thiruvalla"
    },
    {
        "label": "Thiruvallur",
        "value": "Thiruvallur"
    },
    {
        "label": "Thiruvananthapuram",
        "value": "Thiruvananthapuram"
    },
    {
        "label": "Thiruvarur",
        "value": "Thiruvarur"
    },
    {
        "label": "Thodupuzha",
        "value": "Thodupuzha"
    },
    {
        "label": "Thoubal",
        "value": "Thoubal"
    },
    {
        "label": "Thrissur",
        "value": "Thrissur"
    },
    {
        "label": "Thuraiyur",
        "value": "Thuraiyur"
    },
    {
        "label": "Tikamgarh",
        "value": "Tikamgarh"
    },
    {
        "label": "Tilda Newra",
        "value": "Tilda Newra"
    },
    {
        "label": "Tilhar",
        "value": "Tilhar"
    },
    {
        "label": "Tindivanam",
        "value": "Tindivanam"
    },
    {
        "label": "Tinsukia",
        "value": "Tinsukia"
    },
    {
        "label": "Tiptur",
        "value": "Tiptur"
    },
    {
        "label": "Tirora",
        "value": "Tirora"
    },
    {
        "label": "Tiruchendur",
        "value": "Tiruchendur"
    },
    {
        "label": "Tiruchengode",
        "value": "Tiruchengode"
    },
    {
        "label": "Tiruchirappalli",
        "value": "Tiruchirappalli"
    },
    {
        "label": "Tirukalukundram",
        "value": "Tirukalukundram"
    },
    {
        "label": "Tirukkoyilur",
        "value": "Tirukkoyilur"
    },
    {
        "label": "Tirunelveli",
        "value": "Tirunelveli"
    },
    {
        "label": "Tirupathur",
        "value": "Tirupathur"
    },
    {
        "label": "Tirupathur",
        "value": "Tirupathur"
    },
    {
        "label": "Tirupati",
        "value": "Tirupati"
    },
    {
        "label": "Tiruppur",
        "value": "Tiruppur"
    },
    {
        "label": "Tirur",
        "value": "Tirur"
    },
    {
        "label": "Tiruttani",
        "value": "Tiruttani"
    },
    {
        "label": "Tiruvannamalai",
        "value": "Tiruvannamalai"
    },
    {
        "label": "Tiruvethipuram",
        "value": "Tiruvethipuram"
    },
    {
        "label": "Tiruvuru",
        "value": "Tiruvuru"
    },
    {
        "label": "Tirwaganj",
        "value": "Tirwaganj"
    },
    {
        "label": "Titlagarh",
        "value": "Titlagarh"
    },
    {
        "label": "Tittakudi",
        "value": "Tittakudi"
    },
    {
        "label": "Todabhim",
        "value": "Todabhim"
    },
    {
        "label": "Todaraisingh",
        "value": "Todaraisingh"
    },
    {
        "label": "Tohana",
        "value": "Tohana"
    },
    {
        "label": "Tonk",
        "value": "Tonk"
    },
    {
        "label": "Tuensang",
        "value": "Tuensang"
    },
    {
        "label": "Tuljapur",
        "value": "Tuljapur"
    },
    {
        "label": "Tulsipur",
        "value": "Tulsipur"
    },
    {
        "label": "Tumkur",
        "value": "Tumkur"
    },
    {
        "label": "Tumsar",
        "value": "Tumsar"
    },
    {
        "label": "Tundla",
        "value": "Tundla"
    },
    {
        "label": "Tuni",
        "value": "Tuni"
    },
    {
        "label": "Tura",
        "value": "Tura"
    },
    {
        "label": "Uchgaon",
        "value": "Uchgaon"
    },
    {
        "label": "Udaipur",
        "value": "Udaipur"
    },
    {
        "label": "Udaipur",
        "value": "Udaipur"
    },
    {
        "label": "Udaipurwati",
        "value": "Udaipurwati"
    },
    {
        "label": "Udgir",
        "value": "Udgir"
    },
    {
        "label": "Udhagamandalam",
        "value": "Udhagamandalam"
    },
    {
        "label": "Udhampur",
        "value": "Udhampur"
    },
    {
        "label": "Udumalaipettai",
        "value": "Udumalaipettai"
    },
    {
        "label": "Udupi",
        "value": "Udupi"
    },
    {
        "label": "Ujhani",
        "value": "Ujhani"
    },
    {
        "label": "Ujjain",
        "value": "Ujjain"
    },
    {
        "label": "Umarga",
        "value": "Umarga"
    },
    {
        "label": "Umaria",
        "value": "Umaria"
    },
    {
        "label": "Umarkhed",
        "value": "Umarkhed"
    },
    {
        "label": "Umbergaon",
        "value": "Umbergaon"
    },
    {
        "label": "Umred",
        "value": "Umred"
    },
    {
        "label": "Umreth",
        "value": "Umreth"
    },
    {
        "label": "Una",
        "value": "Una"
    },
    {
        "label": "Unjha",
        "value": "Unjha"
    },
    {
        "label": "Unnamalaikadai",
        "value": "Unnamalaikadai"
    },
    {
        "label": "Unnao",
        "value": "Unnao"
    },
    {
        "label": "Upleta",
        "value": "Upleta"
    },
    {
        "label": "Uran",
        "value": "Uran"
    },
    {
        "label": "Uran Islampur",
        "value": "Uran Islampur"
    },
    {
        "label": "Uravakonda",
        "value": "Uravakonda"
    },
    {
        "label": "Urmar Tanda",
        "value": "Urmar Tanda"
    },
    {
        "label": "Usilampatti",
        "value": "Usilampatti"
    },
    {
        "label": "Uthamapalayam",
        "value": "Uthamapalayam"
    },
    {
        "label": "Uthiramerur",
        "value": "Uthiramerur"
    },
    {
        "label": "Utraula",
        "value": "Utraula"
    },
    {
        "label": "Vadakkuvalliyur",
        "value": "Vadakkuvalliyur"
    },
    {
        "label": "Vadalur",
        "value": "Vadalur"
    },
    {
        "label": "Vadgaon Kasba",
        "value": "Vadgaon Kasba"
    },
    {
        "label": "Vadipatti",
        "value": "Vadipatti"
    },
    {
        "label": "Vadnagar",
        "value": "Vadnagar"
    },
    {
        "label": "Vadodara",
        "value": "Vadodara"
    },
    {
        "label": "Vaijapur",
        "value": "Vaijapur"
    },
    {
        "label": "Vaikom",
        "value": "Vaikom"
    },
    {
        "label": "Valparai",
        "value": "Valparai"
    },
    {
        "label": "Valsad",
        "value": "Valsad"
    },
    {
        "label": "Vandavasi",
        "value": "Vandavasi"
    },
    {
        "label": "Vaniyambadi",
        "value": "Vaniyambadi"
    },
    {
        "label": "Vapi",
        "value": "Vapi"
    },
    {
        "label": "Vapi",
        "value": "Vapi"
    },
    {
        "label": "Varanasi",
        "value": "Varanasi"
    },
    {
        "label": "Varkala",
        "value": "Varkala"
    },
    {
        "label": "Vasai-Virar",
        "value": "Vasai-Virar"
    },
    {
        "label": "Vatakara",
        "value": "Vatakara"
    },
    {
        "label": "Vedaranyam",
        "value": "Vedaranyam"
    },
    {
        "label": "Vellakoil",
        "value": "Vellakoil"
    },
    {
        "label": "Vellore",
        "value": "Vellore"
    },
    {
        "label": "Venkatagiri",
        "value": "Venkatagiri"
    },
    {
        "label": "Veraval",
        "value": "Veraval"
    },
    {
        "label": "Vidisha",
        "value": "Vidisha"
    },
    {
        "label": "Vijainagar, Ajmer",
        "value": "Vijainagar, Ajmer"
    },
    {
        "label": "Vijapur",
        "value": "Vijapur"
    },
    {
        "label": "Vijayapura",
        "value": "Vijayapura"
    },
    {
        "label": "Vijayawada",
        "value": "Vijayawada"
    },
    {
        "label": "Vijaypur",
        "value": "Vijaypur"
    },
    {
        "label": "Vikarabad",
        "value": "Vikarabad"
    },
    {
        "label": "Vikramasingapuram",
        "value": "Vikramasingapuram"
    },
    {
        "label": "Viluppuram",
        "value": "Viluppuram"
    },
    {
        "label": "Vinukonda",
        "value": "Vinukonda"
    },
    {
        "label": "Viramgam",
        "value": "Viramgam"
    },
    {
        "label": "Virudhachalam",
        "value": "Virudhachalam"
    },
    {
        "label": "Virudhunagar",
        "value": "Virudhunagar"
    },
    {
        "label": "Visakhapatnam",
        "value": "Visakhapatnam"
    },
    {
        "label": "Visnagar",
        "value": "Visnagar"
    },
    {
        "label": "Viswanatham",
        "value": "Viswanatham"
    },
    {
        "label": "Vita",
        "value": "Vita"
    },
    {
        "label": "Vizianagaram",
        "value": "Vizianagaram"
    },
    {
        "label": "Vrindavan",
        "value": "Vrindavan"
    },
    {
        "label": "Vyara",
        "value": "Vyara"
    },
    {
        "label": "Wadgaon Road",
        "value": "Wadgaon Road"
    },
    {
        "label": "Wadhwan",
        "value": "Wadhwan"
    },
    {
        "label": "Wadi",
        "value": "Wadi"
    },
    {
        "label": "Wai",
        "value": "Wai"
    },
    {
        "label": "Wanaparthy",
        "value": "Wanaparthy"
    },
    {
        "label": "Wani",
        "value": "Wani"
    },
    {
        "label": "Wankaner",
        "value": "Wankaner"
    },
    {
        "label": "Wara Seoni",
        "value": "Wara Seoni"
    },
    {
        "label": "Warangal",
        "value": "Warangal"
    },
    {
        "label": "Wardha",
        "value": "Wardha"
    },
    {
        "label": "Warhapur",
        "value": "Warhapur"
    },
    {
        "label": "Warisaliganj",
        "value": "Warisaliganj"
    },
    {
        "label": "Warora",
        "value": "Warora"
    },
    {
        "label": "Warud",
        "value": "Warud"
    },
    {
        "label": "Washim",
        "value": "Washim"
    },
    {
        "label": "Wokha",
        "value": "Wokha"
    },
    {
        "label": "Yadgir",
        "value": "Yadgir"
    },
    {
        "label": "Yamunanagar",
        "value": "Yamunanagar"
    },
    {
        "label": "Yanam",
        "value": "Yanam"
    },
    {
        "label": "Yavatmal",
        "value": "Yavatmal"
    },
    {
        "label": "Yawal",
        "value": "Yawal"
    },
    {
        "label": "Yellandu",
        "value": "Yellandu"
    },
    {
        "label": "Yemmiganur",
        "value": "Yemmiganur"
    },
    {
        "label": "Yerraguntla",
        "value": "Yerraguntla"
    },
    {
        "label": "Yevla",
        "value": "Yevla"
    },
    {
        "label": "Zaidpur",
        "value": "Zaidpur"
    },
    {
        "label": "Zamania",
        "value": "Zamania"
    },
    {
        "label": "Zira",
        "value": "Zira"
    },
    {
        "label": "Zirakpur",
        "value": "Zirakpur"
    },
    {
        "label": "Zunheboto",
        "value": "Zunheboto"
    }
]