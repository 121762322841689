import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../components/core/Header/Header'

const Notifications = () => {
    return (
        <Container>
            <Row
                className="justify-content-center align-content-center p-2"
                style={{ height: '100%', marginTop: 80 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header title={'Notifications'} />
                </Col>
                <p style={{ color: '#fff', opacity: 0.6 }}>No notifications</p>
                {/* <Card style={{ margin: 8, borderRadius: 16, border: 0 }} className="shadow">
                    <Card.Body style={{
                        padding: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: '#fff',
                        borderRadius: 16
                    }}
                    >
                        <p className='title'>Your Reward</p>
                        <p className='subtitle'>You can Withdraw only when it is ≤ ₹100</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 16 }}>
                            <p className='amt'>₹15</p>
                            <div className='get-started-btn'>
                                <img src={Rupees} />
                                <p>Withdraw</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Row style={{ marginTop: 20 }} className='p-0'>
                    <Col md={9} sm={9} xs={9} className='p-0 m-0'>
                        <div className='referral' style={{ width: '100%' }}>
                            <p>lcmdkl</p>
                        </div>
                    </Col>
                    <Col md={3} sm={3} xs={3} >
                        <div className='copy-wrap'>
                            <img src={Copy} style={{ marginTop: 8, marginBottom: 8 }} />
                        </div>
                    </Col>
                </Row> */}
            </Row>
        </Container>
    )
}

export default Notifications