import React from 'react'
import { Container, Row } from 'react-bootstrap'

const Blocked = () => {
    return (
        <Container>
            <Row className="justify-content-center align-content-center" >
                <p style={{fontSize: 100, marginTop: 100}}>😕</p>
                <h1 style={{color: '#fff'}}>Your account has been blocked</h1>
            </Row>
        </Container>
    )
}

export default Blocked