import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './RecommendationChallengeModal.css'
import UploadSvg from '../../../assets/images/svgs/upload.svg'
import CloseSvg from '../../../assets/images/svgs/close.svg'
// import UploadSvg from '../../../assets/images/svgs/upload.svg'
import CustomInput from '../../core/CustomInput/CustomInput'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BASE_URL, BASE_URL_PROD } from '../../../utils/config'
import { mapCategory } from '../../../utils/mapCategory'
import { useNavigate } from 'react-router'
import { getData } from '../../../utils/axiosInstance'
import CustomWebCam from '../../core/WebCam/CustomWebCam'

const OptionCard = ({ label, active, onSelect }) =>
    <div
        style={{ display: 'flex', flex: 1, padding: '0px 16px', cursor: 'pointer' }}
        onClick={() => onSelect(label)}
    >
        <div className='option-radio'>
            {active && <div className='option-radio-selected' />}
        </div>
        <p className='option-label'>{label}</p>
    </div>

const RecommendationChallengeModal = ({ show, close, selected, onComplete }) => {
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const [fileSelected, setFileSelected] = useState(null);
    const [selectedOther, setSselectedOther] = useState(false);
    const [selectedOtherRec, setSelectedOtherRec] = useState(false);
    const [selectedFromList, setSelectedFromList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [search, setSearch] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [showAddProductBtn, setShowAddProductBtn] = useState(false);
    const [token, setToken] = useState(null);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [cameraPopup, setCameraPopup] = useState(false);
    const [recommendationDetails, setRecommendationDetails] = useState({
        product_name: '',
        brand_name: '',
        reason_to_like: '',
        time_being_used: '',
        recommended_by: '',
        is_recommended: '',
        shopFrom: '',
        whatsapp_number: '',
        product_type: selected?.product || '',
        category: selected?.category || ''
    })

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData)
        const jwtToken = localStorage.getItem('jwtToken');
        setToken(jwtToken)
    }, []);

    const product = selected?.product || 'product'

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const handleFileSelect = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        setFileSelected(file);
    }

    const resetData = () => {
        setRecommendationDetails({
            ...recommendationDetails,
            product_name: '',
            brand_name: '',
            reason_to_like: '',
            time_being_used: '',
            recommended_by: '',
            is_recommended: '',
            shopFrom: '',
            whatsapp_number: '',
            category: '',
            product_type: ''
        })
        setFileSelected(null)
    }

    const onPost = async (file) => {
        if (showAddProduct) {
            if (!recommendationDetails?.brand_name) {
                toast.warn('Brand name is required')
                return
            }
        }
        if (!selectedFromList) {
            if (!showAddProduct) {
                toast.warn('Product should be selected from the list')
                return
            }
        }

        // if (!showAddProduct) {
        //     // if (!showAddProduct) {
        //     console.log('heree...........');
        //     toast.warn('Product should be selected from the list')
        //     return
        //     // }
        // }

        if (!recommendationDetails?.product_name) {
            toast.warn('Product name is required')
            return
        }
        if (!recommendationDetails.reason_to_like) {
            toast.warn('Please enter why do you like the product')
            return
        }
        if (recommendationDetails.reason_to_like?.trim()?.split(/\s+/)?.length < 15) {
            toast.warn('Reason to like/dislike should not be less than 15 words')
            return
        }
        if (!recommendationDetails.time_being_used) {
            toast.warn('Please select How long have you been using this product?')
            return
        }
        if (!recommendationDetails.recommended_by) {
            toast.warn('Please select who recommended the product')
            return
        }
        if (!recommendationDetails.is_recommended) {
            toast.warn('Please select would you recommend the product?')
            return
        }
        if (!recommendationDetails.shopFrom) {
            toast.warn('Please select Where do you get your skincare from?')
            return
        }
        if (!file) {
            toast.warn('Please Upload image to submit')
            return
        }
        else {
            setLoading(true);
            if (file) {
                new Promise((resolve, reject) => {
                    var data = new FormData();
                    if (file) {
                        data.append('file', file);
                    }
                    data.append('product_name', recommendationDetails?.product_name);
                    data.append('brand_name', recommendationDetails?.brand_name);
                    data.append('reason_to_like', recommendationDetails?.reason_to_like);
                    data.append('time_being_used', recommendationDetails?.time_being_used);
                    data.append('recommended_by', recommendationDetails?.recommended_by);
                    data.append('is_recommended', recommendationDetails?.is_recommended);
                    data.append('shopFrom', recommendationDetails?.shopFrom);
                    data.append('whatsapp_number', recommendationDetails?.whatsapp_number);
                    data.append('category', recommendationDetails?.category)
                    data.append('product_type', recommendationDetails?.product_type)
                    
                    return axios.post(
                        `${BASE_URL}challenge/${selected?.cd_id}/user/${user?.userID}/accept/upload`,
                        data,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${token}`,
                            }
                        }).then(response => {
                            console.log('upload image response.. ', response?.data);
                            resolve(response);
                            setLoading(false);
                            setShowAddProduct(false)
                            setShowAddProduct(false)
                            setSearchResult([])
                            setShowSearch(false)
                            setSselectedOther(false)
                            setSelectedOtherRec(false)
                            resetData()
                            // close()
                            onComplete()
                            // uploadComplete()

                        })
                        .catch(error => {
                            reject(error)
                            console.log(error, '...........', error?.response);
                            setLoading(false)
                            resetData()
                            // onComplete()
                            toast.warn('Something went wrong, please try again')
                            if(error?.response?.status == 403) {
                                navigate('/blocked')
                            }
                        });
                });
            } else {
                const reqBody = {
                    ...recommendationDetails,
                    category: selected?.category || null
                }
                return axios.post(`${BASE_URL}influencer/${selected?.cd_id}/user/${user?.userID}/recommendation/noimage`, reqBody,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    }).then(response => {
                        console.log('upload image response.. ', response?.data);
                        setLoading(false);
                        setShowAddProduct(false)
                        setShowAddProduct(false)
                        setSearchResult([])
                        setShowSearch(false)
                        setSselectedOther(false)
                        setSelectedOtherRec(false)
                        resetData()
                        onComplete()

                    })
                    .catch(error => {
                        console.log(error, '...........', error?.response);
                        setLoading(false)
                        resetData()
                        // onComplete()
                        toast.warn('Something went wrong, please try again')
                        if(error?.response?.status == 403) {
                            navigate('/blocked')
                        }
                    });
            }
        }
    }

    const onSearch =async (productKey) => {
        setSearch(productKey);
        setRecommendationDetails({ ...recommendationDetails, product_name: productKey })
        setShowAddProductBtn(false)
        if (productKey?.length > 3) {
            const url = `${BASE_URL}search/product/autofill?key=${productKey}&type=${mapCategory(selected?.name)}`
            axios.get(url)
                .then(response => {
                    setSearchResult(response?.data?.items)
                    setShowSearch(true);
                })
                .catch(error => {
                    console.error('Error:', error)
                    setShowSearch(true);
                    // console.log('heree................??');
                    // setShowSearch(false);
                    // setShowAddProductBtn(true);
                });
        } else {
            setSearchResult([])
            setShowSearch(false)
        }
    }

    return (
        <Modal
            show={show}
            onHide={close}
            style={{ alignSelf: 'flex-end', marginTop: 66 }}
        >
            <div className='p-4' style={{ backgroundColor: '#25387C', borderRadius: 14 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: 5, width: 100, backgroundColor: '#fff', alignSelf: 'center', display: 'flex', borderRadius: 6 }} />
                </div>
                <div className='scrolling-comp pt-3'>
                    <div
                        style={{ position: 'absolute', right: 16, cursor: 'pointer' }}
                        onClick={close}
                    >
                        <img src={CloseSvg} style={{ height: 26, width: 26 }} />
                    </div>
                    <p className='form-title'>Fill the details</p>
                    {!showAddProduct && <>
                        <p className='input-title' style={{ marginBottom: 10 }}>
                            Which {product} do you use?
                        </p>
                        <CustomInput
                            placeholder={'Type product name'}
                            val={recommendationDetails?.product_name}
                            onChange={(e) => onSearch(e.target.value)}
                            style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                        />
                    </>}
                    {/* {(showAddProductBtn && !showAddProduct) && <div
                        className='addprod'
                        onClick={() => {
                            setShowAddProduct(true)
                            setRecommendationDetails({ ...recommendationDetails, product_name: '' })
                        }
                        }
                    >
                        <p><span style={{ opacity: 0.6 }}>Unable to find product you're looking for?</span> Add Product</p>
                    </div>} */}
                    {showAddProduct
                        && <>
                            <p className='input-title' style={{ marginBottom: 10 }}>
                                Which Brand's {product} do you use?
                            </p>
                            <CustomInput
                                placeholder={'Type Brand name'}
                                val={recommendationDetails?.brand_name}
                                onChange={(e) => setRecommendationDetails({ ...recommendationDetails, brand_name: e.target.value })}
                                style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                            />
                            <p className='input-title' style={{ marginBottom: 10 }}>
                                Which {product} do you use?
                            </p>
                            <CustomInput
                                placeholder={'Type product name'}
                                val={recommendationDetails?.product_name}
                                onChange={(e) => setRecommendationDetails({ ...recommendationDetails, product_name: e.target.value })}
                                style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                            />
                        </>}
                    {(showSearch && !showAddProduct) && <div className='scroll-products'>
                        {searchResult.map(({ product_name }) => <p
                            className='product-name'
                            onClick={() => {
                                setRecommendationDetails({ ...recommendationDetails, product_name: product_name })
                                setSelectedFromList(true)
                                setShowSearch(false)
                            }}
                        >
                            {product_name}
                        </p>
                        )}
                        <div
                            className='upload-btn'
                            style={{ width: 160, marginTop: 18, cursor: 'pointer' }}
                            onClick={() => {
                                setShowAddProduct(true)
                                setRecommendationDetails({ ...recommendationDetails, product_name: '' })
                            }}
                        >
                            <img src={UploadSvg} style={{ height: 20, width: 20 }} />
                            <p>Add Product</p>
                        </div>
                    </div>}
                    <p className='input-title' style={{ marginBottom: 10 }}>
                        Can you tell us why you like this {product}?
                    </p>
                    <CustomInput
                        placeholder={'Answer'}
                        val={recommendationDetails?.reason_to_like}
                        // onChange={(e) => setRecommendationDetails({ ...recommendationDetails, reason_to_like: e.target.value })}
                        onChange={(e) => {
                            e.preventDefault()
                            setRecommendationDetails({ ...recommendationDetails, reason_to_like: e.target.value })
                        }}
                        style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                        onPaste={(e) => {
                            e.preventDefault()
                            toast.warn('Pasting is not allowed in this input field')
                            setRecommendationDetails({ ...recommendationDetails, reason_to_like: '' })
                        }}
                    />
                    <p style={{ fontSize: 12, color: '#fff', opacity: 0.6, marginLeft: 16 }}>
                        Explain in 2-3 sentences
                    </p>
                    <p className='input-title' style={{ marginBottom: 12 }}>
                        How long have you been using this {product} for?
                    </p>
                    <OptionCard
                        label={'Less than 3 months'}
                        active={recommendationDetails?.time_being_used == 'Less than 3 months'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, time_being_used: val })}
                    />
                    <OptionCard
                        label={'3 to 6 months'}
                        active={recommendationDetails?.time_being_used == '3 to 6 months'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, time_being_used: val })}
                    />
                    <OptionCard
                        label={'6 to 12 months'}
                        active={recommendationDetails?.time_being_used == '6 to 12 months'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, time_being_used: val })}
                    />
                    <OptionCard
                        label={'More than one year'}
                        active={recommendationDetails?.time_being_used == 'More than one year'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, time_being_used: val })}
                    />

                    <p className='input-title' style={{ marginBottom: 12 }}>
                        Who has recommended this {product} to you?
                    </p>

                    <OptionCard
                        label={'Dermatologist'}
                        active={recommendationDetails?.recommended_by == 'Dermatologist'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />
                    <OptionCard
                        label={'Friends/family member'}
                        active={recommendationDetails?.recommended_by == 'Friends/family member'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />
                    <OptionCard
                        label={'Influencer'}
                        active={recommendationDetails?.recommended_by == 'Influencer'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />
                    <OptionCard
                        label={'Social Media (eg: Facebook, Instagram, YouTube, etc)'}
                        active={recommendationDetails?.recommended_by == 'Social Media (eg: Facebook, Instagram, YouTube, etc)'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />
                    <OptionCard
                        label={'Ads on TV, billboards'}
                        active={recommendationDetails?.recommended_by == 'Ads on TV, billboards'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />

                    <OptionCard
                        label={'Sales person at the store'}
                        active={recommendationDetails?.recommended_by == 'Sales person at the store'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: val })
                            setSelectedOtherRec(false)
                        }}
                    />
                    <OptionCard
                        label={'Other (please specify)'}
                        active={selectedOtherRec}
                        onSelect={(val) => {
                            setSelectedOtherRec(true)
                            setRecommendationDetails({ ...recommendationDetails, recommended_by: '' })
                        }}
                    />
                    <div style={{ marginTop: -12 }}>
                        {selectedOtherRec
                            && <CustomInput
                                placeholder={'Please specify'}
                                val={recommendationDetails?.recommended_by}
                                onChange={(e) => setRecommendationDetails({
                                    ...recommendationDetails,
                                    recommended_by: e.target.value
                                })}
                                style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12, marginTop: 10 }}
                            />}
                    </div>
                    <p className='input-title' style={{ marginBottom: 12 }}>
                        Would you recommend this {product} to someone?
                    </p>
                    <OptionCard
                        label={'Yes'}
                        active={recommendationDetails?.is_recommended == 'Yes'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, is_recommended: val })}
                    />
                    <OptionCard
                        label={'No'}
                        active={recommendationDetails?.is_recommended == 'No'}
                        onSelect={(val) => setRecommendationDetails({ ...recommendationDetails, is_recommended: val })}
                    />
                    <p className='input-title' style={{ marginBottom: 12 }}>
                        Where do you get your skincare from?
                    </p>
                    <OptionCard
                        label={'Nykaa'}
                        active={recommendationDetails?.shopFrom == 'Nykaa'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: val })
                            setSselectedOther(false)
                        }}
                    />
                    <OptionCard
                        label={'Amazon'}
                        active={recommendationDetails?.shopFrom == 'Amazon'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: val })
                            setSselectedOther(false)
                        }}
                    />
                    <OptionCard
                        label={'Purplle'}
                        active={recommendationDetails?.shopFrom == 'Purplle'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: val })
                            setSselectedOther(false)
                        }}
                    />
                    <OptionCard
                        label={'Flipkart'}
                        active={recommendationDetails?.shopFrom == 'Flipkart'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: val })
                            setSselectedOther(false)
                        }}
                    />
                    <OptionCard
                        label={'Local stores'}
                        active={recommendationDetails?.shopFrom == 'Local stores'}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: val })
                            setSselectedOther(false)
                        }}
                    />
                    <OptionCard
                        label={'Other (please specify)'}
                        active={selectedOther}
                        onSelect={(val) => {
                            setRecommendationDetails({ ...recommendationDetails, shopFrom: '' })
                            setSselectedOther(true)
                        }}
                    />
                    <div style={{ marginTop: -12 }}>
                        {selectedOther
                            && <CustomInput
                                placeholder={'Please specify'}
                                val={recommendationDetails?.shopFrom}
                                onChange={(e) => setRecommendationDetails({
                                    ...recommendationDetails,
                                    shopFrom: e.target.value
                                })}
                                style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12, marginTop: 10 }}
                            />}
                    </div>
                    {/* <p className='input-title' style={{
                        marginBottom: -6
                    }}>
                        Share your Whatsapp Number?
                    </p>
                    <CustomInput
                        placeholder={'Whatsapp Number'}
                        val={recommendationDetails?.whatsapp_number}
                        onChange={(e) => {
                            if (e.target.value?.length < 11) {
                                setRecommendationDetails({
                                    ...recommendationDetails,
                                    whatsapp_number: e.target.value
                                })
                            }
                        }}
                    /> */}

                    <p className='input-title'>
                        Submit a photo of yourself with the {product}?
                    </p>

                    {fileSelected
                        ? <p
                            style={{ fontSize: 16, opacity: 0.6, padding: '16px 16px', color: '#fff', cursor: 'pointer' }}
                            onClick={() => setFileSelected(null)}
                        >
                            {fileSelected?.name} <img src={CloseSvg} style={{ height: 16, width: 16, marginLeft: 16 }} />
                        </p>
                        : <div
                            className='upload-btn'
                            style={{ width: 160, marginTop: 18, marginLeft: 16, cursor: 'pointer' }}
                            // onClick={onButtonClick}
                            onClick={() => setCameraPopup(true)}
                        >
                            <img src={UploadSvg} style={{ height: 20, width: 20 }} />
                            <p>Upload Image</p>
                        </div>}
                    <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileSelect} />


                    <div
                        className='save-btn'
                        style={{ opacity: loading ? 0.6 : 1 }}
                        onClick={() => !loading && onPost(fileSelected)}
                    >
                        <p>Save & upload</p>
                    </div>
                    <div style={{ height: 160 }} />
                </div>
            </div>
            <CustomWebCam
                show={cameraPopup}
                close={() => setCameraPopup(false)}
                onSubmit={(file) => {
                    setFileSelected(file)
                    setCameraPopup(false)
                }}
                product={recommendationDetails?.product_type}
            />
        </Modal>
    )
}

export default RecommendationChallengeModal