export const decideHairType = (hairTypeOpts) => {
    const aCount = hairTypeOpts.filter(x => x == 'A')?.length;
    const bCount = hairTypeOpts.filter(x => x == 'B')?.length;
    const cCount = hairTypeOpts.filter(x => x == 'C')?.length;
    // const dCount = hairTypeOpts.filter(x => x == 'D')?.length;
    if (aCount > 2) return 'Straight'
    if (bCount > 2) return 'Wavy'
    if (cCount > 2) return 'Curly'
    if (cCount == 2) return 'Curly'
    // if (dCount > 2) return 'Curly Hair'
    if (aCount == 2 && bCount == 2) {
        if (cCount = 1) {
            return 'Straight'
        } else return 'Wavy'
    }
    if (aCount == 2 && cCount == 2) {
        if (bCount == 1) {
            return 'Wavy'
        } else return 'Curly'
    }
    if (bCount == 2 && cCount == 2) {
        if (aCount == 1) {
            return 'Wavy'
        } else return 'Curly'
    }
    if (aCount == 2) return 'Straight'
    if (bCount == 2) return 'Wavy'
    if (cCount == 2) return 'Curly'
}

export const decideScalpType = (scalpTypeOpts) => {
    const aCount = scalpTypeOpts.filter(x => x == 'A')?.length;
    const bCount = scalpTypeOpts.filter(x => x == 'B')?.length;
    const cCount = scalpTypeOpts.filter(x => x == 'C')?.length;
    const dCount = scalpTypeOpts.filter(x => x == 'D')?.length;

    if (aCount > 2) return 'Normal'
    if (bCount > 2) return 'Dry'
    if (cCount > 2) return 'Oily'
    if (dCount >= 2) return 'Irritated/Sensitive'
    if ((aCount == 2 && bCount == 2) || (aCount == 2 && cCount == 2)) return 'Normal'
    if ((bCount == 2 && cCount == 2)) return 'Dry'
    if (aCount = 2) return 'Normal'
    if (bCount = 2) return 'Dry'
    if (cCount = 2) return 'Oily'
    return 'Normal'
}


export const decideSkinTone = (skinToneOpts) => {
    let aCount = skinToneOpts.filter(x => x == 'A')?.length;
    let bCount = skinToneOpts.filter(x => x == 'B')?.length;
    let cCount = skinToneOpts.filter(x => x == 'C')?.length;
    let dCount = skinToneOpts.filter(x => x == 'D')?.length;

    if (aCount > 2) return 'Fair'
    if (bCount > 2) return 'Light'
    if (cCount > 2) return 'Medium'
    if (dCount > 2) return 'Dark'
    if ((bCount == 2 && aCount == 2)) return 'Light'
    if ((aCount == 2 && cCount == 2) || (aCount == 2 && dCount == 2) || (bCount == 2 && cCount == 2)) return 'Medium'
    if ((bCount == 2 && dCount == 2) || (cCount == 2 && dCount == 2)) return 'Dark'
    if (aCount = 2) return 'Fair'
    if (bCount = 2) return 'Light'
    if (cCount = 2) return 'Medium'
    if (dCount = 2) return 'Dark'
    return 'Medium'
}



export const decideSkinType = (categories) => {
    const aCount = categories?.filter(x => x == 'A')?.length;
    const bCount = categories?.filter(x => x == 'B')?.length;
    const cCount = categories?.filter(x => x == 'C')?.length;
    const dCount = categories?.filter(x => x == 'D')?.length;

    if (dCount > 1) return 'Sensitive'
    else if (aCount > 2) return 'Oily'
    else if (bCount > 2) return 'Dry'
    else if (cCount > 2) return 'Combination'
    else if ((aCount > 1 && bCount > 1) || (aCount > 1 && cCount > 1) || (bCount > 1 && cCount > 1)) return 'Combination'
    else return 'Combination'
}