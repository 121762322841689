import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './CustomInput.css'
import { toast } from 'react-toastify'

const CustomInput = ({ val, placeholder, onChange, style, onPaste }) => {
  return (
    // <Container>
    <input
      className='input'
      value={val}
      placeholder={placeholder}
      onChange={onChange}
      style={style ? style : {}}
      onPaste={(e) => onPaste ? onPaste(e) : onChange(e)}
    />
    // </Container>
  )
}

export default CustomInput