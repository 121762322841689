import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './ThanksForSubmitting.css'

const ThanksForSubmitting = () => {
    return (
        <Container>
            <Row className="justify-content-center" style={{ height: '100%', paddingTop: 200 }}>
                <Col md={4} sm={12} xs={12} style={{ height: '100%' }}>
                    <div style={{ alignSelf: 'center', flexDirection: 'column' }}>
                        <p className='title-text'>
                            Thanks for submitting!
                        </p>
                        <p className='subtitle-text mt-4'>
                            Our team will share login details with you through WhatsApp in next 24 hours.
                        </p>
                    </div>
                    <div className='verify-btn'>
                        <p>View profile</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ThanksForSubmitting