import React, { useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import './UploadImageModal.css'
import Shelf from '../../../assets/images/pngs/shelf.png'
import Selfie from '../../../assets/images/pngs/selfie.png'
import Valid from '../../../assets/images/pngs/valid.png'
import ValidMakeup from '../../../assets/images/pngs/valid-makeup.png'
import Invalid from '../../../assets/images/pngs/invalid.png'
import InvalidMakeup from '../../../assets/images/pngs/invalid-makeup.png'
import ValidSvg from '../../../assets/images/svgs/valid.svg'
import InvalidSvg from '../../../assets/images/svgs/invalid.svg'
import WarningSvg from '../../../assets/images/svgs/warning.svg'
import CameraSvg from '../../../assets/images/svgs/camera.svg'
import GallerySvg from '../../../assets/images/svgs/gallery.svg'
import CloseSvg from '../../../assets/images/svgs/closeBlack.svg'
import axios from 'axios'
import { BASE_URL } from '../../../utils/config'
import { convertToBlob } from './Base64ToBlob'
import CustomWebCam from '../../core/WebCam/CustomWebCam'

const UploadImageModal = ({ show, close, selected, onComplete }) => {
    const inputFile = useRef(null);
    const [fileSelected, setFileSelected] = useState(null);
    const [showImageCapture, setShowImageCapture] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(null);
    const [token, setToken] = useState(null);
    const [cameraPopup, setCameraPopup] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData)
        const jwtToken = localStorage.getItem('jwtToken');
        setToken(jwtToken)
    }, [])

    const handleFileSelect = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        setFileSelected(file);
        onPost(file)
    }

    const onButtonClick = () => {
    // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const onPost = async (file) => {
        setLoading(true);
        new Promise((resolve, reject) => {
            var data = new FormData();
            if (file) {
                data.append('file', file);
            }
            data.append('product_name', '');
            data.append('reason_to_like', '');
            data.append('time_being_used', '');
            data.append('recommended_by', '');
            data.append('is_recommended', '');
            data.append('shopFrom', '');
            data.append('whatsapp_number', '');
            data.append('brand_name', '');
            data.append('category', selected?.category || '')
            data.append('product_type', '')
            return axios.post(
                `${BASE_URL}challenge/${selected?.cd_id}/user/${user?.userID}/accept/upload`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(response => {
                    console.log('upload image response.. ', response?.data);
                    resolve(response);
                    setLoading(false);
                    // close()
                    onComplete()
                    // uploadComplete()

                })
                .catch(error => {
                    reject(error)
                    console.log(error, '...........', error?.response);
                    // setLoading(false)
                    onComplete()
                });
        });
    }

    const videoRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            setMediaStream(stream);
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    const closeCamera = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }

    const captureImage = () => {
        const canvas = document.createElement('canvas');
        const video = videoRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const capturedImage = canvas.toDataURL('image/png');
        console.log('What is this now..............???', capturedImage);
        setImageSrc(capturedImage);
        convertToBlob(capturedImage).then(blob => {
            console.log('csjhbcjhdbjhbdb', blob)

            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'file.jpg';
            downloadLink.click();
        })
    };

    const openCamera = () => {
        setShowImageCapture(true)
        startCamera()
    }

    return (
        <>
            <Modal
                show={show}
                onHide={close}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {!showImageCapture ? <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className='take-a-pic'>Take a Photo</p>
                        <img src={CloseSvg} style={{ height: 26, width: 26, alignSelf: 'flex-end', cursor: 'pointer', marginBottom: 12 }} onClick={close} />
                    </div>
                    <p className='instruction'>
                        {selected?.description}
                    </p>
                    <img src={selected?.name == 'Share a Selfie (No makeup)' ? Selfie : Shelf} style={{ width: '100%' }} />
                    <div style={{ display: 'flex', justifyContent: 'center' }} className='mt-2'>
                        <img src={ValidSvg} style={{ height: 22, width: 22 }} />
                        <p className='valid'>Valid picture</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-2'>
                        <div style={{ width: '48%' }}>
                            <img src={selected?.name == 'Share a Selfie (No makeup)' ? InvalidMakeup : Valid} style={{ width: '100%' }} />
                            <div style={{ display: 'flex', justifyContent: 'center' }} className='mt-2'>
                                <img src={InvalidSvg} style={{ height: 22, width: 22 }} />
                                <p className='valid'>Stock Photos</p>
                            </div>
                        </div>
                        <div style={{ width: '48%' }}>
                            <img src={selected?.name == 'Share a Selfie (No makeup)' ? ValidMakeup : Invalid} style={{ width: '100%' }} />
                            <div style={{ display: 'flex', justifyContent: 'center' }} className='mt-2'>
                                <img src={InvalidSvg} style={{ height: 22, width: 22 }} />
                                <p className='valid'>{selected?.name == 'Share a Selfie (No makeup)' ? 'Makeup look' : 'Irrelevant'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='warning mt-2'>
                        <img src={WarningSvg} style={{ height: 22, width: 22 }} />
                        <p className='warningtxt'>
                            Invalid photo submissions (unrelated images, stock images, blurry images) will be rejected.
                        </p>
                    </div>

                    {!loading && <>
                        {/* <Row className='mt-4' style={{ borderBottom: '1px solid #cac4d0', cursor: 'pointer' }}>
                            <Col md={2} sm={2} xs={2} onClick={openCamera}>
                                <img src={CameraSvg} style={{ height: 30, width: 30 }} />
                            </Col>
                            <Col md={8} sm={8} xs={8} onClick={openCamera}>
                                <p className='option-txt'>Open Camera</p>
                            </Col>
                        </Row> */}
                        <Row className='mt-3' style={{ cursor: 'pointer' }}>
                            <Col md={2} sm={2} xs={2} onClick={() =>setCameraPopup(true)}>
                                <img src={GallerySvg} style={{ height: 30, width: 30 }} />
                            </Col>
                            <Col md={8} sm={8} xs={8} onClick={() =>setCameraPopup(true)}>
                                <p className='option-txt'>Upload Image</p>
                                {/* <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileSelect} /> */}
                            </Col>
                        </Row>
                    </>}
                </div>
                    : <div style={{ padding: 16 }} onClick={() => setShowImageCapture(false)}>
                        <div>
                            <div style={{ width: '100%', display: "flex", justifyContent: 'flex-end' }}>
                                <img src={CloseSvg} style={{ height: 26, width: 26, alignSelf: 'flex-end', cursor: 'pointer', marginBottom: 12 }} onClick={closeCamera} />
                            </div>
                            <video
                                style={{ width: '100%', borderRadius: 16 }}
                                ref={videoRef}
                                autoPlay
                                muted
                            />
                            <div className='save-btn' style={{ marginTop: 8, marginBottom: 8 }} onClick={captureImage}>
                                <p>Capture Image</p>
                            </div>
                        </div>
                    </div>}
                    <CustomWebCam
                show={cameraPopup}
                close={() => setCameraPopup(false)}
                onSubmit={(file) => {
                    setFileSelected(file)
                    onPost(file)
                    setCameraPopup(false)
                }}
                // product={recommendationDetails?.product_type}
            />
            </Modal>
        </>
    )
}

export default UploadImageModal