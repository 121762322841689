import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import '../../core/CopyLinkModal.js/CopyLink.css'
import { hairTypeQuestions, scalpTypeQuestions, skinToneQuestions, skinTypeQuestions } from './questions';
import chevron from "../../../assets/images/svgs/goback.svg";
import { toast } from 'react-toastify';
import { decideHairType, decideScalpType, decideSkinTone, decideSkinType } from './IdentifierFunctions';

const OptionCard = ({ label, active, onSelect }) =>
    <div
        style={{ display: 'flex', padding: '0px 16px', cursor: 'pointer' }}
        onClick={() => onSelect(label)}
    >
        <div className='option-radio' style={{ borderColor: '#000' }}>
            {active && <div className='option-radio-selected' style={{ backgroundColor: '#000' }} />}
        </div>
        <p className='option-label' style={{ color: '#000' }}>{label}</p>
    </div>

const BeautyProfileIdentifier = ({ show, close, keyId, onComplete }) => {
    const questions = keyId == 'hairType' ? hairTypeQuestions : keyId == 'scalpType' ? scalpTypeQuestions : keyId == 'skinType' ? skinTypeQuestions : skinToneQuestions
    const title = keyId == 'hairType' ? 'Hair type' : keyId == 'scalpType' ? 'Scalp Type' : keyId == 'skinType' ? 'Skin Type' : 'Skin Tone'
    const [index, setIndex] = useState(0);
    const [skinToneOpts, setSkinToneOpts] = useState(['', '', '', '', ''])
    const [scalpTypeOpts, setScalpTypeOpts] = useState(['', '', '', '', ''])
    const [hairTypeOpts, setHairTypeOpts] = useState(['', '', '', '', ''])
    const [skinTypeOpts, setSkinTypeOpts] = useState(['', '', '', '', ''])

    // const [hairType, setHairType] = useState('')
    // const [skinType, setskinType] = useState('')
    // const [scalpType, setscalpType] = useState('')
    const [type, setType] = useState(null);

    const handleChange = (val) => {
        if (keyId == 'hairType') {
            const updatedData = hairTypeOpts;
            updatedData[index] = val
            setHairTypeOpts(() => [...updatedData])
        }
        if (keyId == 'scalpType') {
            const updatedData = scalpTypeOpts;
            updatedData[index] = val
            setScalpTypeOpts(() => [...updatedData])
        }
        if (keyId == 'skinTone') {
            const updatedData = skinToneOpts;
            updatedData[index] = val
            setSkinToneOpts(() => [...updatedData])
        }
        if (keyId == 'skinType') {
            const updatedData = skinTypeOpts;
            updatedData[index] = val
            setSkinTypeOpts(() => [...updatedData])
        }
    }

    const handleNext = () => {
        if (keyId == 'hairType') {
            if (index < 4) {
                (hairTypeOpts?.[index]?.length > 0) ? setIndex(index + 1) : toast.warn('Please select one')
            } else {
                if (hairTypeOpts?.[index]?.length > 0) {
                    const typeSelected = decideHairType(hairTypeOpts)
                    setType(typeSelected)
                    onComplete(keyId, typeSelected)
                    setIndex(index + 1)
                } else toast.warn('Please select one')
            }
        }
        if (keyId == 'scalpType') {
            // scalpTypeOpts?.[index]?.length > 0 ? setIndex(index + 1) : toast.warn('Please select one')
            if (index < 4) {
                (scalpTypeOpts?.[index]?.length > 0) ? setIndex(index + 1) : toast.warn('Please select one')
            } else {
                if (scalpTypeOpts?.[index]?.length > 0) {
                    const typeSelected = decideScalpType(scalpTypeOpts)
                    setType(typeSelected)
                    onComplete(keyId, typeSelected)
                    setIndex(index + 1)
                } else toast.warn('Please select one')
            }
        }
        if (keyId == 'skinTone') {
            // skinToneOpts?.[index]?.length > 0 ? setIndex(index + 1) : toast.warn('Please select one')
            if (index < 4) {
                (skinToneOpts?.[index]?.length > 0) ? setIndex(index + 1) : toast.warn('Please select one')
            } else {
                if (skinToneOpts?.[index]?.length > 0) {
                    const typeSelected = decideSkinTone(skinToneOpts)
                    setType(typeSelected)
                    onComplete(keyId, typeSelected)
                    setIndex(index + 1)
                } else toast.warn('Please select one')
            }
        }

        if (keyId == 'skinType') {
            if (index < 4) {
                (skinTypeOpts?.[index]?.length > 0) ? setIndex(index + 1) : toast.warn('Please select one')
            } else {
                if (skinTypeOpts?.[index]?.length > 0) {
                    const typeSelected = decideSkinType(skinTypeOpts)
                    setType(typeSelected)
                    onComplete(keyId, typeSelected)
                    setIndex(index + 1)
                } else toast.warn('Please select one')
            }
        }
    }

    const QuestionComponent = ({ label, options, value }) => <>
        <p className='input-title' style={{ color: '#000', lineHeight: 1.4 }}>{label}</p>
        {
            options?.map(({ label, val }) => <OptionCard
                label={label}
                active={value == val}
                onSelect={() => handleChange(val)}
            />)
        }
    </>

    return (
        <Modal
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter br4"
            centered
        >
            <div className='p-4 container' style={{ backgroundColor: '#fff', border: '1px solid #fff' }}>
                <p style={{ fontSize: 20, textAlign: 'center', fontWeight: 600, color: '#25387C' }}>
                    Know your {title}
                </p>
                {index < 5
                    ? <QuestionComponent
                        label={questions?.[index]?.label}
                        options={questions?.[index]?.options}
                        value={keyId == 'hairType' ? hairTypeOpts[index] : keyId == 'scalpType' ? scalpTypeOpts[index] : keyId == 'skinType' ? skinTypeOpts[index] : skinToneOpts[index]}
                    />
                    : <>
                        <h2 style={{ marginTop: 36, alignSelf: 'center', textAlign: 'center', color: '#25387C' }}>{type}</h2>
                        <div className='skin-type-btn' onClick={close}>
                            <p>Okay, thanks!</p>
                        </div>
                    </>}
                {index < 5 && <div style={{ height: 150 }} />}
                {index < 5 && <div
                    className="bottomRow"
                    style={{ alignSelf: "center" }}
                >
                    {index > 0 && (
                        <button
                            className="footerbutton"
                            style={{
                                backgroundColor: "#fff",
                                alignSelf: "flex-end",
                                border: 0,
                                paddingLeft: 6,
                                paddingRight: 6,
                                marginRight: 6,
                            }}
                            onClick={() => index > 0 && setIndex(index - 1)}
                        >
                            <img
                                src={chevron}
                                alt=""
                                className="nextIcon"
                                style={{
                                    marginLeft: 0,
                                    marginRight: 10,
                                    marginBottom: -4,
                                    height: 40,
                                    width: 40
                                }}
                            />
                            Prev
                        </button>
                    )}
                    <div>
                        <p style={{ color: "#25387C", fontSize: 16, marginBottom: 6 }}>{`${index * 25}%`}</p>
                        <div className="progress" style={{ height: 8, backgroundColor: '#c1c1c1' }}>
                            <div
                                className="progressActive"
                                style={{ width: `${index * 25}%` }}
                            />
                        </div>
                    </div>
                    <button
                        className="footerbutton"
                        style={{
                            backgroundColor: "#fff",
                            border: 0,
                            alignSelf: "flex-end",
                            padding: 0,
                            marginLeft: 10,
                            marginTop: 14,
                        }}
                        onClick={handleNext}
                    >
                        Next
                        <img
                            src={chevron}
                            alt=""
                            className="nextIcon"
                            style={{ marginBottom: -1, transform: "rotate(180deg)", height: 40, width: 40 }}

                        />
                    </button>
                </div>}
            </div>
        </Modal>
    )
}

export default BeautyProfileIdentifier