export const questions = [

    {
        label: 'What is your skin type?',
        options: ['Oily', 'Combination', 'Dry', 'Sensitive', 'I don’t know'],
        value: 'skinType'
    },
    {
        label: 'What are your skin goals?',
        options: ['Brighten skin ', 'Decrease dryness and dry patches', 'Dehydrated skin', 'Glowing skin', 'Improve texture', 'Increase hydration', 'Maintain healthy skin', 'Minimise pores', 'Reduce acne/pimples/breakouts', 'Reduce acne scars', 'Reduce blackheads and/or whiteheads', 'Reduce dark spots', 'Reduce fine lines/wrinkles', 'Reduce oiliness/greasiness', 'Reduce pigmentation ', 'Reduce tanning'],
        value: 'skinGoals'
    },
    {
        label: 'What is your hair type?',
        options: ['Straight', 'Wavy', 'Curly', 'I don’t know'],
        value: 'hairType'
    },
    {
        label: 'What is your scalp type?',
        options: ['Oily', 'Dry', 'Dandruff prone', 'Irritated/Sensitive', 'I don’t know'],
        value: 'scalpType'
    },
    {
        label: 'What is your hair concern?',
        options: ['Frizzy Hair', 'Dull Hair', 'Dry Hair', 'Damaged Hair', 'Split Ends', 'Hairfall', 'Thinning Hair or Hair Loss', 'Breakage', 'Greasy or Oily Hair', 'Itchy Scalp', 'Dandruff', 'Scalp Acne', 'Premature Graying'],
        value: 'hairConcern'
    },
    {
        label: 'What is your skin tone?',
        options: ['Fair', 'Light', 'Medium', 'Dark', 'I don’t know'],
        value: 'skinTone'
    },
    {
        label: 'How often do you use makeup?',
        options: ['Daily', 'Weekly', 'Occasionally', 'Rarely', 'Never'],
        value: 'makeUp'
    }
]

export const scalpTypeQuestions = [
    {
        label: 'How often do you experience itching on your scalp?',
        options: [
            { label: 'Rarely or never', val: 'A' },
            { label: 'Occasionally', val: 'B' },
            { label: 'Frequently', val: 'C' },
            { label: 'Always', val: 'D' }
        ],
        value: 'itching'
    },
    {
        label: 'How does your scalp feel after washing your hair?',
        options: [
            { label: 'Clean and comfortable', val: 'A' },
            { label: 'Tight and a bit dry', val: 'B' },
            { label: 'Still somewhat oily ', val: 'C' },
            { label: 'Sensitive or irritated', val: 'D' }
        ],
        value: 'feel'
    },
    {
        label: 'Do you notice any flaking on your scalp?',
        options: [
            { label: 'Not really', val: 'A' },
            { label: 'Sometimes', val: 'B' },
            { label: 'Often ', val: 'C' },
            { label: 'Always ', val: 'D' }
        ],
        value: 'flaking'
    },
    {
        label: 'How does your hair look by the end of the day?',
        options: [
            { label: 'Greasy or oily', val: 'A' },
            { label: 'Dry and a bit frizzy', val: 'B' },
            { label: 'Normal ', val: 'C' },
            { label: 'Itchy with visible flakes ', val: 'D' }
        ],
        value: 'look'
    },

    {
        label: 'Do you have any visible redness or irritation on your scalp?',
        options: [
            { label: 'No', val: 'A' },
            { label: 'Rarely', val: 'B' },
            { label: 'Sometimes', val: 'C' },
            { label: 'Yes, frequently', val: 'D' }
        ],
        value: 'redness'
    },
]




export const hairTypeQuestions = [
    {
        label: `What is the natural shape or pattern of your hair?`,
        options: [
            { label: `Straight and doesn't hold curls`, val: 'A' },
            { label: 'Naturally wavy or can easily form waves', val: 'B' },
            { label: 'Naturally curly or forms tight curls', val: 'C' },
        ],
        value: 'pattern'
    },
    {
        label: 'How does your hair behave after washing and air drying?',
        options: [
            { label: 'Lies completely flat and straight', val: 'A' },
            { label: 'Forms loose waves or bends', val: 'B' },
            { label: 'Forms defined curls or spirals', val: 'C' },
        ],
        value: 'afterWash'
    },
    {
        label: 'How does your hair react to humidity?',
        options: [
            { label: `Remains mostly unchanged or gets a bit oily`, val: 'A' },
            { label: 'Tends to frizz or increase in wave', val: 'B' },
            { label: 'Curls become more pronounced or frizzy', val: 'C' },
        ],
        value: 'humidity'
    },
    {
        label: 'When you style your hair, how does it typically respond?',
        options: [
            { label: 'Stays straight or struggles to hold curls', val: 'A' },
            { label: 'Easily takes on waves or mild curls', val: 'B' },
            { label: 'Holds curls well or tends to revert to curly', val: 'C' },
        ],
        value: 'style'
    },
    {
        label: 'How does your hair respond to styling with heat?',
        options: [
            { label: 'Stays straight or becomes slightly wavy', val: 'A' },
            { label: 'Can be straightened or curled with moderate ease', val: 'B' },
            { label: 'Difficult to straighten, quickly returns to curls', val: 'C' },
        ],
        value: 'heat'
    },
]


export const skinToneQuestions = [

    {
        label: 'How does your skin react to prolonged sun exposure without protection?',
        options: [
            { label: 'Burns easily and rarely tans', val: 'A' },
            { label: 'Sometimes burns, tans gradually', val: 'B' },
            { label: 'Rarely burns, tans easily', val: 'C' },
            { label: 'Never burns, tans very easily', val: 'D' }
        ],
        value: 'react'
    },
    {
        label: 'What is the natural color of your veins under natural light?',
        options: [
            { label: 'Blue or purple', val: 'A' },
            { label: 'Blue-green', val: 'B' },
            { label: 'Green or olive', val: 'C' },
            { label: 'Brown or extremely dark', val: 'D' }
        ],
        value: 'color'
    },
    {
        label: 'Which colors of clothing make your skin look more vibrant?',
        options: [
            { label: `Very easy, but styles don't hold for long`, val: 'A' },
            { label: 'Relatively easy, with a moderate hold', val: 'B' },
            { label: 'Requires effort for styling, holds well', val: 'C' },
            { label: 'Challenging to style, but holds styles well', val: 'D' }
        ],
        value: 'clothing'
    },
    {
        label: 'What is the general appearance of your skin under natural light?',
        options: [
            { label: 'Bright colors like red or light blue', val: 'A' },
            { label: 'Neutral or pastel shades', val: 'B' },
            { label: 'Earth tones like green, brown, or orange', val: 'C' },
            { label: 'Bold shades like royal blue or deep purple', val: 'D' }
        ],
        value: 'appearNatural'
    },
    {
        label: 'How does your skin typically appear in photographs with flash?',
        options: [
            { label: 'Often looks very light or washed out', val: 'A' },
            { label: 'Generally looks true to its natural color', val: 'B' },
            { label: 'Usually appears tanned or with a golden hue', val: 'C' },
            { label: 'Appears very dark or absorbs most of the light', val: 'D' }
        ],
        value: 'appear'
    },
]

export const skinTypeQuestions = [
    {
        label: `How does your skin feel after you wash your face?`,
        options: [
            { label: 'Refreshed but quickly turns oily again', val: 'A' },
            { label: 'Tight and dry', val: 'B' },
            { label: 'Clean on my forehead and nose, but dry on my cheeks', val: 'C' },
            { label: 'Slightly irritated or itchy', val: 'D' }
        ],
        value: 'appear'
    },
    {
        label: `If you wash your face and don’t apply any products, how does your skin feel after 30 minutes?`,
        options: [
            { label: `It gets oily quickly`, val: 'A' },
            { label: `Feels tight and looks dry`, val: 'B' },
            { label: `Some areas become oily while others feel dry`, val: 'C' },
            { label: `My skin starts to feel itchy or turns red`, val: 'D' }
        ],
        value: 'appear'
    },
    {
        label: `How does your skin feel by midday?`,
        options: [
            { label: `Shiny and oily`, val: 'A' },
            { label: `Tight and dry`, val: 'B' },
            { label: `Oily in some areas and dry in others`, val: 'C' },
            { label: `Red or irritated`, val: 'D' },
        ],
        value: 'appear'
    },
    {
        label: `How often do you experience breakouts?`,
        options: [
            { label: `Frequently`, val: 'A' },
            { label: `Rarely or never`, val: 'B' },
            { label: `Sometimes, but only in certain areas like my T-zone`, val: 'C' },
            { label: `Sometimes, but usually when I use new products`, val: 'D' },
        ],
        value: 'appear'
    },
    {
        label: `When does your skin look red?`,
        options: [
            { label: `Rarely, unless I'm exercising hard`, val: 'A' },
            { label: `Sometimes, especially in cold or dry weather`, val: 'B' },
            { label: `Rarely, but my T-zone can become a bit flushed`, val: 'C' },
            { label: `Often, especially when I use new products or eat certain foods`, val: 'D' },
        ],
        value: 'appear'
    },
]


