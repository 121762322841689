import React, { useEffect, useState } from 'react'
import './Header.css'
import Info from '../../../assets/images/svgs/info.svg'
import Rupee from '../../../assets/images/svgs/rupeesCircle.svg'
import Logo from '../../../assets/images/svgs/brandIcon.svg'
import Tasks from '../../../assets/images/svgs/tabs/tasksActive.svg'
import Notif from '../../../assets/images/svgs/notification.svg'
import Chevron from '../../../assets/images/svgs/rightChevron.svg'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { BASE_URL } from '../../../utils/config'
import axios from 'axios'
import InfoPopup from '../InfoPopup/InfoPopup'
import { getData } from '../../../utils/axiosInstance'

const Header = ({ title }) => {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [show, setShow] = useState(false);
    const [token, setToken] = useState(false);
    const [stats, setStats] = useState({});
    const [coinsEarned, setCoinsEarned] = useState({});
    const [showCopyLink, setShowCopyLink] = useState(false);
    const [refferalCode, setRefferalCode] = useState('');

    useEffect(() => {
        const jwtToken = localStorage.getItem('jwtToken');
        setToken(jwtToken)
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    const fetchData = async() => {
        const response = await getData(`influencer/stats/${user?.userID}`)
        setStats(response)
    }

    useEffect(() => {
        if(user?.userID) fetchData();
    }, [user])


    // useEffect(() => {
    //     if (user && token) {
    //         console.log('whyyyy', token);
    //         const url = `${BASE_URL}influencer/stats/${user?.userID}`
    //         axios.get(url)
    //             .then(response => {
    //                 // console.log('Response: for stats', response.data)
    //                 setStats(response?.data)
    //             })
    //             .catch(error => {
    //                 console.error('Error:000', token, error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });
    //     }
    // }, [user, token])
    

    useEffect(() => {
        if (user?.userID && token) {
            const url = `${BASE_URL}coin/user/${user?.userID}`
            axios.get(url)
                .then(response => setCoinsEarned(response?.data))
                .catch(error => {
                    console.error('Error:', error)
                    if(error?.response?.status == 403) {
                        navigate('/blocked')
                    }
                });


            const refferalUrl = `${BASE_URL}referral/influencer/user/${user?.userID}`
            axios.get(refferalUrl)
                .then(response => response?.data?.signup_referral_code ? setRefferalCode(response?.data?.signup_referral_code) : setRefferalCode(''))
                .catch(error => {
                    console.error('Error: fetching refferal code', error)
                    if(error?.response?.status == 403) {
                        navigate('/blocked')
                    }
                });
        }
    }, [user, token])

    const completedCount = stats?.items?.challangeLogs?.filter(x => x?.status == 4)?.length
    return (
        <>
            <div className='header'>
                {title ? <Row>
                    <Col md={1} sm={2} xs={2}>
                        <img src={Chevron} style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => window.history.back()} />
                    </Col>
                    <Col md={11} sm={10} xs={10}>
                        <p className='heading'>{title}</p>
                    </Col>
                </Row> : <>
                    <img src={Info} style={{ cursor: 'pointer' }} onClick={() => setShow(true)} />
                    <div className='header-chip'>
                        <img src={Rupee} />
                        <p>{coinsEarned?.balance || 0.00}</p>
                    </div>
                    <img src={Logo} height={44} width={44} />
                    <div className='header-chip'>
                        <img src={Tasks} style={{ height: 18, width: 18 }} />
                        <p>{completedCount || 0}</p>
                    </div>
                    <img src={Notif} style={{ cursor: 'pointer' }} onClick={() => navigate('/notifications')} />
                </>}
            </div>
            <InfoPopup show={show} close={() => setShow(false)} />
        </>
    )
}

export default Header