import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import './Dashboard.css'
import UBCoin from '../../assets/images/svgs/ubcoin.svg'
import ReferPng from '../../assets/images/pngs/refer.png'
import TransferPng from '../../assets/images/svgs/rupeesCircle.svg'
import axios from 'axios';
import UploadImageModal from '../../components/Challenge/UploadImage/UploadImageModal';
import RecommendationChallengeModal from '../../components/Challenge/RecommendationChallengeModal/RecommendationChallengeModal';
import ChallengeCard from './ChallengeCard';
import { BASE_URL } from '../../utils/config';
import CopyLink from '../../components/core/CopyLinkModal.js/CopyLink';
import StatsCard from './StatsCard';
import HeaderTab from './HeaderTab';
import { toast } from 'react-toastify';
import LifeStyleChallenge from '../../components/Challenge/LifeStyleChallenge/LifeStyleChallenge';
import { useNavigate } from 'react-router';
import Header from '../../components/core/Header/Header';
import BottomTabs from '../../components/core/BottomTabs/BottomTabs';
import { getData } from '../../utils/axiosInstance';
import BeautyProfileChallenge from '../../components/Challenge/BeautyProfileChallenge/BeautyProfileChallenge';

const Dashboard = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showRec, setShowRec] = useState(false);
    const [showLifeStyle, setShowLifeStyle] = useState(false);
    const [showBeauty, setShowBeauty] = useState(false);
    const [showCopyLink, setShowCopyLink] = useState(false);
    const [token, setToken] = useState(null);
    const [selcted, setSelected] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [coinsEarned, setCoinsEarned] = useState({});
    const [user, setUser] = useState(null);
    const [challenges, setChallenges] = useState([]);
    const [allChallenges, setAllChallenges] = useState([]);
    const [category, setCategory] = useState('');
    const [completedChallenges, setCompletedChallenges] = useState([]);
    const [pendingChallenges, setPendingChallenges] = useState([]);
    const [stats, setStats] = useState({})

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    const fetchData = async() => {
        const response = await getData(`influencer/stats/${user?.userID}`)
        setStats(response)
        const coinsRes = await getData(`coin/user/${user?.userID}`)
        setCoinsEarned(coinsRes)
    }

    useEffect(() => {
        if(user?.userID) fetchData();
    }, [user])

    const sortActiveChallenges = (activeArray) => {
        const lifstyleArr = activeArray.filter(x => x?.category == 'lifestyle')
        const beautyArr = activeArray.filter(x => x?.category == 'beauty')
        const noMakeUpArray = activeArray.filter(x => x?.name == 'Share a Selfie (No makeup)')
        const generalArray = activeArray.filter(x => x?.category == 'general' && x?.name != 'Share a Selfie (No makeup)')
        const skincareColectionArr = activeArray.filter(x => x?.category == 'skincare' && x?.type == "image_upload")
        const skincareRecArr = activeArray.filter(x => x?.category == 'skincare' && x?.type == "recommendation")
        const haircareRecArr = activeArray.filter(x => x?.category == 'haircare')
        const makeupRecArr = activeArray.filter(x => x?.category == 'makeup')
        console.log(activeArray?.map(x => x?.product));
        const allActive = [...beautyArr, ...lifstyleArr, ...noMakeUpArray, ...generalArray, ...skincareColectionArr, ...skincareRecArr, ...haircareRecArr, ...makeupRecArr]
        // const allActive = [...beautyArr, ...lifstyleArr, ...noMakeUpArray, ...generalArray]
        return allActive

    }

    const fetchChallenges = async() => {
        const res = await getData(`influencer/challenges/all`)
        const sortedAllChallenges = sortActiveChallenges(res?.items)
        setAllChallenges(sortedAllChallenges)
        if(user){
            const response = await getData(`challenge/user/${user?.userID}/all`)
            const ids = response?.items?.filter(y => y?.status != 3 && y?.category != 'lifestyle')?.map(x => x?.cd_id)
            const lifestyleChallengeHasData = response?.items?.filter(y => y?.category == 'lifestyle')
            if(lifestyleChallengeHasData?.[0]?.age && lifestyleChallengeHasData?.[0]?.gender && lifestyleChallengeHasData?.[0]?.city){
                ids.push(lifestyleChallengeHasData?.[0]?.cd_id)
            }
            const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
            const completed = response?.items?.filter(x => x?.status == 4);
            const completedIds = completed?.map(x => x?.cd_id);
            const pending = response?.items?.filter(x => x?.status > 1 && x.status < 4 && !completedIds.includes(x?.cd_id))
            setCompletedChallenges(completed);
            setPendingChallenges(pending);
            setChallenges(active)
        }
    }

    useEffect(() => {
        fetchChallenges();
    }, [user])

    // useEffect(() => {
    //     const url = `${BASE_URL}influencer/challenges/all`
    //     axios.get(url)
    //         .then(res => {
    //             const sortedAllChallenges = sortActiveChallenges(res?.data?.items)
    //             setAllChallenges(sortedAllChallenges)
    //             if (user) {
    //                 const userChallenegesUrl = `${BASE_URL}challenge/user/${user?.userID}/all`
    //                 axios.get(userChallenegesUrl)
    //                     .then(response => {
    //                         const ids = response?.data?.items?.filter(y => y?.status != 3)?.map(x => x?.cd_id)
    //                         const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
    //                         const completed = response?.data?.items?.filter(x => x?.status == 4);
    //                         const completedIds = completed?.map(x => x?.cd_id);
    //                         const pending = response?.data?.items?.filter(x => x?.status > 1 && x.status < 4 && !completedIds.includes(x?.cd_id))
    //                         setCompletedChallenges(completed);
    //                         setPendingChallenges(pending);
    //                         // const sortedActive = sortActiveChallenges(active)
    //                         setChallenges(active)
    //                         // if (active?.length > 0) {
    //                         //     setActiveTab(1)
    //                         // }
    //                     })
    //                     .catch(error => {
    //                         console.error('User challenges Error:', error)
    //                         if(error?.response?.status == 403) {
    //                             navigate('/blocked')
    //                         }
    //                     });
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error)
    //             if(error?.response?.status == 403) {
    //                 navigate('/blocked')
    //             }
    //         });
    // }, [user])

    const handleClickChallenge = (item) => {
        setSelected(item);
        if (item?.type == 'lifestyle') {
            setShowLifeStyle(true)
        }
        if(item?.type == 'beauty'){
            setShowBeauty(true)
        }
        if (item?.type == 'image_upload') {
            setShow(true)
        } else if (item?.type == 'recommendation') {
            setShowRec(true)
        }
    }

    const handleOnComplete = async() => {
        const res = await getData(`influencer/challenges/all`)
        const sortedAllChallenges = sortActiveChallenges(res?.items)
        setAllChallenges(sortedAllChallenges)
        if(user){
            const response = await getData(`challenge/user/${user?.userID}/all`)
            const completed = response?.items?.filter(x => x?.status == 4)
            const pending = response?.items?.filter(x => x?.status > 1 && x.status < 4)
            setCompletedChallenges(completed);
            setPendingChallenges(pending);
            const ids = response?.items?.filter(y => y?.status != 3 && y?.category != 'lifestyle')?.map(x => x?.cd_id)
            const lifestyleChallengeHasData = response?.items?.filter(y => y?.category == 'lifestyle')
            if(lifestyleChallengeHasData?.[0]?.age && lifestyleChallengeHasData?.[0]?.gender && lifestyleChallengeHasData?.[0]?.city){
                ids.push(lifestyleChallengeHasData?.[0]?.cd_id)
            }
            const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
            sortActiveChallenges(active)
            setChallenges(active)
            setShow(false);
            setShowRec(false);
        }
    }

    // const handleOnComplete = () => {
    //     const url = `${BASE_URL}influencer/challenges/all`
    //     axios.get(url)
    //         .then(res => {
    //             // setAllChallenges(res?.data?.items)
    //             const sortedAllChallenges = sortActiveChallenges(res?.data?.items)
    //             setAllChallenges(sortedAllChallenges)
    //             if (user) {
    //                 const userChallenegesUrl = `${BASE_URL}challenge/user/${user?.userID}/all`
    //                 axios.get(userChallenegesUrl)
    //                     .then(response => {
    //                         const completed = response?.data?.items?.filter(x => x?.status == 4)
    //                         const pending = response?.data?.items?.filter(x => x?.status > 1 && x.status < 4)
    //                         setCompletedChallenges(completed);
    //                         setPendingChallenges(pending);
    //                         const ids = response?.data?.items?.filter(y => y?.status != 3)?.map(x => x?.cd_id)
    //                         const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
    //                         sortActiveChallenges(active)
    //                         setChallenges(active)
    //                     })
    //                     .catch(error => {
    //                         console.error('User challenges Error:', error)
    //                         if(error?.response?.status == 403) {
    //                             navigate('/blocked')
    //                         }
    //                     });
    //             }
    //         })
    //         .catch(error => console.error('Error:', error));
    //     setShow(false);
    //     setShowRec(false);
    // }

    const completedCount = stats?.items?.challangeLogs?.filter(x => x?.status == 4)?.length

    const allcategories = allChallenges?.map(x => x?.category)
    const distinctCategories = []
    for (let index = 0; index < allcategories.length; index++) {
        const element = allcategories[index];
        if (element && !distinctCategories.includes(element) && element != 'lifestyle' && element != 'beauty') {
            distinctCategories.push(element)
        }
    }

    const filterQuery = (x) => {
        return category ? category == 'general' ? x.category == category || x.category == 'lifestyle' || x?.category == 'beauty' : x.category == category : x
    }

    return (
        <Container>
            <Row
                className="justify-content-center align-content-start p-2"
                style={{ height: '100vh', marginTop: 80 }}
            >
                <Col sm={12} xs={12} md={12} lg={12} className='wrap-header'>
                    <Header />
                </Col>
                {/* <Col md={4} sm={12} xs={12}> */}
                <div className='banner'>
                    <Row className='p0 m0'>
                        <Col md={6} sm={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingLeft: 16 }}>
                            {user?.userName && <div style={{ display: 'flex' }} className='mb-1'>
                                <div className='avatar'>
                                    <p className='user-name m-0'>{
                                        user?.userName?.[0] == "{"
                                            ? user?.userName?.[1]
                                            : user?.userName?.[0]}
                                    </p>
                                </div>
                                <p className='user-name mb-0'>
                                    {user?.userName?.[0] == "{" ? user?.userName?.slice(1, -1) : user?.userName}
                                </p>
                            </div>}
                            <p className='earned'>Total E-rupee Earned</p>
                            <p className='amt' style={{ fontWeight: 600 }}>₹{coinsEarned?.balance}</p>
                            <div className='upload-btn' onClick={() => navigate('/claim')}>
                                <img src={TransferPng} style={{ height: 20, width: 20 }} />
                                <p>Claim Reward</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <img
                                src={UBCoin}
                                style={{
                                    height: 112,
                                    width: 112,
                                    padding: 0
                                }}
                            />
                        </Col>
                        {/* <Col style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                                <p className='earned'>Total E-rupee Earned</p>
                                <p className='amt'>₹{coinsEarned?.balance}</p>
                                <div className='upload-btn' onClick={() => toast.info('This feature will be available soon  ')}>
                                    <img src={TransferPng} style={{ height: 20, width: 20 }} />
                                    <p>Transfer to UPI</p>
                                </div>
                            </Col> */}
                    </Row>
                </div>
                <div>
                    <Row className='mt-4 mb-4' style={{ borderBottom: '0.5px solid #ffffff66', marginLeft: 0, marginRight: 0 }}>
                        {/* <HeaderTab
                        label={'Statistics'}
                        active={activeTab == 0}
                        onClick={() => setActiveTab(0)}
                    /> */}
                        <HeaderTab
                            label={'Tasks'}
                            active={activeTab == 0}
                            onClick={() => {
                                setActiveTab(0)
                                setCategory('')
                            }}
                        />
                        <HeaderTab
                            label={'Review'}
                            active={activeTab == 1}
                            onClick={() => {
                                setActiveTab(1)
                                setCategory('')
                            }}
                        />
                        <HeaderTab
                            label={'Completed'}
                            active={activeTab == 2}
                            onClick={() => {
                                setActiveTab(2)
                                setCategory('')
                            }}
                        />
                    </Row>
                </div>
                {
                    // activeTab > 0 &&
                    <div className='chip-container'>
                        <div
                            className='chip'
                            style={{ backgroundColor: category == '' ? '#fff' : '#ffffff66' }}
                            onClick={() => setCategory('')}
                        >
                            <p style={{ color: category == '' ? '#42519E' : '#fff' }}>All</p>
                        </div>
                        {distinctCategories?.map(item => <div
                            className='chip'
                            style={{ backgroundColor: category == item ? '#fff' : '#ffffff66' }}
                            onClick={() => setCategory(item)}
                        >
                            <p style={{ color: category == item ? '#42519E' : '#fff' }}>
                                {item}
                            </p>
                        </div>)}
                    </div>}
                {/* {activeTab == 0 ? <>
                    <Row style={{ padding: '0 12px 0 12px' }}>
                        <StatsCard
                            bg='#1BC9CD1A'
                            lable='Tasks completed'
                            value={completedCount || 0}
                        />
                        <StatsCard
                            // bg='#1BC9CD1A'
                            bg='#9F1BCD1A'
                            lable='Number of Signups'
                            value={stats?.items?.signUps || 0}
                        />
                    </Row>
                    <img src={ReferPng} style={{ width: '100%', cursor: 'pointer' }} onClick={() => setShowCopyLink(true)} />
                </> :  */}
                {activeTab == 0 ?
                    <>
                        {challenges
                            ?.filter(x => filterQuery(x))
                            ?.map(item => <ChallengeCard
                                item={item}
                                onClick={handleClickChallenge}
                                status={item?.status}
                            />
                            )}
                    </>
                    : activeTab == 1 ? <>
                        {pendingChallenges
                            ?.filter(x => filterQuery(x))
                            ?.map(item =>
                                <ChallengeCard
                                    item={item}
                                    onClick={handleClickChallenge}
                                    status={item?.status}
                                    pending={true}
                                    pendingChallenges={pendingChallenges?.filter(x => category ? x.category == category : x)}
                                />)}
                    </>
                        : activeTab == 2 ? <>
                            {completedChallenges
                                ?.filter(x => filterQuery(x))
                                ?.map(item => <ChallengeCard
                                    item={item}
                                    onClick={() => { }}
                                    status={item?.status}
                                />)}
                        </>
                            : <></>}
                {/* </Col> */}
                <div style={{ height: 300 }} />
                <BottomTabs />
            </Row>
            <UploadImageModal
                show={show}
                close={() => setShow(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />
            <RecommendationChallengeModal
                show={showRec}
                close={() => setShowRec(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />

            <LifeStyleChallenge
                show={showLifeStyle}
                close={() => setShowLifeStyle(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />

            <BeautyProfileChallenge
                show={showBeauty}
                close={() => setShowBeauty(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />
            <CopyLink
                show={showCopyLink}
                close={() => setShowCopyLink(false)}
                user={user}
                onComplete={handleOnComplete}
            />
        </Container >
    )
}

export default Dashboard