import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import StatsCard from '../Dashboard/StatsCard'
import '../Dashboard/Dashboard.css'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { BASE_URL } from '../../utils/config'
import CopyLink from '../../components/core/CopyLinkModal.js/CopyLink'

import UBCoin from '../../assets/images/svgs/ubcoin.svg'
import CoinBlue from '../../assets/images/svgs/coinblue.svg'
import ReferPng from '../../assets/images/pngs/refer.png'
import TransferPng from '../../assets/images/svgs/rupeesCircle.svg'
import { toast } from 'react-toastify'
import Header from '../../components/core/Header/Header'
import BottomTabs from '../../components/core/BottomTabs/BottomTabs'
import { headers } from '../../utils/setAuthHeader'
import { getData } from '../../utils/axiosInstance'
import PricingPopup from '../../components/core/PricingPopup/PricingPopup'

const Stats = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [stats, setStats] = useState({});
    const [coinsEarned, setCoinsEarned] = useState({});
    const [showCopyLink, setShowCopyLink] = useState(false);
    const [refferalCode, setRefferalCode] = useState('');

    useEffect(() => {
        // const jwtToken = localStorage.getItem('jwtToken');
        // setToken(jwtToken)
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    const fetchData = async() => {
        const response = await getData(`influencer/stats/${user?.userID}`)
        setStats(response)
        const coinsRes = await getData(`coin/user/${user?.userID}`)
        setCoinsEarned(coinsRes)
        const referralRes = await getData(`referral/influencer/user/${user?.userID}`)
        referralRes?.signup_referral_code ? setRefferalCode(referralRes?.signup_referral_code) : setRefferalCode('')
    }

    useEffect(() => {
        if(user?.userID) fetchData();
    }, [user])

    // useEffect(() => {
    //     if (user && token) {
    //         const url = `${BASE_URL}influencer/stats/${user?.userID}`
    //         axios.get(url,
    //             {headers: {
    //             "Content-type": "Application/json",
    //             "Authorization":`Bearer ${token}`
    //             }}
    //         )
    //             .then(response => {
    //                 // console.log('Response: for stats', response.data)
    //                 setStats(response?.data)
    //             })
    //             .catch(error => {
    //                 console.error('Error:222', token, error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });
    //     }
    // }, [user, token])

    // useEffect(() => {
    //     if (user?.userID && token) {
    //         const url = `${BASE_URL}coin/user/${user?.userID}`
    //         axios.get(url)
    //             .then(response => setCoinsEarned(response?.data))
    //             .catch(error => {
    //                 console.error('Error:', error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });


    //         const refferalUrl = `${BASE_URL}referral/influencer/user/${user?.userID}`
    //         axios.get(refferalUrl)
    //             .then(response => response?.data?.signup_referral_code ? setRefferalCode(response?.data?.signup_referral_code) : setRefferalCode(''))
    //             .catch(error => {
    //                 console.error('Error: fetching refferal code', error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });
    //     }
    // }, [user, token])

    const completedCount = stats?.items?.challangeLogs?.filter(x => x?.status == 4)?.length
    return (
        <Container>
            <Row
                className="justify-content-center p-2"
                style={{ height: '100vh', marginTop: 80, marginBottom: 100 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header />
                </Col>
                <div className='banner'>
                    <Row className='p0 m0'>
                        <Col md={6} sm={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingLeft: 16 }}>
                            {user?.userName && <div style={{ display: 'flex' }} className='mb-1'>
                                <div className='avatar'>
                                    <p className='user-name m-0'>{
                                        user?.userName?.[0] == "{"
                                            ? user?.userName?.[1]
                                            : user?.userName?.[0]}
                                    </p>
                                </div>
                                <p className='user-name mb-0'>
                                    {user?.userName?.[0] == "{" ? user?.userName?.slice(1, -1) : user?.userName}
                                </p>
                            </div>}
                            <p className='earned'>Total E-rupee Earned</p>
                            <p className='amt' style={{ fontWeight: 600 }}>₹{coinsEarned?.balance}</p>
                            <div className='upload-btn' onClick={() => navigate('/claim')}>
                                <img src={TransferPng} style={{ height: 20, width: 20 }} />
                                <p>Claim Reward</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <img
                                src={UBCoin}
                                style={{
                                    height: 112,
                                    width: 112,
                                    padding: 0
                                }}
                            />
                        </Col>
                        {/* <Col style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                                <p className='earned'>Total E-rupee Earned</p>
                                <p className='amt'>₹{coinsEarned?.balance}</p>
                                <div className='upload-btn' onClick={() => toast.info('This feature will be available soon  ')}>
                                    <img src={TransferPng} style={{ height: 20, width: 20 }} />
                                    <p>Transfer to UPI</p>
                                </div>
                            </Col> */}
                    </Row>
                </div>
                <Row style={{ marginTop: 15, }}>
                    <StatsCard
                        bg='#1BC9CD1A'
                        lable='Tasks completed'
                        value={completedCount || 0}
                    />
                    {/* <StatsCard
                                bg='#9F1BCD1A'
                                lable='Number of Clicks'
                                value={stats?.items?.linkClicksCount || 0}
                            /> */}
                    <StatsCard
                        // bg='#1BC9CD1A'
                        bg='#9F1BCD1A'
                        lable='Number of Signups'
                        value={stats?.items?.signUps || 0}
                    />
                </Row>
                <img src={ReferPng} style={{ width: '100%', cursor: 'pointer', padding: 0, marginTop: 15, objectFit: 'contain' }} onClick={() => setShowCopyLink(true)} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className='start-earning-btn' onClick={() => setShowCopyLink(true)}>
                            <img src={CoinBlue}/>
                            <p>Start Earning</p>
                </div>
                </div>
                {/* <div style={{ position: 'absolute', bottom: 0, marginLeft: -24 }}>
                    <BottomTabs />
                </div> */}
                <div style={{ height: 400 }} />
                <BottomTabs />
            </Row>
            <CopyLink
                show={showCopyLink}
                close={() => setShowCopyLink(false)}
                user={user}
                refferalCode={refferalCode}
            />
            {/* <PricingPopup show={true} close={() => {}}/> */}
        </Container>
    )
}

export default Stats