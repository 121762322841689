import React, { useEffect, useState } from 'react'
import './WithdrawRewards.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Header from '../../components/core/Header/Header'
import CustomInput from '../../components/core/CustomInput/CustomInput'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../utils/config'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Chevron from '../../assets/images/svgs/rightChevron.svg'
import Rupees from '../../assets/images/svgs/rupeesCircle.svg'
import ProcessingPayment from '../../assets/images/svgs/paymentIn24.svg'
import { getData, postData } from '../../utils/axiosInstance'

const WithdrawRewards = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [upiRecords, setUpiRecords] = useState([]);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [coinsEarned, setCoinsEarned] = useState({});
    const [selectedAddressFromExisting, setSelectedAddressFromExisting] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        name: "",
        email: "",
        contact: "",
        userId: "",
        ifsc: "",
        account_number: "",
        // amount: ""
        amount: coinsEarned?.balance || 0,
        upi: "",
        saveUpi: false
    });

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else {
            setUser(userData)
            setPaymentInfo({ ...paymentInfo, contact: userData?.mobileNumber, name: userData?.userName })
        }
    }, []);

    const handlePaymentResponse = (response) => {
        console.log('Response:...........', response.data)
        toast.success('Your payment will be credited in 24 hours')
        getCoins();
        window.history.back();
        setPaymentLoader(false)
    }

    const makePayment = async () => {
        // setPaymentLoader(true)
        const data = { ...paymentInfo }
        data.contact = '91' + paymentInfo?.contact
        data.userId = user?.userID;
        data.saveUpi = selectedAddressFromExisting ? false : paymentInfo?.saveUpi
        const url = `${BASE_URL}payments/make-payment`
        await postData(`payments/make-payment`, data)
        .then((response) => handlePaymentResponse(response))
        .catch(error => {
            console.error('Error:', error)
            toast.warn('Something went wrong, please try again later')
            setPaymentLoader(false)
        if(error?.response?.status == 403) {
            navigate('/blocked')
        }})
        // axios.post(url, data)
        //     .then(response => handlePaymentResponse(response))
        //     .catch(error => {
        //         console.error('Error:', error)
        //         toast.warn('Something went wrong, please try again later')
        //         setPaymentLoader(false)
        //         if(error?.response?.status == 403) {
        //             navigate('/blocked')
        //         }
        //     });
    }

    const getUpiRecords = async () => {
        const response = await getData(`payments/upi-records/${user?.userID}`)
        setUpiRecords(response?.upiRecords)
    }

    const getCoins = async () => {
        const response = await getData(`coin/user/${user?.userID}`)
        setCoinsEarned(response)
        setPaymentInfo({ ...paymentInfo, amount: response?.balance })
    }

    useEffect(() => {
        if (user?.userID) {
            getUpiRecords()
            getCoins();
        }
    }, [user])

    const onClickPayment = () => {
        if (paymentLoader) {
            console.log('in process...');
        } else {
            setPaymentLoader(true)
            makePayment()
        }
    }

    return (
        <Container>
            <Row
                className="justify-content-center  p-2"
                style={{ height: '100vh', marginTop: 80 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header title={'Withdraw'} />
                </Col>
                <div className='p-0'>
                    <Card style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <p className='your-rewards-text'>
                            Your reward
                        </p>
                        <p className='rewards-amt'>₹{coinsEarned?.balance || 0.00}</p>
                    </Card>

                    {upiRecords?.map(record => <div className='upi-card'
                        onClick={() => {
                            setPaymentInfo({ ...paymentInfo, upi: record?.upi_id, email: record?.email, saveUpi: false })
                            setSelectedAddressFromExisting(true)
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <div>
                                <p className='email'>{record?.email}</p>
                                <p className='upi-id'>{record?.upi_id}</p>
                            </div>
                        </div>
                        <img src={Chevron} height={24} width={24} />
                    </div>)}

                    <p className='label' style={{ marginTop: 24, paddingTop: 0 }}>UPI id</p>
                    <CustomInput
                        val={paymentInfo?.upi}
                        onChange={e => setPaymentInfo({ ...paymentInfo, upi: e.target.value })}
                        placeholder={'Ex: brucewayne@oksbi'}
                        style={{ backgroundColor: '#25387C', borderRadius: 12, marginTop: 4, border: '1px solid #fff', color: '#fff' }}
                    />

                    <p className='label'>Email</p>
                    <CustomInput
                        val={paymentInfo?.email}
                        onChange={e => setPaymentInfo({ ...paymentInfo, email: e.target.value })}
                        placeholder={'ex: bruce.wayne@gmail.com'}
                        style={{ backgroundColor: '#25387C', borderRadius: 12, marginTop: 4, border: '1px solid #fff', color: '#fff' }}
                    />

                    <div
                        style={{ display: 'flex', flexDirection: 'row', marginTop: 12, padding: 0, cursor: 'pointer' }}
                        onClick={() => setPaymentInfo({ ...paymentInfo, saveUpi: !paymentInfo?.saveUpi })}
                    >
                        <div className='radio'>
                            {paymentInfo?.saveUpi && <div />}
                        </div>
                        <p className='save-info'>Save this info for future</p>
                    </div>

                    {paymentLoader
                        ? <></>
                        : <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                            <div
                                className='get-started-btn'
                                style={{ opacity: paymentLoader ? 0.6 : 1 }}
                                onClick={onClickPayment}
                            >
                                <img src={Rupees} />
                                <p>Withdraw</p>
                            </div>
                        </div>}
                </div>
                {/* <p className='label'>Amount</p>
                <CustomInput
                    val={paymentInfo?.amount}
                    onChange={e => toast.info('Amount cannot be changed')}
                    placeholder={'Mobile Number'}
                    style={{ backgroundColor: '#25387C', borderRadius: 12, marginTop: 4, border: '1px solid #fff', color: '#fff' }}
                /> */}

                {/* <Button onClick={makePayment} style={{ marginTop: 40 }}>
                    Withdraw
                </Button> */}

                {/* <Col md={4} lg={4} sm={12} xs={12} className='m-0 mb-4 p-0 bottom-tabs-wrap' style={{ position: 'absolute', bottom: 0 }}>
                    <div className='bottom-tabs' style={{ backgroundColor: '#fff', justifyContent: 'center', flexDirection: 'column', borderRadius: 24, marginLeft: 10, marginRight: 10 }}>
                        <img src={ProcessingPayment} />
                        <p className='credit-in-24'>
                            Your reward would be credit 24 hours
                        </p>
                    </div>
                </Col> */}
            </Row>
        </Container>
    )
}

export default WithdrawRewards