// import logo from './logo.svg';
import { useNavigate } from "react-router";
import "./App.css";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";

function App() {
  const isDesktop = window.innerWidth > 768;
  return (
    <div className="App">
      {isDesktop ? (
        <DeviceFrameset device="iPhone 8 Plus" color="gold">
          {/* Render mobile mockup for desktop */}
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </DeviceFrameset>
      ) : (
        /* Render Routes and ToastContainer for mobile or desktop without the device frame */
        <>
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </div>
  );
}

export default App;
