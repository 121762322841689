import React from "react";
import { Col } from "react-bootstrap";
import homeSvg from "../../../assets/images/svgs/tabs/home.svg";
import homeActiveSvg from "../../../assets/images/svgs/tabs/homeActive.svg";
import tasksSvg from "../../../assets/images/svgs/tabs/tasks.svg";
import tasksActiveSvg from "../../../assets/images/svgs/tabs/tasksActive.svg";
import profileSvg from "../../../assets/images/svgs/tabs/profile.svg";
import profileActiveSvg from "../../../assets/images/svgs/tabs/profileActive.svg";
import { useNavigate } from "react-router";

const TabComponent = ({ label, icon, active, onClick }) => (
  <div
    className={active ? "bottom-tab-active" : "bottom-tab"}
    onClick={onClick}
  >
    <img src={icon} height={24} width={24} style={{ marginTop: 4 }} />
    <p className={active ? "tab-name-active" : "tab-name"}>{label}</p>
  </div>
);

const BottomTabs = () => {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const activeBottomTab =
    path == "/dashboard"
      ? "tasks"
      : path == "/profile"
      ? "profile"
      : path == "/stats"
      ? "home"
      : "home";

  return (
    <Col md={12} lg={12} sm={12} xs={12} className="m-0 p-0 bottom-tabs-wrap">
      <div className="bottom-tabs">
        <TabComponent
          label={"Home"}
          active={activeBottomTab == "home"}
          icon={activeBottomTab == "home" ? homeActiveSvg : homeSvg}
          onClick={() => navigate("/stats")}
        />
        <TabComponent
          label={"Tasks"}
          active={activeBottomTab == "tasks"}
          icon={activeBottomTab == "tasks" ? tasksActiveSvg : tasksSvg}
          onClick={() => navigate("/dashboard")}
        />
        <TabComponent
          label={"Profile"}
          active={activeBottomTab == "profile"}
          icon={activeBottomTab == "profile" ? profileActiveSvg : profileSvg}
          onClick={() => navigate("/profile")}
        />
      </div>
    </Col>
  );
};

export default BottomTabs;
