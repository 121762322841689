import React from 'react'
import './CopyLink.css'
import { Col, Modal, Row } from 'react-bootstrap'
import CloseSvg from '../../../assets/images/svgs/closeBlack.svg'
import Whatsapp from '../../../assets/images/svgs/whatsapp.svg'
import Instagram from '../../../assets/images/svgs/instagram.svg'
import Options from '../../../assets/images/svgs/copyOptions.svg'
import Copy from '../../../assets/images/svgs/copy.svg'
import { toast } from 'react-toastify';

const CopyLink = ({ show, close, user, refferalCode }) => {

    const copyToClipboard = () => {

        const textArea = document.createElement('textarea');
        textArea.value = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.info('Link copied to clipboard');

        // const textToCopy = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`;
        // navigator.clipboard.writeText(textToCopy)
        //     .then(() => {
        //         toast.info('Link copied to clipboard');
        //         close()
        //     })
        //     .catch(err => {
        //         console.log('Could not copy text: ', err);
        //     });
    };

    const shareToWhatsApp = () => {
        const url = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`;
        const message = 'Check this out:' + url;
        // const whatsappURL = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`
        // window.location.href = whatsappURL;
        // window.open(whatsappURL, "_blank")
        window.location.href = whatsappURL
    };

    const openInstagramApp = () => {
        if (refferalCode) {
            // const url = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`
            const instagramURL = 'https://www.instagram.com/direct/inbox/';
            const textArea = document.createElement('textarea');
            textArea.value = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            toast.info('Link copied to clipboard');
            window.location.href = instagramURL
            // navigator.clipboard.writeText(url).then(() => {
            //     toast.info('Link copied to clipboard')
            //     // window.location.href = instagramURL;
            //     window.open(instagramURL, "_blank")
            // }).catch(err => {
            //     console.error('Could not copy text: ', err);
            //     window.open(instagramURL, "_blank")
            // });
        }
    };

    return (
        <Modal
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter br4"
            centered
        >
            <div className='p-4'>
                <p style={{ fontSize: 20, textAlign: 'center', fontWeight: 600 }}>Share</p>
                {refferalCode?.length > 1 && <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='copy-wrap-container'>
                            <p className='refferal-title mb-0'>Referral Code</p>
                            <p className='refferal-code'>{`https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`}</p>
                        </div>
                        <div className='copy-btn' onClick={copyToClipboard}>
                            <img src={Copy} style={{ marginTop: 8, marginBottom: 8 }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 28 }}>
                        <img
                            src={Whatsapp}
                            style={{ height: 44, width: 44, marginRight: 16, cursor: 'pointer' }}
                            onClick={shareToWhatsApp}
                        />
                        <img
                            src={Instagram}
                            style={{ height: 44, width: 44, marginLeft: 16, marginRight: 16, cursor: 'pointer' }}
                            onClick={openInstagramApp}
                        />
                        <img
                            src={Options}
                            style={{ height: 44, width: 44, marginLeft: 16, marginRight: 16, cursor: 'pointer' }}
                            onClick={copyToClipboard}
                        />
                    </div>
                </>}
            </div>
        </Modal>
    )
}

export default CopyLink