

export const convertToBlob = (base64String) => {
    const mimeType = 'image/png'
    // const byteCharacters = atob(base64String);
    // const byteArray = new Uint8Array(byteCharacters.length);

    // for (let i = 0; i < byteCharacters.length; i++) {
    //     byteArray[i] = byteCharacters.charCodeAt(i);
    // }

    // // return new Blob([byteArray], { type: mimeType });
    // console.log('HEINNNNNNNNNNNNNNNNNNNNNNNNNN', new Blob([byteArray], { type: mimeType }));
    try {
        // const byteCharacters = atob(base64String);
        // const byteArray = new Uint8Array(byteCharacters.length);

        // for (let i = 0; i < byteCharacters.length; i++) {
        //     byteArray[i] = byteCharacters.charCodeAt(i);
        // }
        return fetch(base64String)
            .then(response => response.blob())
            .then(blob => {
                return new Blob([blob], { type: mimeType });
            });
    } catch (error) {
        console.error('Error decoding Base64:-------------------------', error);
        return null; // Return null or handle the error as needed
    }
};
