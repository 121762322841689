
export const mapCategory = (text) => {
    switch(text){
        case 'Share a body lotion/moisturiser recommendation':
        case 'Share a treatment recommendation':
        case 'Share a cleanser recommendation':
        case 'Share a sunscreen recommendation':
        case 'Share a eyecare product recommendation (eye cream, eye gel, under eye patch etc)':
        case 'Share a moisturiser recommendation':
            return 'skin'

        case 'Share a body wash/soap recommendation':
            return 'bath'
        
        case 'Share a mascara recommendation':
        case 'Share a lipstick/lipgloss recommendation':
        case 'Share a foundation recommendation':
        case 'Share a concealer recommendation':
        case 'Share a eyeliner/kajal recommendation':
        case 'Share a contour/highlighter recommendation':
        case 'Share a blush/bronzer recommendation':
            return 'makeup'

        
        case 'Share a lip care product recommendation (lip balm, lip scrub etc)':
        case 'Share a face/setting powder recommendation':
            return 'luxury_beauty'
        
        case 'Share a hair oil recommendation':
        case 'Share a hair serum recommendation':
        case 'Share a conditioner recommendation':
        case 'Share a hair mask recommendation':
            return 'hair'
        default: 
            return 'skin'
    }
}