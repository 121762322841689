
export const headers = {
    "Content-type": "Application/json",
    "Authorization": ''

}
const setAuthorizationHeader = (token) => {
    headers.Authorization = `Bearer ${token}`
}

export default setAuthorizationHeader;