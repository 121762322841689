import React, { useEffect, useState } from 'react'
import './ClaimRewards.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Header from '../../components/core/Header/Header'
import Rupees from '../../assets/images/svgs/rupeesCircle.svg'
import Copy from '../../assets/images/svgs/copy.svg'
import Tasks from '../../assets/images/svgs/tabs/tasksActive.svg'
import { BASE_URL } from '../../utils/config'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { getData } from '../../utils/axiosInstance'

const ClaimRewards = () => {
    const navigate = useNavigate();
    const [refferalCode, setRefferalCode] = useState('');
    const [user, setUser] = useState(null);
    const [completedChallenges, setCompletedChallenges] = useState([])
    const [coinsEarned, setCoinsEarned] = useState({});

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    const fetchData = async() => {
        const coinsRes = await getData(`coin/user/${user?.userID}`)
        setCoinsEarned(coinsRes)
        const referralRes = await getData(`referral/influencer/user/${user?.userID}`)
        referralRes?.signup_referral_code ? setRefferalCode(referralRes?.signup_referral_code) : setRefferalCode('')
    }

    useEffect(() => {
        if(user?.userID) fetchData();
    }, [user])


    const fetchChallenges = async () => {
        const response = await getData(`challenge/user/${user?.userID}/all`)
        const completed = response?.items?.filter(x => x?.status == 4)
        setCompletedChallenges(completed);
    }

    useEffect(() => {
        if(user?.userID) fetchChallenges();
    }, [user])

    // useEffect(() => {
    //     if (user?.userID) {
    //         const url = `${BASE_URL}coin/user/${user?.userID}`
    //         axios.get(url)
    //             .then(response => setCoinsEarned(response?.data))
    //             .catch(error => {
    //                 console.error('Error:', error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });

    //         const refferalUrl = `${BASE_URL}referral/influencer/user/${user?.userID}`
    //         axios.get(refferalUrl)
    //             .then(response => response?.data?.signup_referral_code ? setRefferalCode(response?.data?.signup_referral_code) : setRefferalCode(''))
    //             .catch(error => {
    //                 console.error('Error: fetching refferal code', error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });
    //     }
    // }, [user])

    // useEffect(() => {
    //     if (user) {
    //         const userChallenegesUrl = `${BASE_URL}challenge/user/${user?.userID}/all`
    //         axios.get(userChallenegesUrl)
    //             .then(response => {
    //                 const completed = response?.data?.items?.filter(x => x?.status == 4)
    //                 setCompletedChallenges(completed);
    //             })
    //             .catch(error => {
    //                 console.error('User challenges Error:', error)
    //                 if(error?.response?.status == 403) {
    //                     navigate('/blocked')
    //                 }
    //             });
    //     }
    // }, [user])

    const copyToClipboard = () => {
        const textToCopy = `https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&utm_source=influencer`;
        navigator.clipboard.writeText(textToCopy)
            .then(() => toast.info('Link copied to clipboard'))
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };

    return (
        <Container>
            <Row
                className="justify-content-center p-2"
                style={{ height: '100%', marginTop: 80 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header title={'Claim Rewards'} />
                </Col>
                <Card style={{ margin: 8, borderRadius: 16, border: 0 }} className="shadow">
                    <Card.Body style={{
                        padding: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: '#fff',
                        borderRadius: 16
                    }}
                    >
                        <p className='title'>Your Reward</p>
                        <p className='subtitle'>You can Withdraw only when it is ≥ ₹100</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 16 }}>
                            <p className='coins-earned'>₹{coinsEarned?.balance ? coinsEarned?.balance : '0'}</p>
                            <div className='get-started-btn' onClick={() => coinsEarned?.balance >= 100 ? navigate('/withdraw') : toast.info('You can withdraw only when Rewards is greater than 100')}>
                                {/* <div className='get-started-btn' onClick={() => user?.mobileNumber == '9380596673' ? navigate('/withdraw') : toast.info('This feature will be available soon')}> */}
                                <img src={Rupees} />
                                <p>Withdraw</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {refferalCode?.length > 0 && <Row style={{ marginTop: 20 }} className='p-0'>
                    <Col md={9} sm={9} xs={9} className='p-0 m-0'>
                        <div className='referral' style={{ width: '100%' }} >
                            <p className='title'>Referral Code</p>
                            <p className='ref-code'>{`https://app.unsweetenedbeauty.com/quiz?utm_content=${refferalCode}&&utm_source=influencer`}</p>
                        </div>
                    </Col>
                    <Col md={3} sm={3} xs={3} >
                        <div className='copy-wrap' onClick={copyToClipboard}>
                            <img src={Copy} style={{ marginTop: 18, marginBottom: 18 }} />
                        </div>
                    </Col>
                </Row>}

                {completedChallenges?.length > 0 && <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, paddingBottom: 20, borderBottom: '2px solid #fff', marginBottom: 20 }}>
                        <p className='completed-heading mb-0' >Completed task rewards</p>
                        <img src={Tasks} height={24} width={24} />
                    </div>

                    {completedChallenges?.map(item => <div className='option-card'>
                        <p className='challenge-name m-0'>{item?.name}</p>
                        <p className='challenge-amt m-0'> +₹{item?.coins}</p>
                    </div>)}
                </>}
            </Row>
        </Container >
    )
}

export default ClaimRewards