export const questions = [
    {
        label: 'How long do you sleep for?',
        options: ['4 or less', '5 to 6', '7 to 8', '9 to 10', 'More than 10'],
        value: 'sleepSchedule'
    },
    {
        label: 'How long are you generally exposed to sunlight?',
        options: ['Less than 1', '1 to 3', '4 to 6', '7 to 8', 'More than 8'],
        value: 'sunlightExposure'
    },
    {
        label: 'Do you have any gut health issues?',
        options: ['Constipation', 'Diarrhoea', 'Bloating', 'None of the above'],
        value: 'healthIssues'
    },
    {
        label: 'How stressed are you?',
        options: ['Not stressed', 'Somewhat stressed', 'Very stressed'],
        value: 'stressLevel'
    },
    {
        label: 'How much water do you consume in a day?',
        options: ['Less than or equal to 1 litre', '2 - 3 litres', 'More than 3 litres'],
        value: 'waterConsumption'
    },
    {
        label: 'How do you commute to work?',
        options: ['Two - wheeler', 'Train / metro', 'Car', 'Public bus', 'Walk', `I don't commute`],
        value: 'commute'
    },
    {
        label: 'What do you consume daily?',
        options: ['Vegetables', 'Dairy', 'Sugar or refined carbs', 'Alcohol', 'Processed meats', 'Vitamins & supplements', 'Other'],
        value: 'diet'
    },
    {
        label: 'How often do you exercise?',
        options: ['1 - 3 times per week', '4 - 5 times per week', '6 - 7 times per week', 'Very rarely'],
        value: 'exercise'
    },
    // new ones---
    // {
    //     label: 'What is your skin tone?',
    //     options: ['Fair','Light','Medium','Dark', 'I don’t know'],
    //     value: 'skinTone'
    // },
    // {
    //     label: 'How often do you use makeup?',
    //     options: ['Daily', 'Weekly', 'Occasionally', 'Rarely', 'Never'],
    //     value: 'makeUp'
    // },
    // {
    //     label: 'What is your hair type?',
    //     options: ['Straight','Wavy','Curly'],
    //     value: 'hairType'
    // },
    // {
    //     label: 'What is your scalp type?',
    //     options: ['Oily','Dry','Dandruff prone','Irritated/sensitive'],
    //     value: 'scalpType'
    // },
    // {
    //     label: 'What is your hair concern?',
    //     options: [ 'Frizzy Hair', 'Dull Hair', 'Dry Hair', 'Damaged Hair', 'Split Ends', 'Thinning Hair or Hair Loss', 'Breakage', 'Greasy or Oily Hair', 'Itchy Scalp', 'Dandruff', 'Scalp Acne', 'Premature Graying'],
    //     value: 'hairConcern'
    // },
    {
        label: 'What is your gender?',
        options: ['Man', 'Woman', 'Non-binary', 'Self-identify'],
        value: 'gender'
    },
    {
        label: 'What is your age?',
        options: ['Less than 20 years', '20-25 years', '26-35 years', '36-50 years', 'More than 50 years'],
        value: 'age'
    },
    // new ones---
    {
        label: 'Are you taking any medication?',
        options: ['Yes', 'No'],
        value: 'medication'
    },
    {
        label: 'Do you smoke?',
        options: ['Yes', 'No'],
        value: 'smoke'
    },
    // {
    //     label: 'What is your location?',
    //     options: ['Yes', 'No'],
    //     value: 'smoke'
    // }
]