import React from 'react'
import { Card, Col } from 'react-bootstrap'
import ClickSvg from '../../assets/images/svgs/clicks.svg'
import TasksCompletedSvg from '../../assets/images/svgs/rewardStat.svg'
import SignUpSvg from '../../assets/images/svgs/signup.svg'

const StatsCard = ({ lable, bg, value }) => {
    const icon = lable == 'Number of Clicks' ? ClickSvg : lable == 'Number of Signups' ? SignUpSvg : TasksCompletedSvg

    return (
        <Col md={6} sm={6} xs={6} style={{ padding: 6, display: 'flex', flex: 1 }}>
            <Card className='stats-card'>
                <div style={{ backgroundColor: bg, display: 'flex', flex: 1 }}>
                    <Card.Body className='stats-card-body'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <img
                                src={icon}
                                style={{ height: 38, width: 38 }}
                            />
                            <p className='stats-val'>{value}</p>
                        </div>
                        <p className='stats-label'>
                            {lable}
                        </p>
                    </Card.Body>
                </div>
            </Card>
        </Col>
    )
}

export default StatsCard