import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './../LifeStyleChallenge/LifeStyleChallenge.css'
import CloseSvg from '../../../assets/images/svgs/close.svg'
import axios from 'axios'
import { BASE_URL } from '../../../utils/config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import CustomInput from '../../core/CustomInput/CustomInput'
import { questions } from './questions'
import BeautyProfileIdentifier from './BeautyProfileIdentifier'

const OptionCard = ({ label, active, onSelect }) =>
    <div
        style={{ display: 'flex', padding: '0px 16px', cursor: 'pointer' }}
        onClick={() => onSelect(label)}
    >
        <div className='option-radio'>
            {active && <div className='option-radio-selected' />}
        </div>
        <p className='option-label'>{label}</p>
    </div>

const BeautyProfileChallenge = ({ show, close, selected, onComplete }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showIdentifier, setShowIdentifier] = useState(false);
    const [identiferKey, setIdentifierKey] = useState(null);
    const [token, setToken] = useState('');
    const [recommendationDetails, setRecommendationDetails] = useState({
        sleepSchedule: '',
        sunlightExposure: '',
        healthIssues: '',
        stressLevel: '',
        waterConsumption: '',
        commute: '',
        diet: [],
        exercise: '',
        medication: '',
        smoke: '',
        category: 'beauty',
        skinTone: '',
        makeUp: '',
        hairType: '',
        scalpType: '',
        hairConcern: [],
        city: '',
        gender: '',
        age: '',
        skinGoals: [],
        skinType: '',
    })

    // console.log('This one...', recommendationDetails?.skinType =='Oily');

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const jwtoken = localStorage.getItem('jwtToken')
        setToken(jwtoken)
        setUser(userData)
    }, []);
    // console.log('jdmnvkjdvk', recommendationDetails);
    const QuestionComponent = ({ label, options, value }) => <>
        <p className='input-title'>{label}</p>
        {value == 'skinType' && console.log(recommendationDetails?.[value], '-------', options)}
        {
            options?.map(item => <OptionCard
                label={item}
                active={value == 'hairConcern' || value == 'skinGoals'? recommendationDetails?.[value]?.includes(item) : recommendationDetails?.[value] == item}
                onSelect={() => {
                    // if (value == 'hairConcern') {
                    //     let selectedConcern = recommendationDetails?.hairConcern
                    //     if (selectedConcern?.includes(item)) {
                    //         const filteredArr = selectedConcern?.filter(x => x != item)
                    //         setRecommendationDetails({ ...recommendationDetails, hairConcern: filteredArr })
                    //     }
                    //     else {
                    //         if (selectedConcern?.length == 3) {
                    //             toast.info('Maximum 3 allowed, unselect one to select new')
                    //         } else {
                    //             selectedConcern?.push(item)
                    //             setRecommendationDetails({ ...recommendationDetails, hairConcern: selectedConcern })
                    //         }
                    //     }
                    // } else if (value == 'skinGoals') {
                    //     let selectedConcern = recommendationDetails?.skinGoals
                    //     if (selectedConcern?.includes(item)) {
                    //         const filteredArr = selectedConcern?.filter(x => x != item)
                    //         setRecommendationDetails({ ...recommendationDetails, skinGoals: filteredArr })
                    //     }
                    //     else {
                    //         if (selectedConcern?.length == 3) {
                    //             toast.info('Maximum 3 allowed, unselect one to select new')
                    //         } else {
                    //             selectedConcern?.push(item)
                    //             setRecommendationDetails({ ...recommendationDetails, skinGoals: selectedConcern })
                    //         }
                    //     }
                    // }
                    if (value == 'skinGoals' || value == 'hairConcern') {
                        const updateArr = recommendationDetails[value]
                        if (updateArr?.length == 3 && !recommendationDetails?.[value]?.includes(item)) {
                            toast.info('Maximum Three allowed')
                        } else if (recommendationDetails?.[value]?.includes(item)) {
                            const filtered = updateArr?.filter(x => x != item)
                            setRecommendationDetails({ ...recommendationDetails, [value]: [...filtered] })
                        } else {
                            updateArr.push(item)
                            setRecommendationDetails({ ...recommendationDetails, [value]: [...updateArr] })
                            // updateArr?.length == 3 && setIndex(index + 1)
                        }
                    }
                    else {
                        if (item == 'I don’t know') {
                            setIdentifierKey(value)
                            setShowIdentifier(true)
                        } else setRecommendationDetails({ ...recommendationDetails, [value]: item })
                    }
                }}
            />)
        }
    </>

    const onPost = async () => {

        if (!recommendationDetails?.skinTone) {
            toast.warn('Please select field What is your skin tone?')
            return
        }
        if (!recommendationDetails?.makeUp) {
            toast.warn('Please select field How often do you use makeup?')
            return
        }
        if (!recommendationDetails?.hairType) {
            toast.warn('Please select field What is your hair type?')
            return
        }
        if (!recommendationDetails?.scalpType) {
            toast.warn('Please select field What is your scalp type?')
            return
        }
        if (!recommendationDetails?.skinType) {
            toast.warn('Please select field What is your skin type?')
            return
        }
        if (recommendationDetails?.skinGoals?.length < 1) {
            toast.warn('Please select Skin Goals?')
            return
        }
        if (recommendationDetails?.hairConcern?.length < 1) {
            toast.warn('Please select Hair concerns?')
            return
        }
        else {
            const data = { ...recommendationDetails }
            data.diet = recommendationDetails.diet.join(',')
            data.hairConcern = recommendationDetails.hairConcern.join(',')
            data.skinGoals = recommendationDetails.skinGoals.join(',')
            setLoading(true);
            return axios.post(`${BASE_URL}influencer/${selected?.cd_id}/user/${user?.userID}/lifestyle`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(response => {
                    console.log('upload image response.. ', response?.data);
                    setLoading(false);
                    close()
                    onComplete()

                })
                .catch(error => {
                    console.log(error, '...........', error?.response);
                    setLoading(false)
                    onComplete()
                    if (error?.response?.status == 403) {
                        navigate('/blocked')
                    }
                });
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={close}
                style={{ marginTop: 66 }}
            >
                <div className='p-4 container' style={{ backgroundColor: '#25387C', }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ height: 5, width: 100, backgroundColor: '#fff', alignSelf: 'center', display: 'flex', borderRadius: 6 }} />
                    </div>
                    <div className='scrolling-comp pt-3'>
                        <div
                            className='cross'
                            onClick={close}
                        >
                            <img src={CloseSvg} style={{ height: 26, width: 26 }} />
                        </div>
                        <p className='form-title'>Fill the details</p>
                        {questions.map(question =>
                            <QuestionComponent
                                label={question.label}
                                options={question.options}
                                value={question.value}
                            />)}
                        {/* <p className='input-title' style={{ marginBottom: 10 }}>
                            What is your location?
                        </p>
                        <CustomInput
                            placeholder={'Enter city'}
                            val={recommendationDetails?.city}
                            onChange={(e) => setRecommendationDetails({...recommendationDetails, city: e?.target?.value})}
                            style={{ backgroundColor: '#25387C', color: '#fff', width: '96%', marginLeft: 12 }}
                        /> */}
                        <div
                            className='save-btn'
                            style={{ opacity: loading ? 0.6 : 1 }}
                            onClick={() => {
                                if (!loading) {
                                    onPost()
                                }
                            }}
                        >
                            <p>Submit</p>
                        </div>
                        <div style={{ height: 160 }} />
                    </div>
                </div>
            </Modal>
            {identiferKey && <BeautyProfileIdentifier
                show={showIdentifier}
                close={() => {
                    setIdentifierKey(null)
                    setShowIdentifier(false)
                }}
                keyId={identiferKey}
                onComplete={(key, item) =>{
                    console.log(item);
                    setRecommendationDetails({
                        ...recommendationDetails,
                        [key]: item
                    })}}
            />}
        </>
    )
}

export default BeautyProfileChallenge







