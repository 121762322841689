import React, { useEffect, useState } from 'react'
import { Button, Container, Row } from 'react-bootstrap'
import Logo from '../../assets/images/svgs/logoWithShadow.svg'
import Chevron from '../../assets/images/svgs/rightChevron.svg'
import './SplashScreen.css'
import { useNavigate } from 'react-router'

const SplashScreen = () => {
    const [showNext, setShowNext] = useState(false);
    const [count, setCount] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        !showNext && setTimeout(() => {
            setCount(1000)
            if (count > 0) {
                const userData = JSON.parse(localStorage.getItem('user'));
                if (!userData) {
                    setShowNext(true)
                } else {
                    navigate('/stats')
                }
            }
        }, 1000)
    }, [count])

    return (
        <Container>
            <Row
                className="justify-content-center align-content-center"
                style={{ height: '100vh', display: 'flex', backgroundColor: '#fff' }}
            >
                <div style={{ height: 220, width: 220, border: showNext ? '4px solid #3F4E99' : 0, borderRadius: 220, display: 'flex', justifyContent: 'center' }}>
                    <img src={Logo} height={220} width={220} />
                </div>
                {showNext && <>
                    <p className='splash-title'>UB match score engine</p>
                    <p className='splash-title mt-0'>Program</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 64 }}>
                        <div className='get-started-btn' onClick={() => navigate('/signup')}>
                            <p>Get Started</p>
                            <img src={Chevron} />
                        </div>
                    </div>
                </>}
            </Row>
        </Container>
    )
}

export default SplashScreen