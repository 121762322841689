import axios from 'axios';
import { BASE_URL } from './config';
import { generateToken } from './generateToken';
const token = localStorage.getItem('jwtToken')

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
});

// Create operation
export const postData = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error creating data:', error);
    if (error.response?.status == 401) {
      generateToken()
        .then(() => window.location.reload())
    } else if (error.response?.status == 403) {
      window.location.replace('https://inf.unsweetenedbeauty.com/blocked')
    }
    else {
      console.log('error', error);
      console.log('error res', error?.response);
      throw error;
    }
  }
};

// Read operation
export const getData = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error getting data:', error);
    if (error.response?.status == 401) {
      generateToken()
        .then(() => window.location.reload())
    } else if (error.response?.status == 504) {
      window.location.reload()
    }
    else if (error.response?.status == 403) {
      window.location.replace('https://inf.unsweetenedbeauty.com/blocked')
    }
    else {
      console.log('error', error);
      console.log('error res', error?.response);
      // throw error;
      // window.location.reload()
    }
  }
};

// Update operation
export const putData = async (endpoint, updatedData) => {
  try {
    const response = await api.put(endpoint, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error in put data:', error);
    if (error.response?.status == 401) {
      generateToken()
        .then(() => window.location.reload())
    } else if (error.response?.status == 403) {
      window.location.replace('https://inf.unsweetenedbeauty.com/blocked')
    } else {
      console.log('error', error);
      console.log('error res', error?.response);
      throw error;
    }
  }
};

// Delete operation
export const deleteData = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error in delete data:', error);
    if (error.response?.status == 401) {
      generateToken()
        .then(() => window.location.reload())
    } else if (error.response?.status == 403) {
      window.location.replace('https://inf.unsweetenedbeauty.com/blocked')
    } else {
      console.log('error', error);
      console.log('error res', error?.response);
      throw error;
    }
  }
};

export const setAuthToken = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem('jwtToken', token);
};