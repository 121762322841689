import React from 'react'
import userSvg from '../../assets/images/svgs/user.svg'
import notifSvg from '../../assets/images/svgs/notif.svg'
import rewardSvg from '../../assets/images/svgs/reward.svg'
import supportSvg from '../../assets/images/svgs/support.svg'
import Chevron from '../../assets/images/svgs/rightChevron.svg'

const ProfileOption = ({ label, onClick }) => {
    const icon = label == 'Profile Settings' ? userSvg : label == 'Notifications' ? notifSvg : label == 'Claim rewards' ? rewardSvg : supportSvg
    return (
        <div className='option-card' onClick={onClick}>
            <div style={{ display: 'flex' }}>
                <img src={icon} height={24} width={24} />
                <p className='m-0'>{label}</p>
            </div>
            <img src={Chevron} height={24} width={24} />
        </div>
    )
}

export default ProfileOption