import axios from "axios";
import { BASE_URL } from "./config";
import setAuthorizationHeader from "./setAuthHeader";
import { setAuthToken } from "./axiosInstance";

export const generateToken = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            mobileNumber: user?.mobileNumber,
            userID: user?.userID
        }
        const url = `${BASE_URL}api/auth/newToken`
       await axios.post(url, data)
            .then(res => {
                setAuthToken(res?.data?.jwtToken)
                setAuthorizationHeader(res?.data?.jwtToken)
                localStorage.setItem('jwtToken', res?.data?.jwtToken)
                return res?.data
            })
            .catch(err => {
                console.log('ERRRRRR.R.R', err)
                return err
            })
}