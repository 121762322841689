import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './Profile.css'
import EditSvg from "../../assets/images/svgs/edit.svg";
import India from "../../assets/images/svgs/india.svg";
import ProfileOption from './ProfileOption';
import CustomInput from '../../components/core/CustomInput/CustomInput';
import Header from '../../components/core/Header/Header';
import BottomTabs from '../../components/core/BottomTabs/BottomTabs';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';
import { putData } from '../../utils/axiosInstance';

const Profile = () => {
    const navigate = useNavigate();
    const [showEdit, setShowEdit] = useState(false);
    const [otpInput, setOtpInput] = useState(false);
    const [allowEdit, useAllowEdit] = useState(false);
    const [counter, setCounter] = useState(0);
    const [resent, setResent] = useState(false);
    const [user, setUser] = useState({})
    const [changedData, setChangedData] = useState({
        userName: '',
        mobileNumber: '',
    })
    const [otpValue, setOtpValue] = useState('');

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else {
            setUser(userData)
            setChangedData({ ...changedData, userName: userData?.userName, mobileNumber: userData?.mobileNumber })
        }
    }, []);

    const handleSendOtp = () => {
        if (changedData?.userName != user?.userName || changedData?.mobileNumber != user?.mobileNumber) {
            setCounter(30)
            const reqBody = { ...user }
            reqBody['mobileNumber'] = changedData.mobileNumber
            const url = `${BASE_URL}api/user/sendOTP/v2`
            axios.post(url, reqBody)
                .then(response => {
                    console.log('Response:', response.data)
                    setOtpInput(true)
                    countdown()
                })
                .catch(error => {
                    console.error('Error:', error)
                    setCounter(0)
                });
        } else toast.warn('Nothing to change')
    }

    const countdown = () => {
        if (counter > 0) {
            setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        }
    }

    useEffect(() => {
        countdown()
    }, [counter])


    const updateUser = async() => {
        const response = await putData(`influencer/${user?.userID}`, changedData)
        console.log('Response:', response.data)
                setOtpInput(false)
                setShowEdit(false)
                let data = { ...user }
                data['mobileNumber'] = changedData.mobileNumber
                data['userName'] = changedData.userName
                setUser(data)
                // localStorage.removeItem("user")
                localStorage.setItem("user", JSON.stringify(data))
        // const url = `${BASE_URL}influencer/${user?.userID}`
        // axios.put(url, changedData)
        //     .then(response => {
        //         console.log('Response:', response.data)
        //         setOtpInput(false)
        //         setShowEdit(false)
        //         let data = { ...user }
        //         data['mobileNumber'] = changedData.mobileNumber
        //         data['userName'] = changedData.userName
        //         setUser(data)
        //         // localStorage.removeItem("user")
        //         localStorage.setItem("user", JSON.stringify(data))
        //     })
        //     .catch(error => {
        //         console.error('Error:', error)
        //         if(error?.response?.status == 403) {
        //             navigate('/blocked')
        //         }
        //     });
    }

    const handleVerifyOtp = () => {
        if (changedData?.userName != user?.userName || changedData?.mobileNumber != user?.mobileNumber) {
            const data = { ...user }
            data['mobileNumber'] = changedData.mobileNumber
            data['otpValue'] = otpValue
            data['countryCode'] = "91"
            data['serviceType'] = "signin"
            const url = `${BASE_URL}api/user/verifyOTP/v2`
            axios.post(url, data)
                .then(response => {
                    console.log('Response:', response.data)
                    if (response?.data?.success) {
                        setOtpValue('')
                        updateUser()
                    } else if (response?.data?.OTPValid == 0) {
                        toast.error('Invalid Otp')
                    }
                })
                .catch(error => console.error('Error:', error));
        } else toast.warn('Nothing to change')
    }

    const changed = () => {
        return changedData?.userName != user?.userName || changedData?.mobileNumber != user?.mobileNumber
    }
    const openWhatsApp = () => {
        const whatsappURL = `https://wa.me/+918977944535`;
        // const whatsappURL = `https://whatsapp://send?text=jdnvjdv`;
        // window.location.href = whatsappURL;
        // window.open(whatsappURL)
        window.location.href = whatsappURL
    };
    return (
        <Container>
            <Row
                className="justify-content-center"
                style={{ height: '100vh', padding: 16, marginTop: 80 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header />
                </Col>
                <Col>
                    <div className='profile-row'>
                        <div className='profile-img'>
                            {/* <img src='https://picsum.photos/200' /> */}
                            <div style={{ backgroundColor: '#fff', height: 66, width: 66, borderRadius: 75, display: 'flex', justifyContent: 'center' }}>
                                <h1 style={{ color: '#25387C', alignSelf: 'center', fontWeight: 600 }} className='mb-0'>{user?.userName?.[0]}</h1>
                            </div>
                            <p>{user?.userName}</p>
                        </div>
                        {showEdit ? <div className='edit-btn' onClick={handleVerifyOtp}>
                            <img src={EditSvg} sheight={20} width={20} />
                            <p>{changed() ? 'Update Profile' : 'Edit profile'}</p>
                        </div> :
                            <img src={EditSvg} height={24} width={24} style={{ alignSelf: 'center', cursor: 'pointer' }} onClick={() => setShowEdit(true)} />
                        }
                    </div>
                    {showEdit ? <div className='input-wrap'>
                        <p style={{ marginLeft: 0 }}>Full Name</p>
                        <CustomInput
                            val={changedData.userName}
                            onChange={e => setChangedData({ ...changedData, userName: e.target.value })}
                            placeholder={'Ishikna'}
                            style={{ backgroundColor: '#25387C', borderRadius: 12, marginTop: 10, border: '1px solid #fff', color: '#fff' }}
                        />

                        <p style={{ marginTop: 20, marginLeft: 0 }}>Phone No.</p>
                        <div className='input-container' style={{ padding: 6, marginTop: 10, borderRadius: 12, border: '1px solid #fff', backgroundColor: '#25387C' }}>
                            <img src={India} />
                            <p className='country-code' style={{ marginTop: 4 }}>+91 - </p>
                            <input
                                className='mobile-input'
                                maxLength={10}
                                placeholder='Phone number'
                                style={{ color: '#fff' }}
                                value={changedData.mobileNumber}
                                onChange={e => setChangedData({ ...changedData, mobileNumber: e.target.value })}
                            />
                        </div>
                        <Row>
                            <Col md={9} sx={9} sm={9} style={{ paddingRight: 0 }}>
                                <CustomInput
                                    val={otpValue}
                                    onChange={e => setOtpValue(e.target.value)}
                                    placeholder={'Enter Otp'}
                                    style={{ backgroundColor: '#25387C', borderRadius: 12, marginTop: 10, border: '1px solid #fff', color: '#fff' }}
                                />
                            </Col>
                            <Col md={3} sx={3} sm={3} style={{ paddingLeft: 0 }} >
                                <Button
                                    style={{ marginTop: 10, backgroundColor: '#4254EA', border: 0, borderRadius: 12, opacity: counter > 0 ? 0.6 : 1 }}
                                    onClick={counter <= 0 ? handleSendOtp : null}
                                >
                                    Get Otp
                                </Button>
                            </Col>
                        </Row>
                        <p className='resend'>Resend in<span className='counter'>00:{`${counter < 10 ? '0' : ''}${counter}`}</span></p>
                    </div> : <>
                        <ProfileOption
                            label={'Profile Settings'}
                            onClick={() => setShowEdit(true)}
                        />
                        <ProfileOption
                            label={'Notifications'}
                            onClick={() => navigate('/notifications')}
                        />
                        <ProfileOption
                            label={'Claim rewards'}
                            onClick={() => navigate('/claim')}
                        />
                        <ProfileOption
                            label={'Help & Support'}
                            onClick={openWhatsApp}
                        />
                    </>}
                    <p
                        className='log-out'
                        onClick={() => {
                            localStorage.clear()
                            navigate('/')
                        }}
                    >
                        Log Out
                    </p>
                </Col>
                <div style={{ height: 300 }} />
                <BottomTabs />
            </Row>
        </Container >
    )
}

export default Profile